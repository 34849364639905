import { observer } from "mobx-react-lite";
import React, { useDeferredValue, useState } from "react";
import { RecipientList } from "../RecipientList";
import { CreateRecipientStore } from "@/features/create-recipient/store/CreateRecipientStore";
import { BaseIcon, Box, Button, FormControlInput, Typography } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
export var Layout = observer(function () {
    var _a = useState(""), search = _a[0], setSearch = _a[1];
    var text = useDeferredValue(search);
    var media = useMedia();
    var store = useStoreContext(CreateRecipientStore);
    var openModal = function () { return store.setIsOpen(true); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
            React.createElement(Typography, { variant: "h2", type: "text32Bold" }, "Recipients"),
            media.phone && (React.createElement(Button, { variant: "Black", icon: "Plus", iconSize: 20, size: "s", width: "110px", onClick: openModal }, "Add"))),
        React.createElement(Box, { bgColor: "general-white", borderRadius: "22px", gap: media.phone ? "20px" : "40px", padding: media.phone ? "20px" : "40px", display: "flex", flexDirection: "column" },
            React.createElement(Box, { display: "flex", gap: "20px", width: "100%" },
                React.createElement(FormControlInput, { value: search, onChange: function (e) { return setSearch(e.target.value); }, width: "100%", placeholder: "Search by name", InputProps: {
                        startAdornment: React.createElement(BaseIcon, { icon: "Search", color: "black-400" }),
                        endAdornment: text
                            ? (React.createElement(Box, { role: "button", onClick: function () { return setSearch(""); }, tabIndex: 0, display: "flex", alignItems: "center", justifyContent: "center", width: 24, flexShrink: 0, bgColor: "black-300", height: 24, borderRadius: "100%" },
                                React.createElement(BaseIcon, { icon: "Cross", size: 10, color: "general-white" })))
                            : undefined,
                    } }),
                !media.phone && (React.createElement(Button, { variant: "Black", icon: "Plus", iconSize: 20, size: "l", width: "180px", onClick: openModal }, "Add recipient"))),
            React.createElement(RecipientList, { search: text }))));
});
