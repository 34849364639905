var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { BaseIcon } from "@/shared/components/BaseIcon";
export var Loader = function (_a) {
    var size = _a.size, containerSize = _a.containerSize, _b = _a.color, color = _b === void 0 ? "black-1200" : _b, _c = _a.opacity, opacity = _c === void 0 ? 0.1 : _c, props = __rest(_a, ["size", "containerSize", "color", "opacity"]);
    return (React.createElement(Wrapper, __assign({}, props, { icon: "Spinner", width: size, height: size, containerHeight: containerSize !== null && containerSize !== void 0 ? containerSize : size, containerWidth: containerSize !== null && containerSize !== void 0 ? containerSize : size, opacity: opacity, color: color })));
};
export var Wrapper = styled(BaseIcon)(function () { return ({
    animation: "rotate 1s linear infinite",
    "@keyframes rotate": {
        "from": {
            transform: "rotate(0)",
        },
        "to": {
            transform: "rotate(360deg)",
        },
    },
}); });
