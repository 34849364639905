import { makeAutoObservable } from "mobx";
var MenuStore = /** @class */ (function () {
    function MenuStore() {
        this.visible = false;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    MenuStore.prototype.toggle = function () {
        this.visible = !this.visible;
    };
    MenuStore.prototype.open = function () {
        this.visible = true;
    };
    MenuStore.prototype.close = function () {
        this.visible = false;
    };
    return MenuStore;
}());
export { MenuStore };
