import { observer } from "mobx-react-lite";
import * as React from "react";
import { Box, Button, Typography } from "@/shared/components";
export var HintBtn = observer(function (_a) {
    var value = _a.value, label = _a.label, disabled = _a.disabled, onClick = _a.onClick;
    return (React.createElement(Button, { disabled: disabled, onClick: onClick, variant: "Bordered" },
        React.createElement(Box, { display: "flex", flexDirection: "column" },
            label && (React.createElement(Typography, { type: "text12", color: "black-500" }, label)),
            React.createElement(Typography, { type: "text14" }, value))));
});
