import { observer } from "mobx-react-lite";
import * as React from "react";
import { SigninForm } from "@/pages/SigninPage/components/SigninForm";
import { VerificationForm } from "@/pages/SigninPage/components/VerificationForm";
import { SigninPageStore, SigninStep } from "@/pages/SigninPage/stores/SigninPageStore";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
export var SigninPage = observer(function () {
    var auth = useStoreContext(AuthStore);
    var page = useStore(SigninPageStore, auth);
    var PageProvider = useProvider(SigninPageStore);
    return (React.createElement(PageProvider, { value: page }, page.step === SigninStep.Email
        ? React.createElement(SigninForm, null)
        : page.step === SigninStep.Verification
            ? React.createElement(VerificationForm, null)
            : null));
});
