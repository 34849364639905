import * as React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ROUTES, TransferType } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
import { generateQueryPath } from "@/shared/utils/path";
export var Header = function (_a) {
    var active = _a.active;
    var media = useMedia();
    var search = useSearchParams()[0];
    var from = search.get("from");
    return (React.createElement(Box, { marginBottom: media.desktop ? "60px" : "30px" },
        React.createElement(Typography, { variant: "h1", type: "text32Bold", margin: "0 0 20px" }, "Transfer funds"),
        React.createElement(Box, { display: media.desktop ? "flex" : "grid", alignItems: "center", gap: "10px", gridAutoFlow: "column", gridAutoColumns: "minmax(0,1fr)", whiteSpace: "nowrap" },
            React.createElement(Button, { replace: true, size: "s", minWidth: "100px", variant: active === TransferType.Account ? "Default" : "LightBlue", LinkComponent: active !== TransferType.Account ? Link : undefined, to: generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Account,
                }, {
                    from: from,
                }) }, media.desktop ? "To my account" : "My account"),
            React.createElement(Button, { replace: true, size: "s", minWidth: "100px", variant: active === TransferType.Recipient ? "Default" : "LightBlue", LinkComponent: active !== TransferType.Recipient ? Link : undefined, to: generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Recipient,
                }, {
                    from: from,
                }) }, media.desktop ? "To recipient" : "Recipient"),
            React.createElement(Button, { replace: true, size: "s", minWidth: "100px", variant: active === TransferType.Crypto ? "Default" : "LightBlue", LinkComponent: active !== TransferType.Crypto ? Link : undefined, to: generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Crypto,
                }, {
                    from: from,
                }) }, media.desktop ? "To digital wallet" : "Wallet"))));
};
