import { observer } from "mobx-react-lite";
import * as React from "react";
import { EmailCodeForm } from "@/pages/SignupPage/components/EmailCodeForm";
import { SignupForm } from "@/pages/SignupPage/components/SignupForm";
import { SignupPageStore, SignupStep } from "@/pages/SignupPage/stores/SignupPageStore";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
export var SignupPage = observer(function () {
    var auth = useStoreContext(AuthStore);
    var page = useStore(SignupPageStore, auth);
    var PageProvider = useProvider(SignupPageStore);
    return (React.createElement(PageProvider, { value: page }, page.step === SignupStep.EmailCode
        ? React.createElement(EmailCodeForm, null)
        : page.step === SignupStep.Email
            ? React.createElement(SignupForm, null)
            : null));
});
