import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { PasswordResetPageStore } from "@/pages/PasswordResetPage/stores/PasswordResetPageStore";
import { ROUTES } from "@/routes";
import { FormControlInput } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
export var PasswordResetForm = observer(function () {
    var _a;
    var navigate = useNavigate();
    var page = useStoreContext(PasswordResetPageStore);
    var onBack = function () {
        navigate(generatePath(ROUTES.signin));
    };
    return (React.createElement(AuthForm, { onBack: onBack, onSubmit: page.submitEmail, title: "Reset password", desc: "Enter the email related to your account", content: React.createElement(FormControlInput, { type: "text", label: "Email", value: page.email, onChange: onChange(action(function (value) {
                page.email = value;
            })), variantError: "alert", error: (_a = page.errors[0]) === null || _a === void 0 ? void 0 : _a.description, disabled: page.loading }), footer: React.createElement(Button, { type: "submit", width: "100%", disabled: page.loading || !page.isEmailValid }, "Continue") }));
});
