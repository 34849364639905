/* eslint-disable no-restricted-syntax */
export var resolveOnChange = function (target, e, onChange, targetValue) {
    if (!onChange) {
        return;
    }
    var event = e;
    if ((e === null || e === void 0 ? void 0 : e.type) === "click") {
        var currentTarget = target.cloneNode(true);
        // click clear icon
        event = Object.create(e, {
            target: { value: currentTarget },
            currentTarget: { value: currentTarget },
        });
        currentTarget.value = targetValue !== null && targetValue !== void 0 ? targetValue : "";
        onChange(event);
        return;
    }
    // Trigger by composition event, this means we need force change the input value
    if (targetValue !== undefined) {
        event = Object.create(e, {
            target: { value: target },
            currentTarget: { value: target },
        });
        // eslint-disable-next-line no-param-reassign
        target.value = targetValue;
        onChange(event);
        return;
    }
    onChange(event);
};
export var mergeRefs = function () {
    var refs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        refs[_i] = arguments[_i];
    }
    var filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length)
        return null;
    return function (inst) {
        for (var _i = 0, filteredRefs_1 = filteredRefs; _i < filteredRefs_1.length; _i++) {
            var ref = filteredRefs_1[_i];
            if (typeof ref === "function") {
                ref(inst);
            }
            else if (ref) {
                ;
                ref.current = inst;
            }
        }
    };
};
