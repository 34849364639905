import { observer } from "mobx-react-lite";
import * as React from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { PaymentFormCard } from "@/pages/PaymentPage/components/PaymentFormCard";
import { PaymentFormCrypto } from "@/pages/PaymentPage/components/PaymentFormCrypto";
import { PaymentParam, PaymentType, ROUTES } from "@/routes";
import { generateQueryPath } from "@/shared/utils/path";
export var PaymentFormPage = observer(function () {
    var _a;
    var search = useSearchParams()[0];
    var type = search.get(PaymentParam.Type);
    var target = search.get(PaymentParam.Target);
    var plan = search.get(PaymentParam.Plan);
    switch (type) {
        case PaymentType.Crypto:
            return React.createElement(PaymentFormCrypto, null);
        case PaymentType.Card:
            return React.createElement(PaymentFormCard, null);
        default:
            return (React.createElement(Navigate, { to: generateQueryPath(ROUTES.payment, {}, (_a = {},
                    _a[PaymentParam.Target] = target,
                    _a[PaymentParam.Plan] = plan,
                    _a)), replace: true }));
    }
});
