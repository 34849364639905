import { makeAutoObservable } from "mobx";
var AuthPageStore = /** @class */ (function () {
    function AuthPageStore() {
        this.formVisible = false;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    AuthPageStore.prototype.toggleForm = function () {
        this.formVisible = !this.formVisible;
    };
    return AuthPageStore;
}());
export { AuthPageStore };
