import { styled } from "@mui/material/styles";
import * as React from "react";
import { Box, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
export var PaymentInfoPopup = function (_a) {
    var onClose = _a.onClose;
    return (React.createElement(Popup, { onClose: onClose },
        React.createElement(PopupHead, { onClose: onClose }, "Payment info"),
        React.createElement(Box, { height: "1px", bgColor: "black-100" }),
        React.createElement(Box, { padding: "25px" },
            React.createElement(Typography, { variant: "body1", type: "text14", fontWeight: 500, color: "black-500", marginBottom: "30px" }, "Description text example description text example description text example description text example description text example"),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", marginBottom: "30px" },
                React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
                    React.createElement(Typography, { type: "text16", fontWeight: 500, color: "black-500" }, "Verification fee"),
                    React.createElement(Typography, { type: "text16", fontWeight: 500, color: "black-1200" }, "\u20AC00.00")),
                React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
                    React.createElement(Typography, { type: "text16", fontWeight: 500, color: "black-500" }, "Monthly"),
                    React.createElement(Typography, { type: "text16", fontWeight: 500, color: "black-1200" }, "\u20AC00.00")),
                React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
                    React.createElement(Typography, { type: "text16", fontWeight: 500, color: "black-500" }, "Activation conditions"),
                    React.createElement(Typography, { type: "text16", fontWeight: 500, color: "black-1200" }, "Valid documents"))),
            React.createElement(Typography, { variant: "body1", bgColor: "black-50", borderRadius: "16px", padding: "19px 30px", type: "text14", fontWeight: 450, color: "black-500", textAlign: "center", marginBottom: "30px" }, "Once your documents are expired, you will need to be verified again with new valid documents."),
            React.createElement(Box, { height: "1px", bgColor: "black-100", margin: "30px 0" }),
            React.createElement(Typography, { type: "text16", fontWeight: 500, variant: "h3", margin: "30px 0 5px 0" }, "What are fiat transactions?"),
            React.createElement(List, null,
                React.createElement("li", null, "Buy crypto with bank account"),
                React.createElement("li", null, "Withdraw to bank account"),
                React.createElement("li", null, "All exchanges with IBAN")),
            React.createElement(Typography, { type: "text16", fontWeight: 500, variant: "h3", margin: "30px 0 5px 0" }, "Long story short"),
            React.createElement(List, null,
                React.createElement("li", null, "To make fiat transactions, you must be verified."),
                React.createElement("li", null, "You can make fiat transactions as long as your documents are valid")))));
};
var List = styled("ul")(function (_a) {
    var theme = _a.theme;
    return ({
        fontSize: theme.text.text14.fontSize,
        fontWeight: 500,
        lineHeight: "21px",
        margin: 0,
        padding: 0,
        listStylePosition: "inside",
        color: theme.colors["black-500"],
    });
});
