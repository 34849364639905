var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// eslint-disable-next-line max-classes-per-file
import { makeAutoObservable, reaction } from "mobx";
import { walletApi } from "@/api/wallet/api";
import { CounterpartyType, } from "@/api/wallet/types";
import { DataSync } from "@/shared/stores/DataStore";
import { Reactions } from "@/shared/utils/reactions";
var Recipient = /** @class */ (function () {
    function Recipient(data) {
        this.data = data;
    }
    Object.defineProperty(Recipient.prototype, "accountNumber", {
        get: function () {
            return "accountNumber" in this.data
                ? this.data.accountNumber
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Recipient.prototype, "iban", {
        get: function () {
            return "iban" in this.data ? this.data.iban : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Recipient.prototype, "swiftBic", {
        get: function () {
            return "swiftBic" in this.data ? this.data.swiftBic : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Recipient.prototype, "blockchainAddress", {
        get: function () {
            return "blockchainAddress" in this.data
                ? this.data.blockchainAddress
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Recipient.prototype, "profileName", {
        get: function () {
            return "profile" in this.data ? this.data.profile.name : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Recipient.prototype, "blockchain", {
        get: function () {
            return "blockchain" in this.data ? this.data.blockchain : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Recipient.prototype, "currency", {
        get: function () {
            return "currency" in this.data ? this.data.currency : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Recipient.prototype, "isCrypto", {
        get: function () {
            return this.data.type === CounterpartyType.CRYPTO;
        },
        enumerable: false,
        configurable: true
    });
    return Recipient;
}());
var RecipientListStore = /** @class */ (function () {
    function RecipientListStore(auth) {
        this.auth = auth;
        this.reactions = new Reactions();
        this.counterparty = new DataSync(walletApi.counterparty.getCounterparties.bind(walletApi));
        makeAutoObservable(this, {}, { autoBind: true });
    }
    RecipientListStore.prototype.init = function () {
        var _this = this;
        this.reactions.create(reaction(function () { return _this.auth.authorized; }, this.sync, {
            fireImmediately: true,
        }));
    };
    RecipientListStore.prototype.dispose = function () {
        this.reactions.destroy();
    };
    RecipientListStore.prototype.sync = function () {
        if (this.auth.authorized) {
            this.counterparty.sync({
                page: 0,
                size: 1000,
            });
        }
        else {
            this.counterparty.reset();
        }
    };
    RecipientListStore.prototype.deleteParty = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.auth.authorized)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, walletApi.counterparty.deleteCounterparty(id)];
                    case 2:
                        _a.sent();
                        this.sync();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.log(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(RecipientListStore.prototype, "list", {
        get: function () {
            var _a, _b;
            var recList = (_b = (_a = this.counterparty.value) === null || _a === void 0 ? void 0 : _a.data.data.map(function (item) { return new Recipient(item); })) !== null && _b !== void 0 ? _b : [];
            return (recList === null || recList === void 0 ? void 0 : recList.length) === 0
                ? recList
                : recList.sort(function (a, b) {
                    var _a, _b;
                    if (!a.profileName)
                        return 1;
                    if (!b.profileName)
                        return -1;
                    return (_a = a.profileName) === null || _a === void 0 ? void 0 : _a.localeCompare((_b = b.profileName) !== null && _b !== void 0 ? _b : "");
                });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RecipientListStore.prototype, "byId", {
        get: function () {
            return this.list
                .filter(function (item) { return !!item.data.id; })
                .reduce(function (acc, item) {
                var _a;
                return (__assign(__assign({}, acc), (_a = {}, _a[item.data.id] = item, _a)));
            }, {});
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RecipientListStore.prototype, "loading", {
        get: function () {
            return this.counterparty.loading;
        },
        enumerable: false,
        configurable: true
    });
    return RecipientListStore;
}());
export { RecipientListStore };
