import { makeAutoObservable, runInAction } from "mobx";
var TimerStore = /** @class */ (function () {
    function TimerStore() {
        this.count = 0;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    TimerStore.prototype.start = function (secs) {
        var _this = this;
        if (secs === void 0) { secs = 60; }
        clearInterval(this.interval);
        this.count = secs;
        this.interval = setInterval(function () {
            runInAction(function () {
                _this.count -= 1;
                if (_this.count === 0) {
                    clearInterval(_this.interval);
                }
            });
        }, 1000);
    };
    TimerStore.prototype.dispose = function () {
        clearInterval(this.interval);
    };
    return TimerStore;
}());
export { TimerStore };
