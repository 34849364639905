/* eslint-disable class-methods-use-this */
var ACCESS_TOKEN_STORAGE_KEY = "accessToken";
var REFRESH_TOKEN_STORAGE_KEY = "refreshToken";
var TokenStorage = /** @class */ (function () {
    function TokenStorage() {
        var _this = this;
        this.callbacks = [];
        window.addEventListener("storage", function () {
            _this.callbacks.forEach(function (cb) { return cb(); });
        });
    }
    TokenStorage.prototype.getAccessToken = function () {
        try {
            return localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
        }
        catch (e) {
            console.error(e);
            return null;
        }
    };
    TokenStorage.prototype.getRefreshToken = function () {
        try {
            return localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
        }
        catch (e) {
            console.error(e);
            return null;
        }
    };
    TokenStorage.prototype.setTokens = function (accessToken, refreshToken) {
        if (accessToken || refreshToken) {
            try {
                if (accessToken) {
                    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
                }
                if (refreshToken) {
                    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
                }
                this.callbacks.forEach(function (cb) { return cb(); });
            }
            catch (e) {
                console.error(e);
            }
        }
    };
    TokenStorage.prototype.clean = function () {
        try {
            localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
            localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
            this.callbacks.forEach(function (cb) { return cb(); });
        }
        catch (e) {
            console.error(e);
        }
    };
    TokenStorage.prototype.subscribe = function (cb) {
        var _this = this;
        this.callbacks.push(cb);
        return function () {
            _this.callbacks = _this.callbacks.filter(function (item) { return item !== cb; });
        };
    };
    return TokenStorage;
}());
export { TokenStorage };
export var tokenStorage = new TokenStorage();
