import React from "react";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
import { DATE_FORMAT, formatDate } from "@/shared/lib";
import { sliceAddress } from "@/shared/utils/slice-address";
export var SuccessPopup = function (_a) {
    var minus = _a.minus, title = _a.title, amount = _a.amount, currency = _a.currency, address = _a.address, createdAt = _a.createdAt, onClose = _a.onClose;
    return (React.createElement(Popup, { onClose: onClose, bgColor: "general-green", width: "450px" },
        React.createElement(PopupBody, { padTop: true },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "50px", alignItems: "center" },
                createdAt && (React.createElement(Typography, { color: "general-white", sx: { opacity: 0.6 } }, formatDate(createdAt, DATE_FORMAT.date_with_time))),
                React.createElement(Typography, { type: "text28Bold", textAlign: "center", color: "general-white" }, title),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" },
                    address && (React.createElement(Typography, { color: "general-white" }, sliceAddress(address))),
                    amount && currency && (React.createElement(Box, { bgColor: "general-white-transparent-10", borderRadius: "16px", padding: "10px 20px" },
                        React.createElement(Typography, { color: "general-white", textTransform: "uppercase", type: "text20", fontWeight: 700 },
                            minus ? "−" : "+",
                            " ",
                            amount,
                            " ",
                            currency))))),
            React.createElement(Button, { mt: "100px", width: "100%", variant: "White", onClick: onClose }, "Done"))));
};
