import { useQuery } from "@tanstack/react-query";
import { walletApi } from "./api";
export var useGetBalanceLogReceipt = function (id) {
    return useQuery({
        queryKey: ["useGetgetBalanceLogReceipt", id],
        queryFn: function () { return walletApi.balance.getBalanceLogReceipt(id); },
        staleTime: 100000,
        select: function (data) {
            return data.data;
        },
        gcTime: 100000,
        enabled: !!id,
    });
};
