var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { formattedTokenAmount } from "@broxus/js-utils";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { DepositType, ROUTES } from "@/routes";
import { BankIcon } from "@/shared/components/BankIcon";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Button } from "@/shared/components/Button";
import { Loader } from "@/shared/components/Loader";
import { PopupItem } from "@/shared/components/Popup/Item";
import { Typography } from "@/shared/components/Typography";
export var AccountItem = function (_a) {
    var id = _a.id, selected = _a.selected, amount = _a.amount, symbol = _a.symbol, desc = _a.desc, accNum = _a.accNum, depositBtnVisible = _a.depositBtnVisible, isEmpty = _a.isEmpty, onSelect = _a.onSelect;
    var navigate = useNavigate();
    return (React.createElement(PopupItem, { selected: selected, onClick: onSelect },
        React.createElement(BankIcon, { symbol: symbol, size: 36 }),
        React.createElement(Box, { display: "flex", flexDirection: "column", flexGrow: "1" },
            React.createElement(Title, { type: "text14" },
                amount
                    ? ("".concat(formattedTokenAmount(amount), " ").concat(symbol))
                    : React.createElement(Loader, { size: 16, containerSize: 21 }),
                selected && React.createElement(BaseIcon, { size: 16, icon: "Success" })),
            desc && (React.createElement(Typography, { type: "text14", color: "black-400" }, desc)),
            React.createElement(Typography, { type: "text14", color: "black-400" }, isEmpty ? "No account" : accNum !== null && accNum !== void 0 ? accNum : "\u200b")),
        depositBtnVisible && (React.createElement(Button, { size: "s", variant: "Bordered", onClick: function (e) {
                e.preventDefault();
                e.stopPropagation();
                navigate(generatePath(ROUTES.deposit, {
                    type: DepositType.Fiat,
                    id: id,
                }));
            } }, "Add funds"))));
};
var Title = styled(Typography)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    gap: 5px;\n"], ["\n    display: flex;\n    align-items: center;\n    gap: 5px;\n"])));
var templateObject_1;
