import { observer } from "mobx-react-lite";
import * as React from "react";
import { CurrencyPopup } from "@/pages/UserPage/components/Currency/CurrencyPopup";
import { Typography } from "@/shared/components";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { UserCurrencyStore } from "@/shared/stores/UserCurrencyStore";
export var Currency = observer(function () {
    var popup = useToggler();
    var userCurrency = useStoreContext(UserCurrencyStore);
    if (!userCurrency.selected) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { display: "flex", alignItems: "center", justifyContent: "center", bgColor: "general-white", borderRadius: "46px", height: "46px", padding: "0 20px", type: "text16", onClick: popup.on }, userCurrency.selected.toUpperCase()),
        popup.enabled && React.createElement(CurrencyPopup, { onClose: popup.off })));
});
