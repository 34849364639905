var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { BaseIcon } from "@/shared/components";
export var AccountItem = function (_a) {
    var to = _a.to, children = _a.children;
    return (React.createElement(Wrapper, { to: to },
        children,
        React.createElement(BaseIcon, { icon: "Right", size: 12, containerSize: 24, color: "black-1200" })));
};
var Wrapper = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    gap: 15px;\n\n    ", " {\n        padding: 20px;\n    }\n\n    ", " {\n        padding: 20px 30px;\n        border: 1px solid ", ";\n        border-radius: 22px;\n    }\n\n    &:hover {\n        background: ", ";\n    }\n"], ["\n    display: flex;\n    align-items: center;\n    gap: 15px;\n\n    ", " {\n        padding: 20px;\n    }\n\n    ", " {\n        padding: 20px 30px;\n        border: 1px solid ", ";\n        border-radius: 22px;\n    }\n\n    &:hover {\n        background: ", ";\n    }\n"])), function (props) { return props.theme.breakpoints.down("md"); }, function (props) { return props.theme.breakpoints.up("md"); }, function (props) { return props.theme.colors["black-50"]; }, function (props) { return props.theme.colors["black-50"]; });
var templateObject_1;
