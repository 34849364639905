import { observer } from "mobx-react-lite";
import React from "react";
import { CreateRecipientStore } from "../store/CreateRecipientStore";
import { CreateRecipientForm } from "./Form";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Dialog } from "@/shared/components/Dialog";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
export var CreateRecipientModal = observer(function () {
    var tablet = useMedia().tablet;
    var _a = useStoreContext(CreateRecipientStore), setIsOpen = _a.setIsOpen, isOpen = _a.isOpen;
    return (React.createElement(Dialog, { open: isOpen, fullScreen: true },
        React.createElement(BaseIcon, { icon: "Close", position: "absolute", top: tablet ? "10px" : "50px", right: tablet ? "10px" : "100px", bgColor: "black-50", padding: "0", size: 45, borderRadius: "12px", onClick: function () { return setIsOpen(false); } }),
        React.createElement(Box, { maxWidth: "780px", width: "100%", display: "flex", flexDirection: "column", mt: tablet ? "10px" : "50px", padding: tablet ? "10px" : "0", ml: tablet ? "0" : "100px", boxSizing: "border-box" },
            React.createElement(Box, { boxSizing: "border-box", width: "150px", padding: "14px 25px", bgColor: "black-50", borderRadius: "100px", display: "flex", alignItems: "center", mb: tablet ? "24px" : "60px" },
                React.createElement(Typography, null, "Add recipient")),
            React.createElement(CreateRecipientForm, null))));
});
