var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
export var UserPick = observer(function (_a) {
    var _b;
    var firstName = _a.firstName, lastName = _a.lastName, name = _a.name, _c = _a.size, size = _c === void 0 ? 36 : _c;
    return (React.createElement(Wrapper, { size: size }, firstName && lastName
        ? (React.createElement(React.Fragment, null,
            firstName[0],
            lastName[0]))
        : name
            ? (React.createElement(React.Fragment, null,
                name.split(" ")[0][0], (_b = name.split(" ")[1]) === null || _b === void 0 ? void 0 :
                _b[0]))
            : null));
});
var Wrapper = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: ", ";\n    height: ", ";\n    border-radius: ", ";\n    background: ", ";\n    font-size: ", ";\n    color: ", ";\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-transform: uppercase;\n"], ["\n    width: ", ";\n    height: ", ";\n    border-radius: ", ";\n    background: ", ";\n    font-size: ", ";\n    color: ", ";\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-transform: uppercase;\n"])), function (props) { return "".concat(props.size, "px"); }, function (props) { return "".concat(props.size, "px"); }, function (props) { return "".concat(props.size, "px"); }, function (props) { return props.theme.colors["light-blue"]; }, function (props) { return props.size > 24 ? "15px" : "10px"; }, function (props) { return props.theme.colors["deep-blue-500"]; });
var templateObject_1;
