import { useMediaQuery } from "@mui/material";
import React from "react";
export var useMedia = function () {
    var desktop = useMediaQuery("(min-width: 1024px)");
    var tablet = useMediaQuery("(max-width: 1023px)");
    var phone = useMediaQuery("(max-width: 767px)");
    return React.useMemo(function () { return ({
        desktop: desktop,
        tablet: tablet,
        phone: phone,
    }); }, [tablet, desktop, phone]);
};
