import { useQuery } from "@tanstack/react-query";
import { registrationApi } from "./api";
export var useGetCompanyInfo = function () {
    return useQuery({
        queryKey: ["registrationApi.user.getCompanyInfo"],
        select: function (data) { return data.data; },
        queryFn: function () { return registrationApi.user.getCompanyInfo(); },
        retry: 3,
    });
};
