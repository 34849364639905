var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { parsePhoneNumber } from "awesome-phonenumber";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { VerificationStep } from "../VerificationStep";
import { PhoneFormStep, PhoneFormStore } from "@/pages/ProfilePage/stores/PhoneFormStore";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { Toast } from "@/shared/components/Alert";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupContent } from "@/shared/components/Popup/Content";
import { PopupHead } from "@/shared/components/Popup/Head";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
import { preventDefault } from "@/shared/utils/prevent";
export var SettingsNumber = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var auth = useStoreContext(AuthStore);
    var store = useStoreContext(PhoneFormStore);
    var _h = useState(""), phone = _h[0], setPhone = _h[1];
    var onSubmitPhone = function () {
        store.submitPhone(phone);
    };
    var onSubmitCode = function (code) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            store.submitPhoneCode(phone, code).then(function (success) {
                if (success) {
                    toast(function (props) { return React.createElement(Toast, __assign({ type: "success", text: "Your phone is successfully changed" }, props)); });
                }
            });
            return [2 /*return*/];
        });
    }); };
    var close = function () {
        store.reset();
        setPhone("");
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                React.createElement(Typography, { type: "text20" }, "Phone number"),
                React.createElement(Typography, { color: "black-500" }, (_b = parsePhoneNumber("+".concat((_a = auth.userInfo) === null || _a === void 0 ? void 0 : _a.phone)).number) === null || _b === void 0 ? void 0 : _b.international)),
            React.createElement(Button, { size: "s", disabled: store.loading, onClick: store.open }, "Change")),
        store.visible && (React.createElement(Popup, { width: "450px", onClose: close },
            React.createElement(PopupHead, { onClose: close }),
            store.step === PhoneFormStep.Code && (React.createElement(VerificationStep, { count: store.timer.count, error: (_d = (_c = store.errors) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.description, loading: store.loading, onResendCode: onSubmitPhone, onSubmitCode: onSubmitCode, title: "Confirm your phone", description: "Enter the code we've sent you by SMS to ".concat((_e = parsePhoneNumber("+".concat(phone)).number) === null || _e === void 0 ? void 0 : _e.international) })),
            store.step === PhoneFormStep.Form && (React.createElement(PopupBody, { as: "form", onSubmit: preventDefault(onSubmitPhone) },
                React.createElement(PopupContent, { gap: "30px" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" },
                        React.createElement(Typography, { type: "text28Bold" }, "Enter new phone number"),
                        React.createElement(Typography, { color: "black-500", textAlign: "center" }, "We will send you a SMS code. Verify your phone number to even better protect your account.")),
                    React.createElement(FormControlInput, { error: (_g = (_f = store.errors) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.description, width: "100%", label: "Phone number", disabled: store.loading, value: phone, onChange: function (e) { return setPhone(e.target.value); } })),
                React.createElement(Button, { width: "100%", onClick: onSubmitPhone, disabled: !phone || store.loading }, "Continue")))))));
});
