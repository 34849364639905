var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { keyframes, styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, NavLink } from "react-router-dom";
import { MenuLinks } from "../../constants";
import { SignOutModal } from "@/features/signout";
import { ROUTES } from "@/routes";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Overlay } from "@/shared/components/Overlay";
import privacyPolicy from "@/shared/files/privacy_policy.pdf";
import termsOfUse from "@/shared/files/terms_of_use.pdf";
import { useStoreContext } from "@/shared/hooks/useStore";
import { MenuStore } from "@/shared/stores/MenuStore";
export var SideMenu = observer(function () {
    var _a = React.useState(false), isOpenModal = _a[0], setOpenModal = _a[1];
    var menu = useStoreContext(MenuStore);
    var handleCloseModal = function () {
        setOpenModal(false);
        menu.close();
    };
    return (React.createElement(Overlay, null,
        React.createElement(Wrapper, null,
            React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(BaseIcon, { icon: "Cross", size: 12, containerSize: 24, onClick: menu.close, alignSelf: "flex-end", marginBottom: "30px" }),
                React.createElement(User, { to: generatePath(ROUTES.profile) },
                    "Profile Settings",
                    React.createElement(BaseIcon, { icon: "Navigation", size: 24, color: "black-500" })),
                React.createElement(Box, { gap: "5px", display: "flex", flexDirection: "column", alignItems: "flex-start" }, MenuLinks.map(function (_a) {
                    var link = _a.link, icon = _a.icon, label = _a.label;
                    return (React.createElement(Item, { to: generatePath(link) },
                        React.createElement(BaseIcon, { icon: icon, size: 22, containerSize: 46 }),
                        label));
                }))),
            React.createElement(Box, null,
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px", marginBottom: "60px", alignItems: "flex-start" },
                    React.createElement(StyledLink, { href: termsOfUse, target: "_blank", rel: "noreferrer" }, "Terms of Use"),
                    React.createElement(StyledLink, { href: privacyPolicy, target: "_blank", rel: "noreferrer" }, "Privacy Policy")),
                React.createElement(Typography, { color: "general-red", type: "text16Bold", onClick: function () { return setOpenModal(true); } }, "Sign out"))),
        React.createElement(SignOutModal, { isOpen: isOpenModal, onClose: handleCloseModal })));
});
var slide = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    from {\n        opacity: 0;\n        transform: translateX(50px);\n    }\n    to {\n        opacity: 1;\n        transform: none;\n    }\n"], ["\n    from {\n        opacity: 0;\n        transform: translateX(50px);\n    }\n    to {\n        opacity: 1;\n        transform: none;\n    }\n"])));
var Wrapper = styled("div")(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    gap: 60px;\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    box-sizing: border-box;\n    padding: 20px;\n    position: relative;\n    background: ", ";\n    min-height: 100vh;\n    animation: ", " 0.25s;\n    flex-grow: 1;\n"], ["\n    gap: 60px;\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    box-sizing: border-box;\n    padding: 20px;\n    position: relative;\n    background: ", ";\n    min-height: 100vh;\n    animation: ", " 0.25s;\n    flex-grow: 1;\n"])), function (props) { return props.theme.colors["black-50"]; }, slide);
var Link = styled(NavLink)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    color: ", ";\n\n    &:hover,\n    &.active {\n        color: ", ";\n    }\n"], ["\n    color: ", ";\n\n    &:hover,\n    &.active {\n        color: ", ";\n    }\n"])), function (props) { return props.theme.colors["black-400"]; }, function (props) { return props.theme.colors["black-1200"]; });
var StyledLink = styled("a")(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    color: ", ";\n\n    &:hover,\n    &.active {\n        color: ", ";\n    }\n"], ["\n    color: ", ";\n\n    &:hover,\n    &.active {\n        color: ", ";\n    }\n"])), function (props) { return props.theme.colors["black-400"]; }, function (props) { return props.theme.colors["black-1200"]; });
var Item = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    height: 46px;\n    font-size: ", ";\n"], ["\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    height: 46px;\n    font-size: ", ";\n"])), function (props) { return props.theme.text.text20.fontSize; });
var User = styled(Link)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    padding: 18px 15px;\n    border-radius: 12px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background: ", ";\n    margin-bottom: 40px;\n    color: ", ";\n"], ["\n    padding: 18px 15px;\n    border-radius: 12px;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background: ", ";\n    margin-bottom: 40px;\n    color: ", ";\n"])), function (props) { return props.theme.colors["general-white"]; }, function (props) { return props.theme.colors["black-1200"]; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
