import * as React from "react";
import { toast } from "react-toastify";
import useClipboard from "react-use-clipboard";
import { Toast } from "@/shared/components/Alert";
export var useCopy = function (text, success) {
    if (success === void 0) { success = "Copy success"; }
    var _a = useClipboard(text, { successDuration: 1000 }), isCopied = _a[0], setCopied = _a[1];
    React.useEffect(function () {
        if (isCopied) {
            toast(React.createElement(Toast, { text: success, type: "success" }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCopied]);
    return setCopied;
};
