import { formattedTokenAmount } from "@broxus/js-utils";
import BigNumber from "bignumber.js";
import React from "react";
import { BaseIcon, Typography } from "@/shared/components";
var COLOR = new Map([
    ["PENDING", "general-orange"],
    ["REJECTED", "general-red"],
]);
export var TransactionAmount = function (_a) {
    var status = _a.status, amount = _a.amount, currency = _a.currency, text = _a.text;
    var getColor = function (status) {
        var textColor = status ? COLOR.get(status) : undefined;
        if (textColor)
            return textColor;
        return BigNumber(amount).gt(0) ? "general-green" : "black-1200";
    };
    var textColor = getColor(status);
    return (React.createElement(Typography, { color: textColor, display: "flex", alignItems: "center", gap: "10px", type: text },
        formattedTokenAmount(amount),
        " ",
        currency,
        textColor === "general-red" && React.createElement(BaseIcon, { icon: "Failed" }),
        textColor === "general-orange" && React.createElement(BaseIcon, { icon: "Pending" })));
};
