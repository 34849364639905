var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import BigNumber from "bignumber.js";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { walletApi } from "@/api/wallet/api";
import { DataSync } from "@/shared/stores/DataStore";
import { parseApiError } from "@/shared/utils/error";
import { Reactions } from "@/shared/utils/reactions";
var TransferCryptoFormStore = /** @class */ (function () {
    function TransferCryptoFormStore(balanceList, accountList, recipientList) {
        this.balanceList = balanceList;
        this.accountList = accountList;
        this.recipientList = recipientList;
        this.loading = false;
        this.from = undefined;
        this.to = undefined;
        this.amount = undefined;
        this.submitErrors = [];
        this.result = undefined;
        this.confirmation = false;
        this.paymentPurpose = undefined;
        this.memo = undefined;
        this.feeData = new DataSync(walletApi.withdraw.createWithdraw.bind(walletApi));
        this.reactions = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
    }
    TransferCryptoFormStore.prototype.init = function () {
        var _this = this;
        this.reactions.create(reaction(function () { var _a; return [(_a = _this.fromAcc) === null || _a === void 0 ? void 0 : _a.currency, _this.amount, _this.to, _this.memo, _this.paymentPurpose]; }, function () {
            var _a;
            if (_this.fromAcc && _this.amount && _this.to && _this.paymentPurpose) {
                _this.feeData.sync({
                    amount: _this.amount,
                    currency: _this.fromAcc.currency,
                    type: "COUNTERPARTY",
                    counterPartyId: (_a = _this.recipient) === null || _a === void 0 ? void 0 : _a.data.id,
                    // @ts-ignore // TODO: Check it works without blockchain and remove it
                    blockchain: _this.fromAcc.blockchain,
                    fromAccount: _this.fromAcc.account,
                    meta: {
                        memo: _this.memo,
                        paymentPurpose: _this.paymentPurpose,
                    },
                }, {
                    autoConversion: true,
                    dryRun: true,
                });
            }
            else {
                _this.feeData.reset();
            }
        }, {
            fireImmediately: true,
        }), reaction(function () { return _this.from; }, function () {
            _this.balanceList.sync(_this.from);
        }, {
            fireImmediately: true,
        }));
    };
    TransferCryptoFormStore.prototype.dispose = function () {
        this.reactions.destroy();
    };
    TransferCryptoFormStore.prototype.reset = function () {
        this.loading = false;
        this.amount = undefined;
        this.submitErrors = [];
        this.result = undefined;
        this.confirmation = false;
        this.feeData.reset();
    };
    TransferCryptoFormStore.prototype.showConfirmation = function () {
        this.confirmation = true;
        this.submitErrors = [];
    };
    TransferCryptoFormStore.prototype.hideConfirmation = function () {
        this.confirmation = false;
    };
    TransferCryptoFormStore.prototype.hideErrors = function () {
        this.submitErrors = [];
    };
    TransferCryptoFormStore.prototype.submit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var errors, result, resp, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        errors = [];
                        runInAction(function () {
                            _this.loading = true;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        if (!this.feeData.value) {
                            throw new Error("feeData.value must be defined");
                        }
                        return [4 /*yield*/, walletApi.withdraw.createWithdraw(this.feeData.value.data, {
                                autoConversion: true,
                                dryRun: false,
                            })];
                    case 2:
                        resp = _a.sent();
                        result = resp.data;
                        return [4 /*yield*/, this.balanceList.sync(this.from)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        console.error(e_1);
                        errors = parseApiError(e_1);
                        return [3 /*break*/, 5];
                    case 5:
                        runInAction(function () {
                            _this.loading = false;
                            _this.submitErrors = errors;
                            _this.result = result;
                            _this.confirmation = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    TransferCryptoFormStore.prototype.paste = function () {
        return __awaiter(this, void 0, void 0, function () {
            var text;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, navigator.clipboard.readText()];
                    case 1:
                        text = _a.sent();
                        runInAction(function () {
                            _this.to = text;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(TransferCryptoFormStore.prototype, "errors", {
        get: function () {
            return __spreadArray(__spreadArray([], this.submitErrors, true), (this.feeData.error ? parseApiError(this.feeData.error) : []), true);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferCryptoFormStore.prototype, "fromAcc", {
        get: function () {
            return this.from ? this.accountList.cryptoById[this.from] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferCryptoFormStore.prototype, "recipient", {
        get: function () {
            return this.to ? this.recipientList.byId[this.to] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferCryptoFormStore.prototype, "balance", {
        get: function () {
            return this.fromAcc ? this.balanceList.data[this.fromAcc.account] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferCryptoFormStore.prototype, "enough", {
        get: function () {
            if (this.balance) {
                if (this.total) {
                    return new BigNumber(this.total).lte(this.balance.balance);
                }
                if (this.amount) {
                    return new BigNumber(this.amount).lte(this.balance.balance);
                }
            }
            return undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferCryptoFormStore.prototype, "valid", {
        get: function () {
            return this.to
                && this.amount
                && this.enough
                && new BigNumber(this.amount).gt(0)
                && !new BigNumber(this.amount).isNaN()
                && !!this.paymentPurpose;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferCryptoFormStore.prototype, "fee", {
        get: function () {
            var _a;
            return (_a = this.feeData.value) === null || _a === void 0 ? void 0 : _a.data.feeAmount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferCryptoFormStore.prototype, "total", {
        get: function () {
            var _a;
            return ((_a = this.feeData.value) === null || _a === void 0 ? void 0 : _a.data.feeAmount)
                ? new BigNumber(this.feeData.value.data.amount)
                    .plus(this.feeData.value.data.feeAmount)
                    .toFixed()
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferCryptoFormStore.prototype, "ready", {
        get: function () {
            return this.feeData.value
                && !this.feeData.loading
                && this.enough
                && this.valid;
        },
        enumerable: false,
        configurable: true
    });
    return TransferCryptoFormStore;
}());
export { TransferCryptoFormStore };
