import { ROUTES } from "@/routes";
export var HelperLinks = [
    {
        label: "Terms of Use",
        link: ROUTES.terms,
    },
    {
        label: "Privacy Policy",
        link: ROUTES.privacy,
    },
];
export var ProfileLinks = [
    {
        label: "Profile Settings",
        link: ROUTES.profile,
    },
];
export var MenuLinks = [
    {
        label: "Dashboard",
        link: ROUTES.root,
        icon: "MenuDashboard",
    },
    {
        label: "Company info",
        link: ROUTES.company,
        icon: "MenuCompany",
    },
    {
        label: "Bank accounts",
        link: ROUTES.bankAccountsList,
        icon: "Bank",
    },
    {
        label: "Digital assets",
        link: ROUTES.walletList,
        icon: "Crypto",
    },
    {
        label: "Recipients",
        link: ROUTES.recipients,
        icon: "Recipient",
    },
    {
        label: "Transactions history",
        link: ROUTES.transactions,
        icon: "MenuTransactions",
    },
];
