import BigNumber from "bignumber.js";
import { makeAutoObservable } from "mobx";
var ExchangeFormStore = /** @class */ (function () {
    function ExchangeFormStore(balances) {
        this.balances = balances;
        this.fiat1Id = undefined;
        this.fiat2Id = undefined;
        this.cryptoId = undefined;
        this.fiat1Amount = undefined;
        this.fiat2Amount = undefined;
        this.cryptoAmount = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    Object.defineProperty(ExchangeFormStore.prototype, "cryptoBalance", {
        get: function () {
            return this.cryptoId ? this.balances.data[this.cryptoId] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeFormStore.prototype, "fiat1Balance", {
        get: function () {
            return this.fiat1Id ? this.balances.data[this.fiat1Id] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeFormStore.prototype, "fiat2Balance", {
        get: function () {
            return this.fiat2Id ? this.balances.data[this.fiat2Id] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeFormStore.prototype, "cryptoEnough", {
        get: function () {
            return this.cryptoAmount && this.cryptoBalance
                ? new BigNumber(this.cryptoAmount).lte(this.cryptoBalance.balance)
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExchangeFormStore.prototype, "fiat1Enough", {
        get: function () {
            return this.fiat1Amount && this.fiat1Balance
                ? new BigNumber(this.fiat1Amount).lte(this.fiat1Balance.balance)
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    ExchangeFormStore.prototype.reset = function () {
        this.fiat1Amount = undefined;
        this.fiat2Amount = undefined;
        this.cryptoAmount = undefined;
    };
    return ExchangeFormStore;
}());
export { ExchangeFormStore };
