var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ThemeProvider } from "@mui/system";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import Zendesk from "react-zendesk";
import { ZEN_DESK_ID, ZEN_DESK_NAME } from "@/config";
import { Root } from "@/shared/components/Root";
import { theme } from "@/shared/constants/theme";
import "@/shared/styles/index.scss";
var setting = {
    color: {
        theme: "#000",
    },
    launcher: {
        chatLabel: {
            "en-US": "Help",
        },
    },
    chat: {
        departments: {
            select: ZEN_DESK_NAME,
            enabled: [ZEN_DESK_NAME],
        },
    },
    contactForm: {
        fields: [
            {
                id: "description",
                prefill: { "*": "My pre-filled description" },
            },
        ],
    },
};
var root = createRoot(document.getElementById("root"));
var queryClient = new QueryClient();
root.render(
// <React.StrictMode>
React.createElement(QueryClientProvider, { client: queryClient },
    React.createElement(BrowserRouter, null,
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(Zendesk, __assign({ defer: true, zendeskKey: ZEN_DESK_ID }, setting)),
            React.createElement(Root, null),
            React.createElement(ToastContainer, { position: "top-center", autoClose: 3000, draggable: false, closeOnClick: false, hideProgressBar: true, icon: false, closeButton: false, transition: Slide })))));
