var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
export var StyledTypography = styled(Typography, {
    shouldForwardProp: function (prop) { return !prop.startsWith("$"); },
})(function (_a) {
    var _b, _c;
    var theme = _a.theme, _d = _a.$textColor, $textColor = _d === void 0 ? "black-1200" : _d, $text = _a.$text, $wordBreak = _a.$wordBreak, $textDecoration = _a.$textDecoration, $textIndent = _a.$textIndent, $lineClamp = _a.$lineClamp, $bgColor = _a.$bgColor, props = __rest(_a, ["theme", "$textColor", "$text", "$wordBreak", "$textDecoration", "$textIndent", "$lineClamp", "$bgColor"]);
    return (__assign(__assign(__assign(__assign(__assign(__assign({ color: $textColor
            ? theme.colors[$textColor]
            : "var(--default-font-color)", fontSize: $text
            ? theme.text[$text].fontSize
            : "var(--default-font-size)" }, ($wordBreak && { wordBreak: $wordBreak })), ($textDecoration && { textDecoration: $textDecoration })), ($textIndent && { textIndent: $textIndent })), { fontWeight: (_c = (_b = props.fontWeight) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : ($text
            ? theme.text[$text].fontWeight
            : "var(--default-font-weight)"), lineHeight: $text
            ? theme.text[$text].lineHeight
            : "var(--default-line-height)", transition: "color 0.2s ease", fontFamily: theme.fontFamily }), ($lineClamp && {
        overflow: "hidden",
        whiteSpace: "initial",
        textOverflow: "ellipsis",
        WebkitLineClamp: $lineClamp,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        wordBreak: $wordBreak || "break-word",
        maxWidth: "max-content",
    })), ($bgColor && { backgroundColor: theme.colors[$bgColor] })));
});
