var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// import { data as IsoData } from "iso-3166-2"
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { FormLayout } from "@/pages/KYBPage/components/FormLayout";
import { FormRow } from "@/pages/KYBPage/components/FormRow";
import { parseArray, stringifyArray } from "@/pages/KYBPage/utils";
import { ROUTES } from "@/routes";
import { Box, FormControlInput, Switch, Typography } from "@/shared/components";
import { PhoneInput } from "@/shared/components/FormControlInput/PhoneInput";
import { FormControlMultiSelect } from "@/shared/components/FormControlMultiSelect";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { countries } from "@/shared/constants/countries";
import { ACTIVITIES, REGULATED_STATUS } from "@/shared/constants/dict";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CompanyInfoStore } from "@/shared/stores/CompanyInfoStore";
import { RequiredFieldMessage, WrongFormatMessage } from "@/shared/utils/error";
import { onCheckbox } from "@/shared/utils/input";
import { EMAIL_REGEXP } from "@/shared/utils/is-email";
export var Form3 = observer(function () {
    var _a;
    var navigate = useNavigate();
    var companyInfo = useStoreContext(CompanyInfoStore);
    var form = useForm();
    var _b = React.useState(true), addressMatchEnabled = _b[0], setAddressMatchEnabled = _b[1];
    var submit = form.handleSubmit(function (formData) { return __awaiter(void 0, void 0, void 0, function () {
        var meta, data;
        return __generator(this, function (_a) {
            meta = formData.meta;
            data = __assign(__assign({}, formData), { meta: __assign(__assign({}, meta), { physicalCountryCode: addressMatchEnabled
                        ? meta === null || meta === void 0 ? void 0 : meta.registeredCountryCode
                        : meta === null || meta === void 0 ? void 0 : meta.physicalCountryCode, physicalState: addressMatchEnabled
                        ? meta === null || meta === void 0 ? void 0 : meta.registeredState
                        : meta === null || meta === void 0 ? void 0 : meta.physicalState, physicalStreetAddress: addressMatchEnabled
                        ? meta === null || meta === void 0 ? void 0 : meta.registeredStreetAddress
                        : meta === null || meta === void 0 ? void 0 : meta.physicalStreetAddress, physicalCity: addressMatchEnabled
                        ? meta === null || meta === void 0 ? void 0 : meta.registeredCity
                        : meta === null || meta === void 0 ? void 0 : meta.physicalCity, physicalZipCode: addressMatchEnabled
                        ? meta === null || meta === void 0 ? void 0 : meta.registeredZipCode
                        : meta === null || meta === void 0 ? void 0 : meta.physicalZipCode }) });
            companyInfo.update(data).then(function (success) {
                if (success)
                    navigate(generatePath(ROUTES.kybFormId, { id: 4 }));
            });
            return [2 /*return*/];
        });
    }); });
    React.useEffect(function () {
        var _a;
        form.reset(companyInfo.companyInfo);
        var meta = (_a = companyInfo.companyInfo) === null || _a === void 0 ? void 0 : _a.meta;
        var cityMatch = (meta === null || meta === void 0 ? void 0 : meta.registeredCity) === (meta === null || meta === void 0 ? void 0 : meta.physicalCity);
        var stateMatch = (meta === null || meta === void 0 ? void 0 : meta.registeredState) === (meta === null || meta === void 0 ? void 0 : meta.physicalState);
        var countryCodeMatch = (meta === null || meta === void 0 ? void 0 : meta.registeredCountryCode) === (meta === null || meta === void 0 ? void 0 : meta.physicalCountryCode);
        var streetAddressMatch = (meta === null || meta === void 0 ? void 0 : meta.registeredStreetAddress) === (meta === null || meta === void 0 ? void 0 : meta.physicalStreetAddress);
        var zipCodeMatch = (meta === null || meta === void 0 ? void 0 : meta.registeredZipCode) === (meta === null || meta === void 0 ? void 0 : meta.physicalZipCode);
        var emptyForm = !(meta === null || meta === void 0 ? void 0 : meta.physicalCity)
            && !(meta === null || meta === void 0 ? void 0 : meta.physicalState)
            && !(meta === null || meta === void 0 ? void 0 : meta.physicalCountryCode)
            && !(meta === null || meta === void 0 ? void 0 : meta.physicalStreetAddress)
            && !(meta === null || meta === void 0 ? void 0 : meta.physicalZipCode);
        var addressMatch = cityMatch
            && stateMatch
            && countryCodeMatch
            && streetAddressMatch
            && zipCodeMatch;
        setAddressMatchEnabled(emptyForm || addressMatch);
    }, [form, companyInfo.companyInfo]);
    // const countryCode = useWatch({
    //     control: form.control,
    //     name: "meta.registeredCountryCode",
    // })
    // const treeOptions = React.useMemo(() => {
    //     const objStates = Object.entries(IsoData).find(
    //         ([key]) => key === countryCode,
    //     )
    //     if (objStates) {
    //         return Object.entries(objStates[1].sub).map(([key, value]) => ({
    //             key,
    //             label: value.name,
    //         }))
    //     }
    //     return []
    // }, [countryCode])
    return (React.createElement(FormLayout, { onSubmit: submit, onBack: function () { return navigate(generatePath(ROUTES.kybFormId, { id: 2 })); }, index: 3, error: (_a = companyInfo.errors[0]) === null || _a === void 0 ? void 0 : _a.description, title: "Company details", desc: "Fill in the fields below with your company information. The company's data must match with the uploaded documents. Please, use Latin letters only.", loading: !companyInfo.ready || companyInfo.loading },
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "registrationNumber", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Registered Number", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "meta.registeredCountryCode", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: countries.map(function (item) { return ({
                            key: item.codeAlpha2,
                            label: "".concat(item.flag, " ").concat(item.name),
                        }); }), label: "Registered Country", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.registeredState", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (
                    // <FormControlSelect
                    //     name={field.name}
                    //     onChange={(_, v) => field.onChange(v?.key)}
                    //     value={field.value}
                    //     options={treeOptions}
                    //     label="Registered State/Province"
                    //     error={error?.message}
                    //     errorPosition="relative"
                    // />
                    React.createElement(FormControlInput, __assign({}, field, { label: "Registered State/Province", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "meta.registeredCity", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Registered City", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.registeredStreetAddress", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Registered Street Address", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "meta.registeredZipCode", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Registered Zip Code/Postal Code", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.companyPhoneNumber", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(PhoneInput, { onChange: field.onChange, value: field.value, error: error === null || error === void 0 ? void 0 : error.message, disabled: companyInfo.loading || field.disabled }));
                } }),
            React.createElement(Controller, { control: form.control, name: "meta.companyWebsite", render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { InputProps: {
                            endAdornment: (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")),
                        }, disabled: companyInfo.loading || field.disabled, label: "Website address", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.companyEmail", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                    pattern: {
                        value: EMAIL_REGEXP,
                        message: WrongFormatMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Email", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "meta.establishedOn", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(MaskedInput, __assign({}, field, { mask: [
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                        ], render: function (innerRef, props) { return (React.createElement(FormControlInput, __assign({}, props, { ref: innerRef, value: props.defaultValue, disabled: companyInfo.loading || field.disabled, label: "Established on", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative", placeholder: "YYYY-MM-DD" }))); } })));
                } })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.stateOfIncorporation", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlInput, __assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "State of Incorporation", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                } }),
            React.createElement(Controller, { control: form.control, name: "meta.businessJurisdictions", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlMultiSelect, { name: field.name, onChange: function (_, v) {
                            field.onChange(stringifyArray(v === null || v === void 0 ? void 0 : v.map(function (item) { return item.key; })));
                        }, multiple: true, value: parseArray(field.value), options: countries.map(function (item) { return ({
                            key: item.codeAlpha2,
                            label: "".concat(item.flag, " ").concat(item.name),
                        }); }), label: "Business jurisdictions", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.fundsSendReceiveJurisdictions", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlMultiSelect, { name: field.name, onChange: function (_, v) {
                            field.onChange(stringifyArray(v === null || v === void 0 ? void 0 : v.map(function (item) { return item.key; })));
                        }, multiple: true, value: parseArray(field.value), options: countries.map(function (item) { return ({
                            key: item.codeAlpha2,
                            label: "".concat(item.flag, " ").concat(item.name),
                        }); }), label: "Funds send receive jurisdictions", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } }),
            React.createElement(Controller, { control: form.control, name: "meta.engageInActivities", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlMultiSelect, { name: field.name, onChange: function (_, v) {
                            field.onChange(stringifyArray(v === null || v === void 0 ? void 0 : v.map(function (item) { return item.key; })));
                        }, multiple: true, value: parseArray(field.value), options: ACTIVITIES.map(function (item) { return ({
                            key: item.key,
                            label: item.label,
                        }); }), label: "Engage in activities", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } })),
        React.createElement(FormRow, null,
            React.createElement(Controller, { control: form.control, name: "meta.regulatedStatus", rules: {
                    required: {
                        value: true,
                        message: RequiredFieldMessage.description,
                    },
                }, render: function (_a) {
                    var field = _a.field, error = _a.fieldState.error;
                    return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: REGULATED_STATUS.map(function (item) { return ({
                            key: item.key,
                            label: item.label,
                        }); }), label: "Regulated status", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                } })),
        React.createElement(Box, { height: "1px", bgColor: "black-100", margin: "20px 0" }),
        React.createElement(Typography, { type: "text16", fontWeight: 450, variant: "body1", bgColor: "black-50", display: "flex", alignItems: "center", justifyContent: "space-between", padding: "36px 30px", borderRadius: "16px", color: "black-500" },
            "Physical address matches registered address",
            React.createElement(Switch, { onChange: onCheckbox(setAddressMatchEnabled), checked: addressMatchEnabled })),
        !addressMatchEnabled && (React.createElement(React.Fragment, null,
            React.createElement(FormRow, null,
                React.createElement(Controller, { control: form.control, name: "meta.physicalCountryCode", rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, render: function (_a) {
                        var field = _a.field, error = _a.fieldState.error;
                        return (React.createElement(FormControlSelect, { name: field.name, onChange: function (_, v) { return field.onChange(v === null || v === void 0 ? void 0 : v.key); }, value: field.value, options: countries.map(function (item) { return ({
                                key: item.codeAlpha2,
                                label: "".concat(item.flag, " ").concat(item.name),
                            }); }), label: "Physical Country", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" }));
                    } }),
                React.createElement(Controller, { control: form.control, name: "meta.physicalState", rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, render: function (_a) {
                        var field = _a.field, error = _a.fieldState.error;
                        return (React.createElement(FormControlInput, __assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Physical State/Province", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                    } })),
            React.createElement(FormRow, null,
                React.createElement(Controller, { control: form.control, name: "meta.physicalStreetAddress", rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, render: function (_a) {
                        var field = _a.field, error = _a.fieldState.error;
                        return (React.createElement(FormControlInput, __assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Physical Street Address", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                    } }),
                React.createElement(Controller, { control: form.control, name: "meta.physicalCity", rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, render: function (_a) {
                        var field = _a.field, error = _a.fieldState.error;
                        return (React.createElement(FormControlInput, __assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Physical City", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                    } })),
            React.createElement(FormRow, null,
                React.createElement(Controller, { control: form.control, name: "meta.physicalZipCode", rules: {
                        required: {
                            value: true,
                            message: RequiredFieldMessage.description,
                        },
                    }, render: function (_a) {
                        var field = _a.field, error = _a.fieldState.error;
                        return (React.createElement(FormControlInput, __assign({}, field, { disabled: companyInfo.loading || field.disabled, label: "Physical Zip Code/Postal Code", error: error === null || error === void 0 ? void 0 : error.message, errorPosition: "relative" })));
                    } }))))));
});
