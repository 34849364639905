import React from "react";
import { DepositInfo } from "./DepositInfo";
import { ExchangeInfo } from "./ExchangeInfo";
import { TransferInfo } from "./TransferInfo";
import { WithdrawInfo } from "./WithdrawInfo";
import { BalanceLogReasonType } from "@/api/wallet/types";
import { Popup } from "@/shared/components/Popup";
import { PopupCloseBtn } from "@/shared/components/Popup/CloseBtn";
export var TransactionInfo = function (_a) {
    var transaction = _a.transaction, onClose = _a.onClose;
    var children = null;
    // if (transaction?.reasonType.match("UNKNOWN")) return null
    if (transaction === null || transaction === void 0 ? void 0 : transaction.reasonType.match("WITHDRAWAL")) {
        children = React.createElement(WithdrawInfo, { transaction: transaction });
    }
    else if (transaction === null || transaction === void 0 ? void 0 : transaction.reasonType.match("DEPOSIT")) {
        children = React.createElement(DepositInfo, { transaction: transaction });
    }
    else if ((transaction === null || transaction === void 0 ? void 0 : transaction.reasonType) === BalanceLogReasonType.DIRECT_EXCHANGE
        || (transaction === null || transaction === void 0 ? void 0 : transaction.reasonType) === BalanceLogReasonType.DIRECT_EXCHANGE_FEE) {
        children = React.createElement(ExchangeInfo, { transaction: transaction });
    }
    else {
        children = React.createElement(TransferInfo, { transaction: transaction });
    }
    return transaction
        ? (React.createElement(Popup, { width: "450px", onClose: onClose },
            React.createElement(PopupCloseBtn, { position: "absolute", top: "20px", right: "20px", onClick: onClose }),
            children))
        : null;
};
