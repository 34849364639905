import { styled } from "@mui/material/styles";
export var StyledContainer = styled("label")(function (_a) {
    var disabled = _a.disabled;
    return ({
        display: "flex",
        gap: "10px",
        alignItems: "flex-start",
        cursor: disabled ? "not-allowed" : "pointer",
    });
});
