var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled } from "@mui/material/styles";
import { capitalizeFirstLetter } from "@/shared/lib";
export var StyledBox = styled("div", {
    shouldForwardProp: function (prop) { return !prop.startsWith("$"); },
})(function (_a) {
    var theme = _a.theme, $bgColor = _a.$bgColor, $borderColor = _a.$borderColor, $borderWidth = _a.$borderWidth, $borderStyle = _a.$borderStyle, $backdropFilter = _a.$backdropFilter, $borders = _a.$borders, $variant = _a.$variant;
    return (__assign(__assign({ backgroundColor: $bgColor ? theme.colors[$bgColor] : undefined, backdropFilter: $backdropFilter }, ($borders
        ? $borders.reduce(function (acc, name) {
            var value = capitalizeFirstLetter(name);
            acc["border".concat(value, "Color")] = theme.colors[$borderColor || "black-1200"];
            acc["border".concat(value, "Width")] = $borderWidth;
            acc["border".concat(value, "Style")] = $borderStyle;
            return acc;
        }, {})
        : {
            borderColor: $borderColor
                ? theme.colors[$borderColor]
                : undefined,
            borderWidth: $borderWidth,
            borderStyle: $borderStyle,
        })), ($variant === "styled"
        ? {
            position: "relative",
            borderRadius: "8px",
            overflow: "hidden",
            boxSizing: "border-box",
        }
        : {})));
});
