// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si, dp) {
    if (si === void 0) { si = true; }
    if (dp === void 0) { dp = 1; }
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return "".concat(bytes, " B");
    }
    var units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    var u = -1;
    var r = Math.pow(10, dp);
    do {
        // eslint-disable-next-line no-param-reassign
        bytes /= thresh // eslint-disable-next-line no-plusplus
        ;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh
        && u < units.length - 1);
    return "".concat(bytes.toFixed(dp), " ").concat(units[u]);
}
