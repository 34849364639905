import * as React from "react";
import * as ReactDOM from "react-dom";
import styles from "./index.module.scss";
import { blockPage, unblockPage } from "@/shared/utils/page";
export var Overlay = function (_a) {
    var children = _a.children;
    React.useEffect(function () {
        blockPage();
        return function () {
            unblockPage();
        };
    }, []);
    return ReactDOM.createPortal(React.createElement("div", { className: styles.overlay }, children), document.body);
};
