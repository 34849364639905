var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Panel } from "@/pages/PaymentPage/components/Panel";
import { PaymentPromo } from "@/pages/PaymentPage/components/Promo";
import { PaymentCryptoFormStore } from "@/pages/PaymentPage/stores/PaymentCryptoFormStore";
import { VerificationStore } from "@/pages/PaymentPage/stores/VerificationStore";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { Amount } from "@/shared/components/Amount";
import { useStoreContext } from "@/shared/hooks/useStore";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
import { onChange } from "@/shared/utils/input";
import { preventDefault } from "@/shared/utils/prevent";
export var TrxForm = observer(function () {
    var form = useStoreContext(PaymentCryptoFormStore);
    var verification = useStoreContext(VerificationStore);
    var subscription = useStoreContext(SubscriptionStore);
    return (React.createElement(Wrapper, null,
        React.createElement(PaymentPromo, null),
        React.createElement(Panel, { onSubmit: preventDefault(form.submit) },
            React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
                React.createElement(Typography, { type: "text18", fontWeight: 600 }, "Total"),
                React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" },
                    subscription.oldAmount && (React.createElement(Typography, { type: "text18", fontWeight: 600, color: "black-500", textDecoration: "line-through" }, form.symbol
                        ? (React.createElement(Amount, { amount: subscription.oldAmount.toString(), from: "USDT", to: form.symbol }))
                        : null)),
                    React.createElement(Typography, { type: "text18", fontWeight: 600 }, subscription.amount && form.symbol
                        ? (React.createElement(Amount, { amount: subscription.amount.toString(), from: "USDT", to: form.symbol }))
                        : null))),
            React.createElement(FormControlInput, { label: "Transaction ID", value: form.trxHash, onChange: onChange(action(function (val) {
                    form.trxHash = val;
                })), disabled: !subscription.amount
                    || !form.symbol
                    || form.loading
                    || form.success }),
            React.createElement(Button, { type: "submit", disabled: !subscription.amount
                    || !form.symbol
                    || form.loading
                    || (form.needTxHash ? !form.trxHash : false)
                    || form.success
                    || !verification.verification }, "Payment was sent"),
            React.createElement(Typography, { type: "text12", color: "black-500", textAlign: "center" }, "Blockchain transaction processing time depends on the network load. This may take a few minutes."))));
});
var Wrapper = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    max-width: 420px;\n    width: 100%;\n\n    ", " {\n        max-width: unset;\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    max-width: 420px;\n    width: 100%;\n\n    ", " {\n        max-width: unset;\n    }\n"])), function (props) { return props.theme.breakpoints.down("md"); });
var templateObject_1;
