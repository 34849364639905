var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import { compose, spacing } from "@mui/system";
import classNames from "classnames";
import React, { memo } from "react";
import { BaseIcon } from "../BaseIcon";
import { StyledInput } from "./styled";
export var Input = React.forwardRef(function (_a, ref) {
    var maxLength = _a.maxLength, inputProps = _a.inputProps, disabled = _a.disabled, value = _a.value, onChange = _a.onChange, _b = _a.size, size = _b === void 0 ? "l" : _b, _c = _a.width, width = _c === void 0 ? "100%" : _c, className = _a.className, inputRef = _a.inputRef, containerRef = _a.containerRef, textAlign = _a.textAlign, type = _a.type, InputProps = _a.InputProps, props = __rest(_a, ["maxLength", "inputProps", "disabled", "value", "onChange", "size", "width", "className", "inputRef", "containerRef", "textAlign", "type", "InputProps"]);
    var _d = React.useState(false), showPassword = _d[0], setShowPassword = _d[1];
    var inputPasswordType = showPassword ? "text" : "password";
    var inputType = type === "password" ? inputPasswordType : type;
    var endAdornment = type === "password"
        ? (React.createElement(InputAdornment, { position: "end" },
            React.createElement(BaseIcon, { onClick: function () { return setShowPassword(function (p) { return !p; }); }, icon: showPassword ? "ClosedEye" : "Eye" })))
        : InputProps === null || InputProps === void 0 ? void 0 : InputProps.endAdornment;
    return (React.createElement(StyledInput, __assign({ ref: containerRef, value: value !== null && value !== void 0 ? value : "", disabled: disabled, onChange: onChange, "$size": size, "$width": width, "$textAlign": textAlign, inputRef: ref, inputProps: __assign({ maxLength: maxLength }, inputProps), className: classNames(className, "BaseInput", {
            "BaseInput-clickable": props.onClick,
            "No-label": !props.label,
        }), type: inputType }, props, { InputProps: __assign(__assign({}, InputProps), { endAdornment: endAdornment }) })));
});
export var BaseInput = memo(styled(Input, {
    shouldForwardProp: function (prop) { return !spacing.filterProps.includes(prop); },
})(compose(spacing)));
