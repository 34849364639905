import { observer } from "mobx-react-lite";
import * as React from "react";
import { Box } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { PopupOption } from "@/shared/components/Popup/Option";
import { useStoreContext } from "@/shared/hooks/useStore";
import { BalanceType, BalanceTypeStore } from "@/shared/stores/BalanceTypeStore";
export var SelectAssetPopup = observer(function (_a) {
    var onClose = _a.onClose;
    var balance = useStoreContext(BalanceTypeStore);
    var onClick = function (type) { return function () {
        balance.setType(type);
        onClose();
    }; };
    return (React.createElement(Popup, { onClose: onClose, width: "450px" },
        React.createElement(PopupHead, { onClose: onClose }, "Select assets"),
        React.createElement(Box, { height: "1px", bgColor: "black-50" }),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", padding: "20px 25px 25px" },
            React.createElement(PopupOption, { desc: "Show fiat and cryptocurrency in the total balance", onClick: onClick(BalanceType.All), title: "All", selected: balance.type === BalanceType.All }),
            React.createElement(PopupOption, { desc: "Show only cryptocurrency in the total balance", onClick: onClick(BalanceType.Crypto), title: "Digital", selected: balance.type === BalanceType.Crypto }),
            React.createElement(PopupOption, { desc: "Show only fiat in total balance", onClick: onClick(BalanceType.Fiat), title: "Traditional", selected: balance.type === BalanceType.Fiat }))));
});
