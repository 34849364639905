import React from "react";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
export var SuccessPopup = function (_a) {
    var onClose = _a.onClose;
    return (React.createElement(Popup, { onClose: onClose, bgColor: "general-green", width: "450px" },
        React.createElement(PopupBody, { padTop: true, minHeight: "350px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "50px", alignItems: "center" },
                React.createElement(Typography, { type: "text28Bold", textAlign: "center", color: "general-white" }, "Payment success")),
            React.createElement(Button, { mt: "auto", width: "100%", variant: "White", onClick: onClose }, "Success"))));
};
