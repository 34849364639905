var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable react-hooks/exhaustive-deps */
import { formattedTokenAmount } from "@broxus/js-utils";
import BN, { BigNumber } from "bignumber.js";
import debounce from "lodash.debounce";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useCreateDirectExchange } from "@/api/wallet";
import { ExchangeModal } from "@/pages/ExchangePage/components/Modal";
import { ExchangeFormStore } from "@/pages/ExchangePage/stores/ExchangeFormStore";
import { ExchangeType, ROUTES } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { AccountSelect } from "@/shared/components/AccountSelect";
import { BankAccountPopup } from "@/shared/components/BankAccountPopup";
import { BankIcon } from "@/shared/components/BankIcon";
import { ExchangeField } from "@/shared/components/ExchangeField";
import { ExchangeInput } from "@/shared/components/ExchangeInput";
import { HintBtn } from "@/shared/components/HintBtn";
import { Info } from "@/shared/components/Info";
import { OperationLayout } from "@/shared/components/OperationLayout";
import { SectionAmount } from "@/shared/components/SectionAmount";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { TokenPopup } from "@/shared/components/TokenPopup";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { sliceAddress } from "@/shared/utils/slice-address";
export var Exchange = observer(function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var type = _a.type;
    var media = useMedia();
    var account1Popup = useToggler();
    var account2Popup = useToggler();
    var params = useParams();
    var tokenPopup = useToggler();
    var navigate = useNavigate();
    var accounts = useStoreContext(AccountListStore);
    var search = useSearchParams()[0];
    var from = search.get("from");
    var to = search.get("to");
    var balances = useStoreContext(BalanceListStore);
    var form = useStore(ExchangeFormStore, balances);
    var currencyList = useStoreContext(CurrencyListStore);
    var _h = React.useState(false), isOpenModal = _h[0], setOpenModal = _h[1];
    var _j = React.useState(""), preexchangeErr = _j[0], setPreexchangeErr = _j[1];
    var _k = React.useState(false), exchangeLoad = _k[0], setExchangeLoad = _k[1];
    var fiat1Id = params.type === ExchangeType.Crypto ? to : from;
    var fiat2Id = params.type === ExchangeType.FiatFiat ? to : undefined;
    var cryptoId = params.type === ExchangeType.Crypto ? from : to;
    var fiat1Acc = fiat1Id ? accounts.byId[fiat1Id] : undefined;
    var fiat2Acc = fiat2Id ? accounts.byId[fiat2Id] : undefined;
    var cryptoAcc = cryptoId ? accounts.byId[cryptoId] : undefined;
    var fromAcc = params.type === ExchangeType.Crypto ? cryptoAcc : fiat1Acc;
    var toAcc = params.type === ExchangeType.FiatFiat
        ? fiat2Acc
        : ExchangeType.Crypto
            ? fiat1Acc
            : cryptoAcc;
    var fiat1Error = params.type !== ExchangeType.Crypto && form.fiat1Enough === false
        ? "Enter a smaller amount"
        : undefined;
    var cryptoError = params.type === ExchangeType.Crypto && form.cryptoEnough === false
        ? "Enter a smaller amount"
        : undefined;
    React.useEffect(function () {
        if (from || to) {
            balances.sync(from, to);
        }
    }, [balances, from, to]);
    React.useEffect(function () {
        runInAction(function () {
            form.cryptoId = cryptoId !== null && cryptoId !== void 0 ? cryptoId : undefined;
            form.fiat1Id = fiat1Id !== null && fiat1Id !== void 0 ? fiat1Id : undefined;
            form.fiat2Id = fiat2Id !== null && fiat2Id !== void 0 ? fiat2Id : undefined;
        });
    }, [cryptoId, fiat1Id, fiat2Id, form]);
    var _l = useCreateDirectExchange({}), mutateAsync = _l.mutateAsync, data = _l.data, isPendingPreExchange = _l.isPending, reset = _l.reset;
    var preExchangeInfo = data === null || data === void 0 ? void 0 : data.data;
    var preExchange = React.useCallback(function (value) { return __awaiter(void 0, void 0, void 0, function () {
        var toAccLabel, isFiat, isFiatFiat, field_1, amount_1, data_1, fee, e_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    toAccLabel = getCurrLabel(true);
                    if (exchangeLoad)
                        return [2 /*return*/];
                    if (!value || new BN(value).eq(0))
                        return [2 /*return*/];
                    if (!toAccLabel)
                        return [2 /*return*/];
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 5, , 6]);
                    setExchangeLoad(true);
                    isFiat = params.type === ExchangeType.Fiat;
                    isFiatFiat = params.type === ExchangeType.FiatFiat;
                    field_1 = isFiatFiat
                        ? "fiat2Amount"
                        : isFiat
                            ? "cryptoAmount"
                            : "fiat1Amount";
                    amount_1 = "";
                    if (!(from && to && (fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency) && !!Number(value))) return [3 /*break*/, 4];
                    return [4 /*yield*/, mutateAsync({
                            dryRun: true,
                            data: {
                                fromAccount: from,
                                toAccount: to,
                                fromCurrency: fromAcc.currency,
                                toCurrency: toAccLabel,
                                fromAmount: value,
                            },
                        })];
                case 2:
                    data_1 = _d.sent();
                    amount_1 = data_1.data.toAmount;
                    if (!(data_1.data.feeAmount && data_1.data.feeCurrency)) return [3 /*break*/, 4];
                    return [4 /*yield*/, currencyList.convert(data_1.data.fromCurrency, data_1.data.toCurrency, data_1.data.feeAmount)];
                case 3:
                    fee = _d.sent();
                    amount_1 = new BN(amount_1).minus(fee !== null && fee !== void 0 ? fee : "0").toFixed();
                    _d.label = 4;
                case 4:
                    runInAction(function () {
                        form[field_1] = amount_1;
                    });
                    setExchangeLoad(false);
                    return [3 /*break*/, 6];
                case 5:
                    e_1 = _d.sent();
                    setPreexchangeErr((_c = (_b = (_a = e_1 === null || e_1 === void 0 ? void 0 : e_1.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message) !== null && _c !== void 0 ? _c : "");
                    setExchangeLoad(false);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [from, to, fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency, toAcc === null || toAcc === void 0 ? void 0 : toAcc.currency, mutateAsync]);
    var onSuccess = function () {
        reset();
        form.reset();
        if (from || to) {
            balances.sync(from, to);
        }
    };
    var onCloseModal = function () {
        setOpenModal(false);
        setPreexchangeErr("");
    };
    var debounceExchange = React.useCallback(debounce(preExchange, 1000), [
        preExchange,
    ]);
    var defaultAmount = params.type === ExchangeType.Crypto
        ? form.cryptoAmount
        : form.fiat1Amount;
    React.useEffect(function () {
        debounceExchange(defaultAmount);
    }, [from, defaultAmount]);
    var isDisabledExchange = type === ExchangeType.FiatFiat
        ? !!fiat1Error
            || !Number(form.fiat1Amount)
            || !Number(form.fiat2Amount)
        : !!fiat1Error
            || !!cryptoError
            || !Number(form.fiat1Amount)
            || !Number(form.cryptoAmount);
    var fiat1Field = (React.createElement(ExchangeField, { error: fiat1Error, input: React.createElement(ExchangeInput, { decimals: (fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.currency)
                ? (_b = currencyList.bySlug[fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.currency.toLowerCase()]) === null || _b === void 0 ? void 0 : _b.decimals
                : undefined, disabled: params.type === ExchangeType.Crypto, invalid: !!fiat1Error, postFix: fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.currency.toUpperCase(), value: params.type === ExchangeType.Crypto
                ? preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount
                : form.fiat1Amount, onChange: action(function (value) {
                form.fiat1Amount = value;
            }) }), label: params.type === ExchangeType.FiatFiat
            ? "You send from bank"
            : params.type === ExchangeType.Fiat
                ? "You send from bank"
                : "You get to bank", select: React.createElement(AccountSelect, { name: fiat1Acc ? sliceAddress(fiat1Acc.account) : undefined, descLoading: !form.fiat1Balance, desc: form.fiat1Balance
                ? "".concat(formattedTokenAmount(form.fiat1Balance.balance), " ").concat(form.fiat1Balance.currency.toUpperCase())
                : undefined, icon: fiat1Acc ? React.createElement(BankIcon, { symbol: fiat1Acc.currency, size: 24 }) : undefined, onClick: account1Popup.on }) }));
    var fiat2Field = (React.createElement(ExchangeField, { input: React.createElement(ExchangeInput, { decimals: (fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.currency)
                ? (_c = currencyList.bySlug[fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.currency.toLowerCase()]) === null || _c === void 0 ? void 0 : _c.decimals
                : undefined, disabled: true, postFix: fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.currency.toUpperCase(), value: params.type === ExchangeType.FiatFiat
                ? preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount
                : form.fiat2Amount }), label: "You get to bank", select: React.createElement(AccountSelect, { name: fiat2Acc ? sliceAddress(fiat2Acc.account) : undefined, descLoading: !form.fiat2Balance, desc: form.fiat2Balance
                ? "".concat(formattedTokenAmount(form.fiat2Balance.balance), " ").concat(form.fiat2Balance.currency.toUpperCase())
                : undefined, icon: fiat2Acc ? React.createElement(BankIcon, { symbol: fiat2Acc.currency, size: 24 }) : undefined, onClick: account2Popup.on }) }));
    var cryptoField = (React.createElement(ExchangeField, { error: cryptoError, input: React.createElement(ExchangeInput, { decimals: (cryptoAcc === null || cryptoAcc === void 0 ? void 0 : cryptoAcc.currency)
                ? (_d = currencyList.bySlug[cryptoAcc === null || cryptoAcc === void 0 ? void 0 : cryptoAcc.currency.toLowerCase()]) === null || _d === void 0 ? void 0 : _d.decimals
                : undefined, disabled: params.type === ExchangeType.Fiat, invalid: !!cryptoError, postFix: cryptoAcc === null || cryptoAcc === void 0 ? void 0 : cryptoAcc.currency.toUpperCase(), value: params.type === ExchangeType.Crypto
                ? form === null || form === void 0 ? void 0 : form.cryptoAmount
                : preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount, onChange: action(function (value) {
                form.cryptoAmount = value;
            }) }), label: params.type === ExchangeType.Crypto
            ? "You send crypto"
            : "You get crypto", select: React.createElement(AccountSelect, { name: cryptoAcc ? sliceAddress(cryptoAcc.account) : undefined, descLoading: !form.cryptoBalance, desc: form.cryptoBalance
                ? "".concat(formattedTokenAmount(form.cryptoBalance.balance), " ").concat(form.cryptoBalance.currency.toUpperCase())
                : undefined, icon: cryptoAcc ? React.createElement(TokenIcon, { symbol: cryptoAcc.currency, size: 24 }) : undefined, onClick: tokenPopup.on }) }));
    var rate = (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount) && (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)
        ? BN(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)
            .div(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount)
            .toFixed(6, BigNumber.ROUND_DOWN)
        : undefined;
    var totalExchangeAmount = preExchangeInfo
        ? formattedTokenAmount(new BN((_e = preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount) !== null && _e !== void 0 ? _e : 0)
            .plus((_f = preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.feeAmount) !== null && _f !== void 0 ? _f : 0)
            .toFixed(2))
        : "0";
    var getCurrLabel = function (reverseOn) {
        if (!(cryptoAcc === null || cryptoAcc === void 0 ? void 0 : cryptoAcc.currency) && !(fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency))
            return "Currency";
        if (params.type === ExchangeType.FiatFiat) {
            return reverseOn && toAcc ? toAcc.currency : fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency;
        }
        if (params.type === ExchangeType.Fiat) {
            return reverseOn && cryptoAcc
                ? cryptoAcc.currency
                : fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency;
        }
        if (params.type === ExchangeType.Crypto) {
            return reverseOn ? toAcc === null || toAcc === void 0 ? void 0 : toAcc.currency : fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency;
        }
        return reverseOn ? fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency : toAcc === null || toAcc === void 0 ? void 0 : toAcc.currency;
    };
    var info = [
        {
            label: "Amount",
            value: (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)
                ? "".concat(formattedTokenAmount(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount), " ").concat(fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency.toUpperCase())
                : "–",
        },
        {
            label: "Transaction fee",
            value: (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.feeAmount) && preExchangeInfo.feeCurrency
                ? "".concat(formattedTokenAmount(preExchangeInfo.feeAmount), " ").concat(preExchangeInfo.feeCurrency.toUpperCase())
                : "–",
        },
        {
            label: "".concat(getCurrLabel(true), " rate"),
            value: rate ? "".concat(rate, " ").concat(getCurrLabel()) : "–",
        },
        {
            label: "Processing time",
            value: "Defined by bank",
        },
    ];
    var showOnlyCurrencyBank1 = (function () {
        if (params.type === ExchangeType.Crypto)
            return "USD";
        if (params.type === ExchangeType.Fiat)
            return "USD";
        if (params.type !== ExchangeType.FiatFiat)
            return undefined;
        if (fiat2Acc && (fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.currency) !== "USD")
            return "USD";
        return undefined;
    })();
    var hideCurrencyBank1 = (function () {
        if (params.type !== ExchangeType.FiatFiat)
            return undefined;
        if (fiat2Acc && (fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.currency) === "USD")
            return "USD";
        return undefined;
    })();
    var showOnlyCurrencyBank2 = (function () {
        if (params.type === ExchangeType.Crypto)
            return "USD";
        if (params.type === ExchangeType.Fiat)
            return "USD";
        if (params.type !== ExchangeType.FiatFiat)
            return undefined;
        if (fiat1Acc && (fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.currency) !== "USD")
            return "USD";
        return undefined;
    })();
    var hideCurrencyBank2 = (function () {
        if (params.type !== ExchangeType.FiatFiat)
            return undefined;
        if (fiat1Acc && (fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.currency) === "USD")
            return "USD";
        return undefined;
    })();
    var handleRouteId = function (accId, type) {
        if (type === ExchangeType.Crypto) {
            navigate(generateQueryPath(ROUTES.exchange, {
                type: params.type,
            }, params.type === ExchangeType.Crypto
                ? {
                    from: (fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.account) === accId ? "" : accId,
                    to: to,
                }
                : {
                    from: from,
                    to: (toAcc === null || toAcc === void 0 ? void 0 : toAcc.account) === accId ? "" : accId,
                }), { replace: true });
        }
        if (type === ExchangeType.Fiat) {
            navigate(generateQueryPath(ROUTES.exchange, { type: params.type }, params.type === ExchangeType.Crypto
                ? {
                    from: from,
                    to: (toAcc === null || toAcc === void 0 ? void 0 : toAcc.account) === accId ? "" : accId,
                }
                : {
                    from: (fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.account) === accId ? "" : accId,
                    to: to,
                }), { replace: true });
        }
        if (type === ExchangeType.FiatFiat) {
            navigate(generateQueryPath(ROUTES.exchange, { type: params.type }, {
                from: from,
                to: (toAcc === null || toAcc === void 0 ? void 0 : toAcc.account) === accId ? "" : accId,
            }), { replace: true });
        }
    };
    return (React.createElement(React.Fragment, null,
        tokenPopup.enabled && (React.createElement(TokenPopup, { selectedId: cryptoAcc === null || cryptoAcc === void 0 ? void 0 : cryptoAcc.account, onClose: tokenPopup.off, onSelect: function (id) {
                tokenPopup.off();
                form.reset();
                handleRouteId(id, ExchangeType.Crypto);
            } })),
        account1Popup.enabled && (React.createElement(BankAccountPopup, { hideAccount: fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.account, selectedId: fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.account, onClose: account1Popup.off, hideCurrency: hideCurrencyBank1, showOnlyCurrency: showOnlyCurrencyBank1, onSelect: function (id) {
                account1Popup.off();
                form.reset();
                handleRouteId(id, ExchangeType.Fiat);
            } })),
        account2Popup.enabled && (React.createElement(BankAccountPopup, { hideAccount: fiat1Acc === null || fiat1Acc === void 0 ? void 0 : fiat1Acc.account, selectedId: fiat2Acc === null || fiat2Acc === void 0 ? void 0 : fiat2Acc.account, onClose: account2Popup.off, hideCurrency: hideCurrencyBank2, showOnlyCurrency: showOnlyCurrencyBank2, onSelect: function (id) {
                account2Popup.off();
                form.reset();
                handleRouteId(id, ExchangeType.FiatFiat);
            } })),
        React.createElement(OperationLayout, { main: React.createElement(Box, null,
                React.createElement(Typography, { type: "text32Bold", marginBottom: "9px", variant: "h2" }, "Exchange funds"),
                React.createElement(Box, { gap: "15px", display: "flex", alignItems: "center", marginBottom: "26px", flexWrap: "wrap" },
                    React.createElement(Button, { variant: type === ExchangeType.FiatFiat
                            ? "BorderedGray"
                            : "Bordered", size: "xs", minWidth: "130px", LinkComponent: Link, to: generateQueryPath(ROUTES.exchange, {
                            type: ExchangeType.FiatFiat,
                        }, type === ExchangeType.Fiat
                            ? {
                                from: from,
                                to: undefined,
                            }
                            : type === ExchangeType.Crypto
                                ? {
                                    from: to,
                                    to: undefined,
                                }
                                : {}) }, "Fiat to Fiat"),
                    React.createElement(Button, { variant: type === ExchangeType.Crypto
                            ? "BorderedGray"
                            : "Bordered", size: "xs", minWidth: "130px", LinkComponent: Link, to: generateQueryPath(ROUTES.exchange, {
                            type: ExchangeType.Crypto,
                        }, type === ExchangeType.Fiat
                            ? {
                                from: to,
                                to: from,
                            }
                            : type === ExchangeType.FiatFiat
                                ? {
                                    from: undefined,
                                    to: from,
                                }
                                : {}) }, "Crypto to USD"),
                    React.createElement(Button, { variant: type === ExchangeType.Fiat
                            ? "BorderedGray"
                            : "Bordered", size: "xs", minWidth: "130px", LinkComponent: Link, to: generateQueryPath(ROUTES.exchange, {
                            type: ExchangeType.Fiat,
                        }, type === ExchangeType.Crypto
                            ? {
                                from: to,
                                to: from,
                            }
                            : type === ExchangeType.FiatFiat
                                ? {
                                    from: from,
                                    to: undefined,
                                }
                                : {}) }, "USD to Crypto")),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                        params.type === ExchangeType.Crypto
                            ? cryptoField
                            : fiat1Field,
                        React.createElement(Button, { replace: true, variant: "Black", size: "xs", width: "50px", alignSelf: "center", LinkComponent: Link, tabIndex: -1, to: generateQueryPath(ROUTES.exchange, {
                                type: type === ExchangeType.FiatFiat
                                    ? ExchangeType.FiatFiat
                                    : type
                                        === ExchangeType.Crypto
                                        ? ExchangeType.Fiat
                                        : ExchangeType.Crypto,
                            }, {
                                from: to,
                                to: from,
                            }) },
                            React.createElement(BaseIcon, { icon: "Swap", size: 14 })),
                        params.type === ExchangeType.FiatFiat
                            ? fiat2Field
                            : params.type === ExchangeType.Crypto
                                ? fiat1Field
                                : cryptoField),
                    false && (React.createElement(Box, { display: "grid", gridAutoFlow: "column", gridAutoColumns: "minmax(0,1fr)", gap: "5px" },
                        React.createElement(HintBtn, { value: "0.0000105", label: "Min EUR" }),
                        React.createElement(HintBtn, { value: "All funds", disabled: !Number((_g = form.cryptoBalance) === null || _g === void 0 ? void 0 : _g.balance), onClick: function () {
                                runInAction(function () {
                                    var _a;
                                    form.cryptoAmount = (_a = form.cryptoBalance) === null || _a === void 0 ? void 0 : _a.balance;
                                });
                            } }))))), card: media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "50px" },
                React.createElement(Info, { data: info }),
                React.createElement(Box, { height: "1px", bgColor: "black-1000", width: "100%" }),
                React.createElement(SectionAmount, { amount: totalExchangeAmount, currency: fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency }),
                React.createElement(Button, { width: "100%", onClick: function () { return setOpenModal(true); }, disabled: isPendingPreExchange || isDisabledExchange, loading: isPendingPreExchange }, "Exchange"))), side: !media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", width: "100%" },
                React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                React.createElement(Info, { dark: false, data: __spreadArray(__spreadArray([], info, true), [
                        {
                            label: "Total + Fees",
                            value: totalExchangeAmount
                                ? "".concat(totalExchangeAmount, " ").concat(fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency)
                                : "–",
                        },
                    ], false) }),
                React.createElement(Button, { width: "100%", onClick: function () { return setOpenModal(true); }, disabled: isPendingPreExchange || isDisabledExchange, loading: isPendingPreExchange }, "Exchange"))) }),
        React.createElement(ExchangeModal, { onSuccess: onSuccess, onClose: onCloseModal, isOpen: isOpenModal, data: {
                fromAccount: from,
                toAccount: to,
                fromCurrency: fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency,
                toCurrency: getCurrLabel(true),
                fromAmount: params.type === ExchangeType.Crypto
                    ? form.cryptoAmount
                    : form.fiat1Amount,
                id: preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.id,
                feeAmount: preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.feeAmount,
                feeCurrency: preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.feeCurrency,
            }, rateInfo: {
                label: "".concat(getCurrLabel(true), " rate"),
                value: rate ? "".concat(rate, " ").concat(fromAcc === null || fromAcc === void 0 ? void 0 : fromAcc.currency) : "–",
            }, showError: preexchangeErr, toAmount: params.type === ExchangeType.FiatFiat
                ? form.fiat2Amount
                : params.type === ExchangeType.Fiat
                    ? form.cryptoAmount
                    : form.fiat1Amount })));
});
