import * as React from "react";
import { Typography } from "@/shared/components";
export var ResendCodeBtn = function (_a) {
    var count = _a.count, loading = _a.loading, onSubmit = _a.onSubmit;
    return count && count > 0
        ? (React.createElement(Typography, { type: "text12", color: "black-500", whiteSpace: "nowrap" },
            "Resend code in ",
            Math.floor(count / 60).toString()
                .padStart(2, "0"),
            ":",
            (count % 60).toString().padStart(2, "0")))
        : (React.createElement("button", { onClick: onSubmit, disabled: loading, type: "button" },
            React.createElement(Typography, { type: "text12", color: loading ? "black-500" : "deep-blue-500", whiteSpace: "nowrap" }, "Resend code")));
};
