export var onChange = function (callback) {
    return function (e) {
        callback(e.currentTarget.value);
    };
};
export var onCheckbox = function (callback) { return function (e) {
    callback(e.currentTarget.checked);
}; };
export var onSelect = function (callback) { return function (_, e) {
    callback(e ? e.key : null);
}; };
