import { styled } from "@mui/material/styles";
export var StyledAutocomplete = styled("div", {
    shouldForwardProp: function (prop) { return !prop.startsWith("$"); },
})(function (_a) {
    var $smallList = _a.$smallList, $maxHeightList = _a.$maxHeightList, $width = _a.$width;
    return ({
        width: $width || "100%",
        "& .MuiInputBase-root.MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
            right: 0,
        },
        ".MuiAutocomplete-endAdornment": {
            marginRight: "20px",
            padding: "0",
            top: "50%",
            transform: "translateY(-50%)",
            ".MuiButtonBase-root": {
                padding: "0",
                margin: "0",
                "&:hover": {
                    background: "transparent",
                },
            },
        },
        ".MuiAutocomplete-option": {
            padding: "8px",
            borderRadius: "8px",
        },
        ".MuiAutocomplete-listbox": {
            overflow: "unset",
            maxHeight: $smallList ? $maxHeightList || "30vh" : $maxHeightList || "40vh",
            "&::-webkit-scrollbar": {
                display: "none",
                position: "relative",
            },
        },
        ".MuiOutlinedInput-root .MuiAutocomplete-input.MuiInputBase-input": {
            padding: "0 0 0 20px",
        },
        ".MuiOutlinedInput-root.MuiInputBase-root": {
            padding: "0px",
        },
        ".Icon": {
            boxSizing: "content-box",
        },
        ".BaseInput-readOnly, .BaseInput-readOnly input": {
            cursor: "auto",
        },
    });
});
export var StyledContainer = styled("li")(function (_a) {
    var theme = _a.theme;
    return ({
        "&#ListOptionDefault": {
            width: "100%",
            display: "flex",
            gap: "12px",
            alignItems: "center",
            padding: "15px 20px",
            boxSizing: "border-box",
            transition: "background 0.2s ease",
        },
        "&#ListOptionDefault[aria-selected=\"true\"]": {
            backgroundColor: "transparent",
        },
        "&#ListOptionDefault[aria-disabled=\"true\"]": {
            opacity: "0.4",
            cursor: "not-allowed",
        },
        "&#ListOptionDefault:hover:not([aria-disabled=\"true\"])": {
            backgroundColor: theme.colors["black-50"],
            cursor: "pointer",
            ".option-text": {
                color: theme.colors["deep-blue-500"],
            },
        },
        "&#ListOptionDefault.Mui-focused": {
            backgroundColor: "transparent",
        },
        "&#ListOptionDefault.Mui-focusVisible": {
            ".option-text": {
                color: theme.colors["black-1200"],
            },
        },
    });
});
export var StyledContent = styled("div")(function () { return ({
    display: "flex",
    gap: "12px",
    alignItems: "center",
    width: "100%",
}); });
