import React from "react";
import { Box, Typography } from "@/shared/components";
export var TransactionInfoData = function (_a) {
    var data = _a.data;
    return (data.map(function (item, index) {
        var _a;
        return (React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 30px", borderColor: "black-50", borders: index === data.length - 1 ? undefined : ["bottom"], borderWidth: index === data.length - 1 ? undefined : "1px", borderStyle: index === data.length - 1 ? undefined : "solid" },
            React.createElement(Typography, { color: "black-400", type: "text14" }, item[0]),
            React.createElement(Typography, { wordBreak: "break-all" }, (_a = item[1]) !== null && _a !== void 0 ? _a : "—")));
    }));
};
