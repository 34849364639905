import * as React from "react";
import styles from "./index.module.scss";
import { BaseIcon } from "@/shared/components";
import { Typography } from "@/shared/components/Typography";
import { preventDefault } from "@/shared/utils/prevent";
export var AuthForm = function (_a) {
    var title = _a.title, desc = _a.desc, footer = _a.footer, content = _a.content, onBack = _a.onBack, onSubmit = _a.onSubmit;
    return (React.createElement("form", { className: styles.root, onSubmit: preventDefault(onSubmit) },
        onBack && (React.createElement(BaseIcon, { icon: "Back", onClick: onBack, "aria-label": "Back", size: 30, className: styles.back })),
        React.createElement("div", { className: styles.header },
            title && (React.createElement(Typography, { type: "text32Bold", className: styles.title }, title)),
            desc && (React.createElement(Typography, { className: styles.desc, color: "black-500" }, desc))),
        content && (React.createElement("div", { className: styles.content }, content)),
        footer && (React.createElement("div", { className: styles.footer }, footer))));
};
