var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { toast } from "react-toastify";
import { useGetVerificationInfo } from "@/api/registration";
import { tokenStorage } from "@/api/tokenStorage";
import { CreateRecipientStore } from "@/features/create-recipient/store/CreateRecipientStore";
import { Toast } from "@/shared/components/Alert";
import { RootInner } from "@/shared/components/Root/Inner";
import { useProvider, useStore } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { AppVersionStore } from "@/shared/stores/AppVersionStore";
import { AuthStore } from "@/shared/stores/AuthStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { BalanceTypeStore } from "@/shared/stores/BalanceTypeStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { EventsStore } from "@/shared/stores/EventsStore";
import { MenuStore } from "@/shared/stores/MenuStore";
import { MfaStore } from "@/shared/stores/MfaStore";
import { RecipientListStore } from "@/shared/stores/RecipientListStore";
import { UserCurrencyStore } from "@/shared/stores/UserCurrencyStore";
export var Root = observer(function () {
    var _a;
    var addressList = useStore(AddressListStore);
    var events = useStore(EventsStore);
    var twoFA = useStore(MfaStore);
    var auth = useStore(AuthStore, tokenStorage, twoFA);
    var menu = useStore(MenuStore);
    var createRecipient = useStore(CreateRecipientStore);
    var userCurrency = useStore(UserCurrencyStore);
    var currencyList = useStore(CurrencyListStore);
    var balance = useStore(BalanceTypeStore);
    var AuthProvider = useProvider(AuthStore);
    var MenuProvider = useProvider(MenuStore);
    var UserCurrencyProvider = useProvider(UserCurrencyStore);
    var CurrencyListProvider = useProvider(CurrencyListStore);
    var BalanceProvider = useProvider(BalanceTypeStore);
    var accountList = useStore(AccountListStore, auth, events);
    var AccountListProvider = useProvider(AccountListStore);
    var CreateRecipientProvider = useProvider(CreateRecipientStore);
    var recipientList = useStore(RecipientListStore, auth);
    var RecipientListProvider = useProvider(RecipientListStore);
    var TwoFAProvider = useProvider(MfaStore);
    var EventsProvider = useProvider(EventsStore);
    var BalanceListProvider = useProvider(BalanceListStore);
    var AddressListProvider = useProvider(AddressListStore);
    var balanceList = useStore(BalanceListStore, accountList);
    var verification = useGetVerificationInfo(auth.authorized);
    var appVersion = useStore(AppVersionStore);
    var AppVersionProvider = useProvider(AppVersionStore);
    var queryClient = useQueryClient();
    React.useEffect(function () { return function () {
        var _a;
        if (!((_a = auth.userInfo) === null || _a === void 0 ? void 0 : _a.primaryEmail))
            queryClient.clear();
    }; }, [(_a = auth.userInfo) === null || _a === void 0 ? void 0 : _a.primaryEmail, queryClient]);
    React.useEffect(function () {
        toggleLoader(auth.initialized
            && accountList.initialized
            && (auth.authorized ? verification.isFetched : auth.initialized));
    }, [
        auth.initialized,
        accountList.initialized,
        verification.isFetched,
        auth.authorized,
    ]);
    React.useEffect(function () {
        if (appVersion.updated) {
            toast(function (props) { return (React.createElement(Toast, __assign({ type: "default", text: "Our application has been updated. Please reload the page for the latest features." }, props))); }, {
                autoClose: false,
            });
        }
    }, [appVersion.updated]);
    return (React.createElement(AppVersionProvider, { value: appVersion },
        React.createElement(AddressListProvider, { value: addressList },
            React.createElement(BalanceListProvider, { value: balanceList },
                React.createElement(EventsProvider, { value: events },
                    React.createElement(TwoFAProvider, { value: twoFA },
                        React.createElement(CreateRecipientProvider, { value: createRecipient },
                            React.createElement(RecipientListProvider, { value: recipientList },
                                React.createElement(AccountListProvider, { value: accountList },
                                    React.createElement(BalanceProvider, { value: balance },
                                        React.createElement(CurrencyListProvider, { value: currencyList },
                                            React.createElement(UserCurrencyProvider, { value: userCurrency },
                                                React.createElement(MenuProvider, { value: menu },
                                                    React.createElement(AuthProvider, { value: auth },
                                                        React.createElement(RootInner, null)))))))))))))));
});
function toggleLoader(visible) {
    var _a;
    (_a = document.getElementById("loader")) === null || _a === void 0 ? void 0 : _a.classList.toggle("active", !visible);
}
