var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material";
import * as React from "react";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Typography } from "@/shared/components/Typography";
export var PopupOption = function (_a) {
    var selected = _a.selected, desc = _a.desc, title = _a.title, onClick = _a.onClick;
    return (React.createElement(Wrapper, { selected: selected, onClick: onClick },
        React.createElement(Box, { display: "flex", flexDirection: "column" },
            React.createElement(Typography, { type: "text18", fontWeight: 500 }, title),
            React.createElement(Typography, { type: "text14", color: "black-500" }, desc)),
        selected && (React.createElement(BaseIcon, { icon: "Check", size: 8, containerSize: 20, borderRadius: "20px", bgColor: "deep-blue-500", color: "general-white" }))));
};
var Wrapper = styled("button")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    gap: 10px;\n    align-items: center;\n    justify-content: space-between;\n    text-align: left;\n    border-radius: 20px;\n    padding: 18px 20px;\n    box-shadow: ", ";\n"], ["\n    display: flex;\n    gap: 10px;\n    align-items: center;\n    justify-content: space-between;\n    text-align: left;\n    border-radius: 20px;\n    padding: 18px 20px;\n    box-shadow: ", ";\n"])), function (props) {
    return props.selected
        ? "inset 0 0 0 2px ".concat(props.theme.colors["deep-blue-500"])
        : "inset 0 0 0 1px ".concat(props.theme.colors["black-100"]);
});
var templateObject_1;
