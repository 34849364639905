import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
export var UnderReviewPopup = observer(function (_a) {
    var redirectLink = _a.redirectLink;
    var navigate = useNavigate();
    return (React.createElement(Popup, { width: "450px" },
        React.createElement(Box, { bgColor: "general-yellow", borderRadius: "20px", padding: "50px", minHeight: "400px", maxHeight: "600px", height: "100vh", boxSizing: "border-box", display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "74px" },
            React.createElement(Box, { flexGrow: "4", display: "flex", flexDirection: "column", justifyContent: "center" },
                React.createElement(Typography, { type: "text28", fontWeight: 500, variant: "h2", textAlign: "center", margin: "0 0 10px" }, "Your request is under review"),
                React.createElement(Typography, { type: "text14", fontWeight: 500, textAlign: "center" }, "Our team will review the information you submitted within 24h. We will send you an email once it\u2019s done.")),
            React.createElement(Button, { variant: "Black", width: "100%", onClick: function () {
                    navigate(redirectLink || generatePath(ROUTES.root));
                } }, "Got it"))));
});
