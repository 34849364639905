var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { formattedTokenAmount } from "@broxus/js-utils";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ConfirmPopup } from "@/features/transfer/components/ConfirmPopup";
import { FailPopup } from "@/features/transfer/components/FailPopup";
import { SuccessPopup } from "@/features/transfer/components/SuccessPopup";
import { Header } from "@/pages/TransferPage/components/Header";
import { TransferAccountFormStore } from "@/pages/TransferPage/stores/TransferAccountFormStore";
import { ROUTES, TransferType } from "@/routes";
import { Box, Button } from "@/shared/components";
import { AccountSelect } from "@/shared/components/AccountSelect";
import { BankAccountPopup } from "@/shared/components/BankAccountPopup";
import { BankIcon } from "@/shared/components/BankIcon";
import { ExchangeField } from "@/shared/components/ExchangeField";
import { ExchangeInput } from "@/shared/components/ExchangeInput";
import { FormAlert } from "@/shared/components/FormAlert";
import { Info } from "@/shared/components/Info";
import { OperationLayout } from "@/shared/components/OperationLayout";
import { SectionAmount } from "@/shared/components/SectionAmount";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { sliceAddress } from "@/shared/utils/slice-address";
export var Account = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    var media = useMedia();
    var fromPopup = useToggler();
    var toPopup = useToggler();
    var navigate = useNavigate();
    var search = useSearchParams()[0];
    var from = search.get("from");
    var to = search.get("to");
    var accountList = useStoreContext(AccountListStore);
    var FormProvider = useProvider(TransferAccountFormStore);
    var balanceList = useStoreContext(BalanceListStore);
    var addressList = useStoreContext(AddressListStore);
    var form = useStore(TransferAccountFormStore, balanceList, accountList, addressList);
    var fromError = form.fromEnough === false ? "Enter a smaller amount" : undefined;
    React.useEffect(function () {
        runInAction(function () {
            form.from = from !== null && from !== void 0 ? from : undefined;
            form.to = to !== null && to !== void 0 ? to : undefined;
        });
    }, [from, to, form]);
    var info = [
        {
            label: "Amount",
            value: (form === null || form === void 0 ? void 0 : form.amount) && (form === null || form === void 0 ? void 0 : form.fromAcc)
                ? "".concat(formattedTokenAmount(form.amount), " ").concat(form.fromAcc.currency)
                : "–",
        },
        {
            label: "Transaction fee",
            value: form.fee ? formattedTokenAmount(form.fee) : "–",
        },
        {
            label: "Processing time",
            value: "Instantly",
        },
    ];
    return (React.createElement(FormProvider, { value: form },
        fromPopup.enabled && (React.createElement(BankAccountPopup, { selectedId: (_a = form.fromAcc) === null || _a === void 0 ? void 0 : _a.account, onClose: fromPopup.off, onSelect: function (id) {
                fromPopup.off();
                navigate(generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Account,
                }, {
                    from: id === to ? to : id,
                    to: id === to ? from : undefined,
                }), {
                    replace: true,
                });
            } })),
        toPopup.enabled && (React.createElement(BankAccountPopup, { showOnlyCurrency: (_b = form.fromAcc) === null || _b === void 0 ? void 0 : _b.currency, selectedId: (_c = form.toAcc) === null || _c === void 0 ? void 0 : _c.account, hideAccount: (_d = form.fromAcc) === null || _d === void 0 ? void 0 : _d.account, onClose: toPopup.off, onSelect: function (id) {
                toPopup.off();
                navigate(generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Account,
                }, {
                    from: id === from ? to : from,
                    to: id === from ? from : id,
                }), {
                    replace: true,
                });
            } })),
        React.createElement(OperationLayout, { main: React.createElement(Box, null,
                React.createElement(Header, { active: TransferType.Account }),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                    React.createElement(ExchangeField, { error: fromError, input: React.createElement(ExchangeInput, { disabled: form.loading, invalid: !!fromError, postFix: (_e = form.fromAcc) === null || _e === void 0 ? void 0 : _e.currency.toUpperCase(), value: form.amount, onChange: action(function (value) {
                                form.amount = value;
                            }) }), label: "You send", select: React.createElement(AccountSelect, { disabled: form.loading, name: ((_g = (_f = form.fromAccAddress) === null || _f === void 0 ? void 0 : _f.first) === null || _g === void 0 ? void 0 : _g.accountNumber)
                                ? sliceAddress(form.fromAccAddress.first
                                    .accountNumber)
                                : undefined, descLoading: !form.fromBalance, desc: form.fromBalance
                                ? "".concat(formattedTokenAmount(form.fromBalance.balance), " ").concat(form.fromBalance.currency.toUpperCase())
                                : undefined, onClick: fromPopup.on, icon: form.fromAcc
                                ? (React.createElement(BankIcon, { symbol: form.fromAcc.currency, size: 24 }))
                                : undefined }) }),
                    React.createElement(ExchangeField, { label: "You get", input: React.createElement(ExchangeInput, { disabled: true, postFix: (_h = form.toAcc) === null || _h === void 0 ? void 0 : _h.currency.toUpperCase(), value: form.amount }), select: React.createElement(AccountSelect, { disabled: form.loading, name: ((_k = (_j = form.toAccAddress) === null || _j === void 0 ? void 0 : _j.first) === null || _k === void 0 ? void 0 : _k.accountNumber)
                                ? sliceAddress(form.toAccAddress.first
                                    .accountNumber)
                                : undefined, descLoading: !form.toBalance, desc: form.toBalance
                                ? "".concat(formattedTokenAmount(form.toBalance.balance), " ").concat(form.toBalance.currency.toUpperCase())
                                : undefined, iconUri: form.toAcc
                                ? form.toAcc.currency
                                : undefined, onClick: toPopup.on, icon: form.toAcc
                                ? (React.createElement(BankIcon, { symbol: (_l = form.toAcc) === null || _l === void 0 ? void 0 : _l.currency, size: 24 }))
                                : undefined }) }),
                    form.errors.length > 0 && React.createElement(FormAlert, { text: form.errors[0].description }))), card: media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "50px" },
                React.createElement(Info, { data: info }),
                React.createElement(Box, { height: "1px", bgColor: "black-1000", width: "100%" }),
                React.createElement(SectionAmount, { amount: form.total, currency: (_m = form.fromAcc) === null || _m === void 0 ? void 0 : _m.currency }),
                React.createElement(Button, { width: "100%", disabled: !form.valid || form.loading, onClick: form.showConfirmation }, "Send"))), side: !media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", width: "100%" },
                React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                React.createElement(Info, { dark: false, data: __spreadArray(__spreadArray([], info, true), [
                        {
                            label: "Total + Fees",
                            value: form.total && form.fromAcc
                                ? "".concat(form.total, " ").concat((_o = form.fromAcc) === null || _o === void 0 ? void 0 : _o.currency, "\"")
                                : "\u200b",
                        },
                    ], false) }),
                React.createElement(Button, { width: "100%", disabled: !form.valid || form.loading, onClick: form.showConfirmation }, "Send"))) }),
        form.confirmation && (React.createElement(ConfirmPopup, { info: info, isLoading: form.loading, onClose: form.hideConfirmation, onSubmit: form.submit, amount: form.amount, currency: (_p = form.fromAcc) === null || _p === void 0 ? void 0 : _p.currency, to: (_r = (_q = form.toAccAddress) === null || _q === void 0 ? void 0 : _q.first) === null || _r === void 0 ? void 0 : _r.accountNumber })),
        form.result && (React.createElement(SuccessPopup, { minus: true, title: "You've successfully sent funds", address: (_t = (_s = form.toAccAddress) === null || _s === void 0 ? void 0 : _s.first) === null || _t === void 0 ? void 0 : _t.accountNumber, amount: form.total, createdAt: (_u = form.result) === null || _u === void 0 ? void 0 : _u.createdAt, currency: (_v = form.result) === null || _v === void 0 ? void 0 : _v.currency, onClose: form.reset })),
        !!form.errors.length && (React.createElement(FailPopup, { message: (_w = form.errors.at(0)) === null || _w === void 0 ? void 0 : _w.description, onClose: form.hideErrors, onSubmit: form.showConfirmation }))));
});
