var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { compose, flexbox, spacing } from "@mui/system";
import React, { memo } from "react";
import { Checkbox } from "../Checkbox";
import { Typography } from "../Typography";
import { StyledContainer } from "./styled";
export var ImplFormControlCheckbox = React.forwardRef(function (_a, ref) {
    var label = _a.label, className = _a.className, colorText = _a.colorText, text = _a.text, lineClamp = _a.lineClamp, disabled = _a.disabled, marginBottom = _a.marginBottom, props = __rest(_a, ["label", "className", "colorText", "text", "lineClamp", "disabled", "marginBottom"]);
    return (React.createElement(StyledContainer, { className: className, disabled: disabled },
        React.createElement(Checkbox, __assign({ inputRef: ref }, props, { disabled: disabled })),
        typeof label === "string"
            ? (React.createElement(Typography, { type: text || "text16", color: colorText || "black-1200", lineClamp: lineClamp }, label))
            : label));
});
export var FormControlCheckbox = memo(styled(ImplFormControlCheckbox)(compose(spacing, flexbox)));
