import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
import { useStore } from "@/shared/hooks/useStore";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
export var SuccessPopup = function (_a) {
    var onClose = _a.onClose;
    var searchParams = useSearchParams()[0];
    var subStore = useStore(SubscriptionStore);
    useEffect(function () {
        var ckoPay = searchParams.get("cko-payment-session-id");
        var ckoSess = searchParams.get("cko-session-id");
        var ckoPayId = searchParams.get("cko-payment-id");
        if (!ckoPay || !ckoSess || !ckoPayId)
            return;
        subStore.createSuccessRed(ckoPay, ckoSess, ckoPayId);
    }, [searchParams, subStore]);
    return (React.createElement(Popup, { onClose: onClose, bgColor: "general-green", width: "450px" },
        React.createElement(PopupBody, { padTop: true, minHeight: "350px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "50px", alignItems: "center" },
                React.createElement(Typography, { type: "text28Bold", textAlign: "center", color: "general-white" }, "Payment success")),
            React.createElement(Button, { mt: "auto", width: "100%", variant: "White", onClick: onClose }, "Success"))));
};
