var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Box } from "@/shared/components/Box";
import { Autocomplete } from "@/shared/components/Select";
import { ListOptionDefault } from "@/shared/components/Select/Option";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { Typography } from "@/shared/components/Typography";
export var TokenSearch = function (_a) {
    var _b;
    var _c = _a.tokens, tokens = _c === void 0 ? [] : _c, value = _a.value, tokenBtns = _a.tokenBtns, label = _a.label, disabled = _a.disabled, onChange = _a.onChange;
    var options = tokens.map(function (item) { return ({
        key: item.id,
        id: item.id,
        label: item.symbol.toUpperCase(),
        symbol: item.symbol,
        iconUrl: item.iconUrl,
        name: item.name,
    }); });
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
        React.createElement(Autocomplete
        // TODO: Add search icons
        // TODO: Add selected token icon
        , { 
            // TODO: Add search icons
            // TODO: Add selected token icon
            fullWidth: true, disabled: disabled, label: label !== null && label !== void 0 ? label : "Search coin", options: options, value: value ? (_b = options.find(function (item) { return item.id === value; })) !== null && _b !== void 0 ? _b : null : null, onChange: function (_, option) {
                onChange(option === null || option === void 0 ? void 0 : option.id);
            }, renderOption: function (p, option, state) { return (React.createElement(ListOptionDefault, __assign({}, state, p, { key: option.id }),
                React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" },
                    React.createElement(TokenIcon, { symbol: option.symbol, iconUrl: option.iconUrl }),
                    React.createElement(Typography, { type: "text16" }, option.symbol),
                    option.name && (React.createElement(Typography, { type: "text16", color: "black-500" }, option.name))))); } }),
        tokenBtns && tokenBtns.length > 0 && (React.createElement(Box, { display: "flex", gap: "10px" }, tokenBtns.map(function (item) { return (React.createElement(TokenBtn, { key: item.id, onClick: function () {
                onChange(item.id);
            } },
            React.createElement(TokenIcon, { symbol: item.symbol }),
            React.createElement(Typography, { type: "text14", fontWeight: 500 }, item.symbol))); })))));
};
var TokenBtn = styled("button")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: ", ";\n    padding: 10px;\n    border-radius: 12px;\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    cursor: pointer;\n"], ["\n    background: ", ";\n    padding: 10px;\n    border-radius: 12px;\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    cursor: pointer;\n"])), function (props) { return props.theme.colors["black-50"]; });
var templateObject_1;
