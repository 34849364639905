import { action } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Typography } from "@/shared/components";
import { useStore } from "@/shared/hooks/useStore";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
import { FailedPopup } from "@/widgets/FailedPopup";
export var FailedCardPage = observer(function () {
    var navigate = useNavigate();
    var searchParams = useSearchParams()[0];
    var subStore = useStore(SubscriptionStore);
    useEffect(function () {
        var ckoPay = searchParams.get("cko-payment-session-id");
        var ckoSess = searchParams.get("cko-session-id");
        var ckoPayId = searchParams.get("cko-payment-id");
        if (!ckoPay || !ckoSess || !ckoPayId)
            return;
        subStore.createFailedRed(ckoPay, ckoSess, ckoPayId);
    }, [searchParams, subStore]);
    return (React.createElement(Box, { display: "flex", gap: "40px", mt: "20px", flexDirection: "column" },
        React.createElement(Typography, { type: "text32Bold" }, "Payment status"),
        React.createElement(FailedPopup, { onClose: action(function () {
                navigate("/");
            }) })));
});
