var Reactions = /** @class */ (function () {
    function Reactions() {
        this.reactions = [];
    }
    Reactions.prototype.create = function () {
        var reactions = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            reactions[_i] = arguments[_i];
        }
        this.destroy();
        this.reactions = this.reactions.concat(reactions);
    };
    Reactions.prototype.destroy = function () {
        this.reactions.forEach(function (item) { return item(); });
        this.reactions = [];
    };
    return Reactions;
}());
export { Reactions };
