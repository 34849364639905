var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { observer } from "mobx-react-lite";
import React from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import { VerificationStep } from "@/pages/ProfilePage/components/VerificationStep";
import { EmailFormStep, EmailFormStore } from "@/pages/ProfilePage/stores/EmailFormStore";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { Toast } from "@/shared/components/Alert";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupContent } from "@/shared/components/Popup/Content";
import { PopupHead } from "@/shared/components/Popup/Head";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
import { RequiredFieldMessage, WrongFormatMessage } from "@/shared/utils/error";
import { EMAIL_REGEXP } from "@/shared/utils/is-email";
import { preventDefault } from "@/shared/utils/prevent";
export var SettingsEmail = observer(function () {
    var _a, _b, _c, _d;
    var auth = useStoreContext(AuthStore);
    var store = useStoreContext(EmailFormStore);
    var form = useForm();
    var email = useWatch({ control: form.control, name: "email" });
    var onSubmitEmail = form.handleSubmit(function (formData) {
        store.submitEmail(formData.email);
    });
    var onSubmitCode = function (code) {
        store.submitEmailCode(email, code).then(function (success) {
            if (success) {
                toast(function (props) { return React.createElement(Toast, __assign({ type: "success", text: "Your email is successfully changed" }, props)); });
            }
        });
    };
    var close = function () {
        store.reset();
        form.reset();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                React.createElement(Typography, { type: "text20" }, "Email"),
                React.createElement(Typography, { color: "black-500" }, (_b = (_a = auth.userInfo) === null || _a === void 0 ? void 0 : _a.primaryEmail) === null || _b === void 0 ? void 0 : _b.email)),
            React.createElement(Button, { size: "s", disabled: store.loading, onClick: store.open }, "Change")),
        store.visible && (React.createElement(Popup, { width: "450px", onClose: close },
            React.createElement(PopupHead, { onClose: close }),
            store.step === EmailFormStep.Code
                ? (React.createElement(VerificationStep, { count: store.timer.count, error: (_d = (_c = store.errors) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.description, loading: store.loading, onResendCode: onSubmitEmail, onSubmitCode: onSubmitCode, title: "Confirm your email", description: "Enter the code we've sent you at ".concat(email) }))
                : (React.createElement(PopupBody, { as: "form", onSubmit: preventDefault(onSubmitEmail) },
                    React.createElement(PopupContent, { gap: "30px" },
                        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" },
                            React.createElement(Typography, { type: "text28Bold" }, "Enter new email"),
                            React.createElement(Typography, { color: "black-500", textAlign: "center" }, "Enter the email related to your account")),
                        React.createElement(Controller, { control: form.control, disabled: store.loading, name: "email", rules: {
                                required: {
                                    value: true,
                                    message: RequiredFieldMessage.description,
                                },
                                pattern: {
                                    value: EMAIL_REGEXP,
                                    message: WrongFormatMessage.description,
                                },
                            }, render: function (_a) {
                                var _b, _c;
                                var field = _a.field, error = _a.fieldState.error;
                                return (React.createElement(FormControlInput, __assign({ error: (error === null || error === void 0 ? void 0 : error.message) || ((_c = (_b = store.errors) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.description), width: "100%", label: "Email", disabled: store.loading }, field)));
                            } })),
                    React.createElement(Button, { width: "100%", type: "submit", disabled: !email || store.loading }, "Continue")))))));
});
