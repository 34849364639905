import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { CreateWalletPopup } from "@/features/create-wallet";
import { WalletItem } from "@/pages/WalletsPage/components/Item";
import { ROUTES } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { AccountTable } from "@/shared/components/AccountTable";
import { EmptyTable } from "@/shared/components/EmptyTable";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
export var WalletsPage = observer(function () {
    var media = useMedia();
    var navigate = useNavigate();
    var popup = useToggler();
    var accounts = useStoreContext(AccountListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var addressList = useStoreContext(AddressListStore);
    var loaded = accounts.crypto.every(function (item) {
        var _a, _b;
        return (((_a = balanceList.data[item.account]) === null || _a === void 0 ? void 0 : _a.balance)
            && !!((_b = addressList.data[item.account]) === null || _b === void 0 ? void 0 : _b[item.currency]));
    });
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accounts.crypto.map(function (acc) { return acc.account; }));
    }, [balanceList, accounts.crypto]);
    React.useEffect(function () {
        addressList.sync.apply(addressList, accounts.crypto);
    }, [accounts.crypto, addressList]);
    return (React.createElement(React.Fragment, null,
        popup.enabled && (React.createElement(CreateWalletPopup, { onClose: popup.off, onSuccess: function (id) {
                navigate(generatePath(ROUTES.walletItem, { id: id }));
                accounts.sync();
            } })),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
            React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                React.createElement(Typography, { type: media.desktop ? "text32Bold" : "text24Bold" }, "Digital assets"),
                React.createElement(Button, { size: "s", variant: "Black", onClick: popup.on },
                    React.createElement(Box, { display: "flex", alignItems: "center", gap: "5px" },
                        React.createElement(BaseIcon, { size: 13, icon: "Plus", containerSize: 24 }),
                        media.desktop ? "Create new wallet" : "Create"))),
            accounts.crypto.length
                ? (React.createElement(AccountTable, { loaded: loaded }, accounts.crypto.map(function (account) {
                    var _a, _b, _c;
                    var address = (_a = addressList.data[account.account]) === null || _a === void 0 ? void 0 : _a[account.currency];
                    return (React.createElement(WalletItem, { address: (_b = address === null || address === void 0 ? void 0 : address.first) === null || _b === void 0 ? void 0 : _b.address, key: account.account, amount: (_c = balanceList.data[account.account]) === null || _c === void 0 ? void 0 : _c.balance, id: account.account, symbol: account.currency }));
                })))
                : React.createElement(EmptyTable, { message: "Invoicing in crypto never was so close to you" }))));
});
