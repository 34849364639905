// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WZ5fnilqRhIiTsv{display:flex;flex-direction:column;gap:5px}`, "",{"version":3,"sources":["webpack://./src/shared/components/PasswordHint/index.module.scss"],"names":[],"mappings":"AAAA,iBACI,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".hint {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hint": `WZ5fnilqRhIiTsv`
};
export default ___CSS_LOADER_EXPORT___;
