import { makeAutoObservable, runInAction } from "mobx";
export var BalanceType;
(function (BalanceType) {
    BalanceType["All"] = "all";
    BalanceType["Crypto"] = "crypto";
    BalanceType["Fiat"] = "fiat";
})(BalanceType || (BalanceType = {}));
var STORAGE_KEY = "balance_type";
var BalanceTypeStore = /** @class */ (function () {
    function BalanceTypeStore() {
        this.type = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    BalanceTypeStore.prototype.init = function () {
        window.addEventListener("storage", this.sync);
        this.sync();
    };
    BalanceTypeStore.prototype.dispose = function () {
        window.removeEventListener("storage", this.sync);
    };
    BalanceTypeStore.prototype.sync = function () {
        var _this = this;
        var type = BalanceType.All;
        try {
            var test = localStorage.getItem(STORAGE_KEY);
            if (test === BalanceType.Crypto) {
                type = BalanceType.Crypto;
            }
            else if (test === BalanceType.Fiat) {
                type = BalanceType.Fiat;
            }
        }
        catch (e) {
            console.error(e);
        }
        runInAction(function () {
            _this.type = type;
        });
    };
    BalanceTypeStore.prototype.setType = function (value) {
        try {
            this.type = value;
            localStorage.setItem(STORAGE_KEY, value);
        }
        catch (e) {
            console.error(e);
        }
    };
    return BalanceTypeStore;
}());
export { BalanceTypeStore };
