var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeAutoObservable, runInAction } from "mobx";
import { acquiringApi } from "@/api/acquiring";
import { InvoicePaymentType } from "@/api/acquiring/types";
import { registrationApi } from "@/api/registration";
import { InvoiceStatus, PaymentStatus, RecurrentInvoiceStatus } from "@/api/registration/types";
import { STAND_URL } from "@/config";
import { Reactions } from "@/shared/utils/reactions";
var SubscriptionStore = /** @class */ (function () {
    function SubscriptionStore() {
        this.loader = 0;
        this.ready = false;
        this.isPaid = false;
        this.subscription = undefined;
        this.reactions = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
    }
    SubscriptionStore.prototype.init = function () {
        this.sync();
    };
    SubscriptionStore.prototype.sync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var subscription, isPaid, subscriptions, current, result, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        runInAction(function () {
                            _this.loader += 1;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, registrationApi.subscription.getSubscriptions()];
                    case 2:
                        subscriptions = _a.sent();
                        current = subscriptions.data
                            .filter(function (item) { return item.status === RecurrentInvoiceStatus.INIT; })
                            .at(0);
                        if (!current && subscriptions.data.length > 0) {
                            current = subscriptions.data.at(0);
                        }
                        if (subscriptions.data.some(function (_) {
                            return [
                                RecurrentInvoiceStatus.TRIAL,
                                RecurrentInvoiceStatus.ACTIVE,
                            ].includes(_.status);
                        })) {
                            isPaid = true;
                        }
                        if (!(current === null || current === void 0 ? void 0 : current.id)) return [3 /*break*/, 4];
                        return [4 /*yield*/, registrationApi.subscription.getSubscriptionById(current.id)];
                    case 3:
                        result = _a.sent();
                        subscription = result.data;
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 6];
                    case 6:
                        runInAction(function () {
                            _this.loader -= 1;
                            _this.ready = true;
                            _this.subscription = subscription;
                            _this.isPaid = isPaid;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SubscriptionStore.prototype.select = function (detailsId) {
        return __awaiter(this, void 0, void 0, function () {
            var success, e_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        success = false;
                        runInAction(function () {
                            _this.loader += 1;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, registrationApi.subscription.startSubscription({
                                subscriptionDetailsId: detailsId,
                            })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.sync()];
                    case 3:
                        _a.sent();
                        success = true;
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 5];
                    case 5:
                        runInAction(function () {
                            _this.loader -= 1;
                        });
                        return [2 /*return*/, success];
                }
            });
        });
    };
    SubscriptionStore.prototype.createFailedRed = function (ckoPay, ckoSess, ckoPaySess) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var e_3;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        runInAction(function () {
                            _this.loader += 1;
                        });
                        return [4 /*yield*/, this.sync()];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 5, , 6]);
                        if (!((_a = this.invoice) === null || _a === void 0 ? void 0 : _a.id)) {
                            throw new Error("invoice.id must be defined");
                        }
                        return [4 /*yield*/, acquiringApi.payment.checkoutFailedRedirect({
                                "cko-payment-id": ckoPay,
                                "cko-session-id": ckoSess,
                                "cko-payment-session-id": ckoPaySess,
                            }, {
                                headers: {
                                    referer: STAND_URL,
                                },
                            })];
                    case 3:
                        _b.sent();
                        return [4 /*yield*/, this.sync()];
                    case 4:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        e_3 = _b.sent();
                        console.error(e_3);
                        return [3 /*break*/, 6];
                    case 6:
                        runInAction(function () {
                            _this.loader -= 1;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SubscriptionStore.prototype.createSuccessRed = function (ckoPay, ckoSess, ckoPaySess) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var e_4;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        runInAction(function () {
                            _this.loader += 1;
                        });
                        return [4 /*yield*/, this.sync()];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 5, , 6]);
                        if (!((_a = this.invoice) === null || _a === void 0 ? void 0 : _a.id)) {
                            throw new Error("invoice.id must be defined");
                        }
                        return [4 /*yield*/, acquiringApi.payment.checkoutSuccessRedirect({
                                "cko-payment-id": ckoPay,
                                "cko-session-id": ckoSess,
                                "cko-payment-session-id": ckoPaySess,
                            }, {
                                headers: {
                                    referer: STAND_URL,
                                },
                            })];
                    case 3:
                        _b.sent();
                        return [4 /*yield*/, this.sync()];
                    case 4:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        e_4 = _b.sent();
                        console.error(e_4);
                        return [3 /*break*/, 6];
                    case 6:
                        runInAction(function () {
                            _this.loader -= 1;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SubscriptionStore.prototype.createPayment = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var e_5;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        runInAction(function () {
                            _this.loader += 1;
                        });
                        return [4 /*yield*/, this.sync()];
                    case 1:
                        _b.sent();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 5, , 6]);
                        if (!((_a = this.invoice) === null || _a === void 0 ? void 0 : _a.id)) {
                            throw new Error("invoice.id must be defined");
                        }
                        return [4 /*yield*/, acquiringApi.invoice.paymentGetOrCreate({
                                type: InvoicePaymentType.CHECKOUT,
                                invoiceId: this.invoice.id,
                            })];
                    case 3:
                        _b.sent();
                        return [4 /*yield*/, this.sync()];
                    case 4:
                        _b.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        e_5 = _b.sent();
                        console.error(e_5);
                        return [3 /*break*/, 6];
                    case 6:
                        runInAction(function () {
                            _this.loader -= 1;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SubscriptionStore.prototype.applyPromo = function (discountCode) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var e_6;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        runInAction(function () {
                            _this.loader += 1;
                        });
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        if (!((_a = this.invoice) === null || _a === void 0 ? void 0 : _a.id)) {
                            throw new Error("invoice.id must be defined");
                        }
                        if (!((_b = this.payment) === null || _b === void 0 ? void 0 : _b.id)) {
                            throw new Error("payment.id must be defined");
                        }
                        return [4 /*yield*/, acquiringApi.invoice.updatePayment({
                                invoiceId: this.invoice.id,
                                type: InvoicePaymentType.CHECKOUT,
                                paymentId: this.payment.id,
                                discountCode: discountCode,
                            })];
                    case 2:
                        _c.sent();
                        return [4 /*yield*/, this.sync()];
                    case 3:
                        _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_6 = _c.sent();
                        console.error(e_6);
                        return [3 /*break*/, 5];
                    case 5:
                        runInAction(function () {
                            _this.loader -= 1;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(SubscriptionStore.prototype, "id", {
        get: function () {
            var _a;
            return (_a = this.subscription) === null || _a === void 0 ? void 0 : _a.id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SubscriptionStore.prototype, "invoice", {
        get: function () {
            var _a;
            return (_a = this.subscription) === null || _a === void 0 ? void 0 : _a.linkedInvoices.filter(function (item) {
                return item.status === InvoiceStatus.PENDING
                    || item.status === InvoiceStatus.INIT
                    || item.status === InvoiceStatus.PROCESSING;
            }).at(0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SubscriptionStore.prototype, "payment", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.invoice) === null || _a === void 0 ? void 0 : _a.payments) === null || _b === void 0 ? void 0 : _b.filter(function (item) {
                return (item.status === PaymentStatus.INIT
                    || item.status === PaymentStatus.PROCESSING)
                    && "session" in item;
            }).at(0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SubscriptionStore.prototype, "session", {
        get: function () {
            if (this.payment && "session" in this.payment) {
                if (this.payment.session && "body" in this.payment.session) {
                    return this.payment.session.body;
                }
            }
            return undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SubscriptionStore.prototype, "amount", {
        get: function () {
            var _a;
            return (_a = this.invoice) === null || _a === void 0 ? void 0 : _a.amount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SubscriptionStore.prototype, "oldAmount", {
        get: function () {
            var _a;
            return (_a = this.invoice) === null || _a === void 0 ? void 0 : _a.AmountBeforeDiscount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SubscriptionStore.prototype, "currency", {
        get: function () {
            var _a;
            return (_a = this.invoice) === null || _a === void 0 ? void 0 : _a.currency;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SubscriptionStore.prototype, "loading", {
        get: function () {
            return this.loader > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SubscriptionStore.prototype, "subscriptionPaid", {
        get: function () {
            return this.isPaid;
        },
        enumerable: false,
        configurable: true
    });
    return SubscriptionStore;
}());
export { SubscriptionStore };
