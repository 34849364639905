import { makeAutoObservable, runInAction } from "mobx";
export var UserCurrency;
(function (UserCurrency) {
    UserCurrency["Usd"] = "usd";
    UserCurrency["Eur"] = "eur";
})(UserCurrency || (UserCurrency = {}));
var STORAGE_KEY = "currency";
var UserCurrencyStore = /** @class */ (function () {
    function UserCurrencyStore() {
        this.selected = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    UserCurrencyStore.prototype.init = function () {
        window.addEventListener("storage", this.sync);
        this.sync();
    };
    UserCurrencyStore.prototype.dispose = function () {
        window.removeEventListener("storage", this.sync);
    };
    UserCurrencyStore.prototype.sync = function () {
        var _this = this;
        var selected = UserCurrency.Usd;
        try {
            var test = localStorage.getItem(STORAGE_KEY);
            if (test === UserCurrency.Eur) {
                selected = UserCurrency.Eur;
            }
        }
        catch (e) {
            console.error(e);
        }
        runInAction(function () {
            _this.selected = selected;
        });
    };
    UserCurrencyStore.prototype.setSelect = function (value) {
        try {
            this.selected = value;
            localStorage.setItem(STORAGE_KEY, value);
        }
        catch (e) {
            console.error(e);
        }
    };
    return UserCurrencyStore;
}());
export { UserCurrencyStore };
