var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeAutoObservable, runInAction, toJS } from "mobx";
import { registrationApi } from "@/api/registration";
import { CLIENT_ID } from "@/config";
import { TimerStore } from "@/shared/stores/TimerStore";
import { groupErrorsByKey, parseApiError, UnknownError } from "@/shared/utils/error";
import { isEmail } from "@/shared/utils/is-email";
export var SigninStep;
(function (SigninStep) {
    SigninStep[SigninStep["Email"] = 0] = "Email";
    SigninStep[SigninStep["Verification"] = 1] = "Verification";
})(SigninStep || (SigninStep = {}));
var SigninPageStore = /** @class */ (function () {
    function SigninPageStore(auth) {
        this.auth = auth;
        this.step = SigninStep.Email;
        this.email = "";
        this.password = "";
        this.errors = [];
        this.loading = false;
        this.mfaToken = undefined;
        this.code = "";
        this.timer = new TimerStore();
        this.authentication = undefined;
        this.challenge = undefined;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    SigninPageStore.prototype.submit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var errors, loading, mfaToken, step, authentication, challenge, resp, authenticationListResp, authenticationItem, challengeResp, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        errors = [];
                        loading = true;
                        step = SigninStep.Email;
                        runInAction(function () {
                            _this.loading = loading;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, , 8]);
                        return [4 /*yield*/, registrationApi.auth.getToken({
                                grant_type: "password",
                                password: this.password,
                                username: this.email,
                                client_id: CLIENT_ID,
                            })];
                    case 2:
                        resp = _a.sent();
                        if (!(resp.data.error === "mfa_required" && resp.data.mfa_token)) return [3 /*break*/, 5];
                        return [4 /*yield*/, registrationApi.mfa.getListOfTwoFactorAuthentications({
                                onlyActive: true,
                            }, {
                                headers: {
                                    Authorization: "Bearer ".concat(resp.data.mfa_token),
                                },
                            })];
                    case 3:
                        authenticationListResp = _a.sent();
                        authenticationItem = authenticationListResp.data.find(function (item) { return "oob_channel" in item && item.oob_channel === "EMAIL"; });
                        if (!authenticationItem) {
                            throw new Error("OOB auth type not founded");
                        }
                        return [4 /*yield*/, registrationApi.mfa.createChallenge({
                                authenticator_id: authenticationItem === null || authenticationItem === void 0 ? void 0 : authenticationItem.id,
                                challenge_type: authenticationItem.authenticator_type,
                                client_id: CLIENT_ID,
                                mfa_token: resp.data.mfa_token,
                            })];
                    case 4:
                        challengeResp = _a.sent();
                        mfaToken = resp.data.mfa_token;
                        authentication = authenticationItem;
                        challenge = challengeResp.data;
                        step = SigninStep.Verification;
                        loading = false;
                        this.timer.start();
                        return [3 /*break*/, 6];
                    case 5:
                        if (resp.data.access_token && resp.data.refresh_token) {
                            this.auth.login(resp.data.access_token, resp.data.refresh_token);
                        }
                        _a.label = 6;
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        e_1 = _a.sent();
                        console.error(e_1);
                        errors = parseApiError(e_1);
                        loading = false;
                        return [3 /*break*/, 8];
                    case 8:
                        runInAction(function () {
                            _this.mfaToken = mfaToken;
                            _this.authentication = authentication;
                            _this.challenge = challenge;
                            _this.loading = loading;
                            _this.errors = errors;
                            _this.mfaToken = mfaToken;
                            _this.step = step;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SigninPageStore.prototype.submitCode = function () {
        return __awaiter(this, void 0, void 0, function () {
            var errors, loading, resp, e_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        errors = [];
                        loading = true;
                        runInAction(function () {
                            _this.loading = loading;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        if (!this.mfaToken) {
                            throw new Error("mfaToken must be defined");
                        }
                        if (!this.challenge) {
                            throw new Error("challenge must be defined");
                        }
                        return [4 /*yield*/, registrationApi.auth.getToken({
                                grant_type: "mfa-oob", // TODO: Other mfa types
                                binding_code: this.code,
                                client_id: CLIENT_ID,
                                mfaToken: this.mfaToken,
                                oobCode: this.challenge.oob_code,
                            })];
                    case 2:
                        resp = _a.sent();
                        if (resp.data.access_token && resp.data.refresh_token) {
                            this.auth.login(resp.data.access_token, resp.data.refresh_token);
                        }
                        else {
                            throw new Error(UnknownError.description);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        console.error(e_2);
                        errors = parseApiError(e_2);
                        loading = false;
                        return [3 /*break*/, 4];
                    case 4:
                        runInAction(function () {
                            _this.errors = errors;
                            _this.loading = loading;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SigninPageStore.prototype.reset = function () {
        this.step = SigninStep.Email;
        this.password = "";
        this.errors = [];
        this.authentication = undefined;
        this.challenge = undefined;
        this.mfaToken = undefined;
        this.code = "";
    };
    Object.defineProperty(SigninPageStore.prototype, "isEmailValid", {
        get: function () {
            return isEmail(this.email);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SigninPageStore.prototype, "isPasswordValid", {
        get: function () {
            return !!this.password;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SigninPageStore.prototype, "errorsByKey", {
        get: function () {
            return groupErrorsByKey(toJS(this.errors));
        },
        enumerable: false,
        configurable: true
    });
    return SigninPageStore;
}());
export { SigninPageStore };
