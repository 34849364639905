var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { formattedTokenAmount } from "@broxus/js-utils";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CounterpartyType } from "@/api/wallet/types";
import { ConfirmPopup } from "@/features/transfer/components/ConfirmPopup";
import { FailPopup } from "@/features/transfer/components/FailPopup";
import { SuccessPopup } from "@/features/transfer/components/SuccessPopup";
import { Header } from "@/pages/TransferPage/components/Header";
import { TransferCryptoFormStore } from "@/pages/TransferPage/stores/TransferCryptoFormStore";
import { ROUTES, TransferType } from "@/routes";
import { Box, Button, FormControlInput } from "@/shared/components";
import { AccountSelect } from "@/shared/components/AccountSelect";
import { ExchangeField } from "@/shared/components/ExchangeField";
import { ExchangeInput } from "@/shared/components/ExchangeInput";
import { FormAlert } from "@/shared/components/FormAlert";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { Info } from "@/shared/components/Info";
import { OperationLayout } from "@/shared/components/OperationLayout";
import { RecipientPopup } from "@/shared/components/RecipientPopup";
import { SectionAmount } from "@/shared/components/SectionAmount";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { TokenPopup } from "@/shared/components/TokenPopup";
import { UserPick } from "@/shared/components/UserPick";
import { PaymentPurpose } from "@/shared/constants/dict";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { RecipientListStore } from "@/shared/stores/RecipientListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { sliceAddress } from "@/shared/utils/slice-address";
export var Crypto = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var media = useMedia();
    var tokenPopup = useToggler();
    var navigate = useNavigate();
    var search = useSearchParams()[0];
    var from = search.get("from");
    var to = search.get("to");
    var accountList = useStoreContext(AccountListStore);
    var FormProvider = useProvider(TransferCryptoFormStore);
    var balanceList = useStoreContext(BalanceListStore);
    var recipientList = useStoreContext(RecipientListStore);
    var form = useStore(TransferCryptoFormStore, balanceList, accountList, recipientList);
    var recipientPopup = useToggler();
    var error = form.enough === false ? "Enter a smaller amount" : undefined;
    React.useEffect(function () {
        runInAction(function () {
            form.from = from !== null && from !== void 0 ? from : undefined;
            form.to = to !== null && to !== void 0 ? to : undefined;
        });
    }, [from, form, to]);
    var info = [
        {
            label: "Amount",
            value: ((_a = form.feeData.value) === null || _a === void 0 ? void 0 : _a.data.amount) && form.fromAcc
                ? "".concat(formattedTokenAmount(form.feeData.value.data.amount), " ").concat(form.fromAcc.currency)
                : "–",
        },
        {
            label: "Transaction fee",
            value: form.fee ? formattedTokenAmount(form.fee) : "–",
        },
        {
            label: "Processing time",
            value: "Instantly",
        },
    ];
    return (React.createElement(FormProvider, { value: form },
        recipientPopup.enabled && (React.createElement(RecipientPopup, { type: CounterpartyType.CRYPTO, currency: (_b = form.fromAcc) === null || _b === void 0 ? void 0 : _b.currency, selectedId: form.to, onClose: recipientPopup.off, onSelect: function (id) {
                recipientPopup.off();
                navigate(generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Crypto,
                }, {
                    from: from,
                    to: id,
                }), {
                    replace: true,
                });
            } })),
        tokenPopup.enabled && (React.createElement(TokenPopup, { selectedId: from, onClose: tokenPopup.off, onSelect: function (id) {
                tokenPopup.off();
                navigate(generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Crypto,
                }, {
                    from: id,
                }), {
                    replace: true,
                });
            } })),
        React.createElement(OperationLayout, { main: React.createElement(Box, null,
                React.createElement(Header, { active: TransferType.Crypto }),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                    React.createElement(ExchangeField, { error: error, input: React.createElement(ExchangeInput, { disabled: form.loading, invalid: !!error, postFix: (_c = form.fromAcc) === null || _c === void 0 ? void 0 : _c.currency.toUpperCase(), value: form.amount, onChange: action(function (value) {
                                form.amount = value;
                            }) }), label: "You send", select: React.createElement(AccountSelect, { disabled: form.loading, icon: form.fromAcc
                                ? (React.createElement(TokenIcon, { symbol: form.fromAcc.currency, size: 24 }))
                                : undefined, name: form.fromAcc
                                ? form.fromAcc.currency.toUpperCase()
                                : undefined, onClick: tokenPopup.on, descLoading: !form.balance, desc: form.balance
                                ? "".concat(formattedTokenAmount(form.balance.balance), " ").concat(form.balance.currency.toUpperCase())
                                : undefined }) }),
                    React.createElement(ExchangeField, { value: ((_d = form.recipient) === null || _d === void 0 ? void 0 : _d.blockchainAddress)
                            ? sliceAddress(form.recipient.blockchainAddress)
                            : "Crypto account", label: "To", select: React.createElement(AccountSelect, { icon: form.recipient
                                ? (React.createElement(UserPick, { name: form.recipient.data.profile.name, size: 24 }))
                                : undefined, name: (_e = form.recipient) === null || _e === void 0 ? void 0 : _e.data.profile.name, onClick: recipientPopup.on }) }),
                    React.createElement(FormControlSelect, { label: "Purpose of payment", options: PaymentPurpose, value: form.paymentPurpose, onChange: action(function (_, v) {
                            form.paymentPurpose = v === null || v === void 0 ? void 0 : v.key;
                        }) }),
                    React.createElement(FormControlInput, { placeholder: "Memo", value: form.memo, onChange: action(function (e) {
                            form.memo = e.currentTarget.value;
                        }) }),
                    form.errors.at(0) && React.createElement(FormAlert, { text: form.errors.at(0).description }))), card: media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "50px" },
                React.createElement(Info, { data: info }),
                React.createElement(Box, { height: "1px", bgColor: "black-1000", width: "100%" }),
                React.createElement(SectionAmount, { amount: form.total, currency: (_f = form.fromAcc) === null || _f === void 0 ? void 0 : _f.currency }),
                React.createElement(Button, { width: "100%", disabled: !form.ready, onClick: form.showConfirmation, loading: form.feeData.loading || form.loading }, "Send"))), side: !media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", width: "100%" },
                React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                React.createElement(Info, { dark: false, data: __spreadArray(__spreadArray([], info, true), [
                        {
                            label: "Total + Fees",
                            value: form.fromAcc
                                ? "".concat((_g = form.total) !== null && _g !== void 0 ? _g : "0.00", " ").concat(form.fromAcc.currency)
                                : "\u200b",
                        },
                    ], false) }),
                React.createElement(Button, { width: "100%", disabled: !form.ready, onClick: form.showConfirmation, loading: form.feeData.loading || form.loading }, "Send"))) }),
        form.confirmation && (React.createElement(ConfirmPopup, { info: info, isLoading: form.loading, onClose: form.hideConfirmation, onSubmit: form.submit, amount: form.amount, currency: (_h = form.fromAcc) === null || _h === void 0 ? void 0 : _h.currency, to: ((_j = form.recipient) === null || _j === void 0 ? void 0 : _j.blockchainAddress)
                ? sliceAddress(form.recipient.blockchainAddress)
                : undefined })),
        form.result && (React.createElement(SuccessPopup, { minus: true, title: "You've successfully sent the crypto", address: ((_k = form.recipient) === null || _k === void 0 ? void 0 : _k.blockchainAddress)
                ? sliceAddress(form.recipient.blockchainAddress)
                : undefined, amount: form.total, createdAt: (_l = form.result) === null || _l === void 0 ? void 0 : _l.createdAt, currency: (_m = form.result) === null || _m === void 0 ? void 0 : _m.currency, onClose: form.reset })),
        !!form.submitErrors.length && (React.createElement(FailPopup, { message: (_o = form.submitErrors.at(0)) === null || _o === void 0 ? void 0 : _o.description, onClose: form.hideErrors, onSubmit: form.showConfirmation }))));
});
