var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material";
import * as React from "react";
import { Button } from "@/shared/components";
export var BackBtn = function (_a) {
    var onClick = _a.onClick;
    return (React.createElement(Wrapper, { variant: "Gray", width: "auto", icon: "Cross", iconSize: 12, iconPosition: "right", onClick: onClick }, "Continue later"));
};
var Wrapper = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: absolute;\n    top: 0;\n    right: 0;\n    font-weight: 500;\n    display: flex;\n    padding-left: 25px;\n    padding-right: 25px;\n"], ["\n    position: absolute;\n    top: 0;\n    right: 0;\n    font-weight: 500;\n    display: flex;\n    padding-left: 25px;\n    padding-right: 25px;\n"])));
var templateObject_1;
