import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import React from "react";
import { SwiperSlide } from "swiper/react";
import { useIntersectionObserver } from "usehooks-ts";
import { Account } from "./Item";
import { CreateAccountPopup } from "@/features/create-account/components/CreateAccountPopup";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Carousel } from "@/shared/components/Carousel";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
export var Accounts = observer(function () {
    var popup = useToggler();
    var _a = useIntersectionObserver({
        threshold: 1,
    }), lastItemIsIntersecting = _a.isIntersecting, lastItem = _a.ref;
    var _b = useIntersectionObserver({
        threshold: 1,
    }), firstItemIsIntersecting = _b.isIntersecting, firstItem = _b.ref;
    var addressList = useStoreContext(AddressListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var accounts = useStoreContext(AccountListStore);
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accounts.fiat.map(function (acc) { return acc.account; }));
    }, [balanceList, accounts.fiat]);
    React.useEffect(function () {
        addressList.sync.apply(addressList, accounts.fiat);
    }, [addressList, accounts.fiat]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledBox, { "$isRightHidden": !lastItemIsIntersecting && !!accounts.fiat.length, "$isLeftHidden": !firstItemIsIntersecting && !!accounts.fiat.length },
            React.createElement(Carousel, { navigation: { nextEl: ".nav-accounts-next", prevEl: ".nav-accounts-prev" }, spaceBetween: 10, slidesPerView: "auto" },
                accounts.fiat.map(function (account, index) {
                    var _a, _b, _c, _d, _e;
                    var address = (_a = addressList.data[account.account]) === null || _a === void 0 ? void 0 : _a[account.currency];
                    return (React.createElement(SwiperSlide, { key: account.account },
                        React.createElement(Account, { isEmpty: (_b = address === null || address === void 0 ? void 0 : address.first) === null || _b === void 0 ? void 0 : _b.isEmpty, ref: index === 0 ? firstItem : undefined, name: account.shortName, id: account.account, currency: account.currency, amount: (_c = balanceList.data[account.account]) === null || _c === void 0 ? void 0 : _c.balance, loading: !((_d = balanceList.data[account.account]) === null || _d === void 0 ? void 0 : _d.balance), accNum: (_e = address === null || address === void 0 ? void 0 : address.first) === null || _e === void 0 ? void 0 : _e.accountNumber })));
                }),
                React.createElement(SwiperSlide, null,
                    React.createElement(Box, { padding: "30px 20px", height: "250px", boxSizing: "border-box", bgColor: "general-white", borderRadius: "22px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "20px", width: "280px", ref: lastItem, tabIndex: 0, role: "button", onClick: function () { return popup.on(); } },
                        React.createElement(BaseIcon, { icon: "Plus", size: 42, padding: "14px", borderRadius: "100%", bgColor: "light-blue", color: "deep-blue-500" }),
                        React.createElement(Typography, { color: "deep-blue-500" }, "Open new bank account"))))),
        popup.enabled && (React.createElement(CreateAccountPopup, { onClose: popup.off, onSuccess: function () {
                accounts.sync();
                popup.off();
            } }))));
});
var StyledBox = styled("div")(function (_a) {
    var $isRightHidden = _a.$isRightHidden, $isLeftHidden = _a.$isLeftHidden;
    return ({
        position: "relative",
        "& .swiper-slide": {
            width: "280px",
        },
        "&:after": {
            transition: "all 0.3s ease",
            opacity: $isRightHidden ? 1 : 0,
            content: "\"\"",
            width: "100px",
            height: "300px",
            position: "absolute",
            right: "0",
            top: "50%",
            zIndex: 1,
            transform: "translateY(-50%)",
            background: "linear-gradient(270deg, #F6F7F8 0%, rgba(246, 247, 248, 0) 100%)",
            pointerEvents: "none",
        },
        "&:before": {
            transition: "all 0.3s ease",
            content: "\"\"",
            opacity: $isLeftHidden ? 1 : 0,
            width: "100px",
            height: "300px",
            position: "absolute",
            left: "0",
            top: "50%",
            zIndex: 2,
            transform: "translateY(-50%)",
            background: "linear-gradient(90deg, #F6F7F8 0%, rgba(246, 247, 248, 0) 100%)",
            pointerEvents: "none",
        },
    });
});
