import { RegistrationApi } from "@/api/registration/types";
import { tokenStorage } from "@/api/tokenStorage";
import { REGISTRATION_API_BASE_URL } from "@/config";
var regApi = new RegistrationApi({
    baseURL: REGISTRATION_API_BASE_URL,
});
var queue = [];
var isRefreshing = false;
var applyAuthReqInterceptor = function (instance) {
    instance.interceptors.request.use(function (config) {
        var _a;
        var accessToken = tokenStorage.getAccessToken();
        if (accessToken && !config.headers.Authorization) {
            // eslint-disable-next-line no-param-reassign
            config.headers = (_a = config.headers) !== null && _a !== void 0 ? _a : {};
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = "Bearer ".concat(accessToken);
        }
        return config;
    });
};
var applyAuthRespInterceptor = function (instance) {
    var respInterceptorId = instance.interceptors.response.use(function (resp) { return resp; }, function (err) {
        if (err.response
            && (err.response.status === 401 || err.response.status === 403)
            && err.config.url !== "/auth/token") {
            if (!isRefreshing) {
                isRefreshing = true;
                regApi.auth.getToken({
                    grant_type: "refresh_token",
                    refresh_token: tokenStorage.getRefreshToken(),
                }).then(function (resp) {
                    if (resp.data.access_token && resp.data.refresh_token) {
                        tokenStorage.setTokens(resp.data.access_token, resp.data.refresh_token);
                    }
                    else {
                        throw new Error("Tokens not founded");
                    }
                }).catch(function (err) {
                    tokenStorage.clean();
                    throw err;
                }).finally(function () {
                    isRefreshing = false;
                    instance.interceptors.response.eject(respInterceptorId);
                    queue.forEach(function (item) {
                        var _a;
                        var accessToken = tokenStorage.getAccessToken();
                        if (accessToken) {
                            item.config.headers = (_a = item.config.headers) !== null && _a !== void 0 ? _a : {};
                            item.config.headers.Authorization = "Bearer ".concat(accessToken);
                        }
                        instance
                            .request(item.config)
                            .then(function (response) { return item.resolve(response); })
                            .catch(function (err) { return item.reject(err); });
                    });
                    queue.length = 0;
                    applyAuthRespInterceptor(instance);
                });
            }
            return new Promise(function (resolve, reject) {
                queue.push({ config: err.config, resolve: resolve, reject: reject });
            });
        }
        return Promise.reject(err);
    });
};
export var applyAuthInterceptor = function (instance) {
    applyAuthReqInterceptor(instance);
    applyAuthRespInterceptor(instance);
};
