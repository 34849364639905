import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { Header } from "@/pages/DepositPage/components/Header";
import { DepositType, ROUTES } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { DropdownInput } from "@/shared/components/DropdownInput";
import { OperationLayout } from "@/shared/components/OperationLayout";
import { QRCode } from "@/shared/components/QRCode";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { TokenPopup } from "@/shared/components/TokenPopup";
import { useCopy } from "@/shared/hooks/useCopy";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { sliceAddress } from "@/shared/utils/slice-address";
export var DepositCrypto = observer(function () {
    var _a, _b, _c, _d, _e, _f;
    var media = useMedia();
    var params = useParams();
    var popup = useToggler();
    var navigate = useNavigate();
    var accountList = useStoreContext(AccountListStore);
    var addressList = useStoreContext(AddressListStore);
    var account = params.id ? accountList.cryptoById[params.id] : undefined;
    var address = account ? (_a = addressList.data[account.account]) === null || _a === void 0 ? void 0 : _a[account.currency] : undefined;
    var copy = useCopy((_c = (_b = address === null || address === void 0 ? void 0 : address.first) === null || _b === void 0 ? void 0 : _b.address) !== null && _c !== void 0 ? _c : "");
    var loading = params.id ? !account || !address : false;
    React.useEffect(function () {
        addressList.sync(account);
    }, [account, addressList]);
    return (React.createElement(React.Fragment, null,
        popup.enabled && (React.createElement(TokenPopup, { selectedId: account === null || account === void 0 ? void 0 : account.account, onClose: popup.off, onSelect: function (id) {
                popup.off();
                navigate(generatePath(ROUTES.deposit, {
                    id: id,
                    type: DepositType.Crypto,
                }), {
                    replace: true,
                });
            } })),
        React.createElement(OperationLayout, { main: React.createElement(Box, null,
                React.createElement(Header, { active: DepositType.Crypto }),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px" },
                    React.createElement(DropdownInput, { loading: loading, onClick: popup.on }, account
                        ? (React.createElement(React.Fragment, null,
                            React.createElement(TokenIcon, { symbol: account === null || account === void 0 ? void 0 : account.currency, size: 36 }),
                            React.createElement(Typography, { type: "text16" }, account.currency),
                            React.createElement(Typography, { type: "text16", color: "black-500" }, account.blockchain),
                            ((_d = address === null || address === void 0 ? void 0 : address.first) === null || _d === void 0 ? void 0 : _d.address) && (React.createElement(Typography, { type: "text16", color: "black-500" }, sliceAddress(address.first.address)))))
                        : null))), loading: loading, card: ((_e = address === null || address === void 0 ? void 0 : address.first) === null || _e === void 0 ? void 0 : _e.address) && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "40px" },
                React.createElement(QRCode, { dark: !media.desktop, value: address.first.address }),
                React.createElement(Box, { display: "flex", gap: "30px", width: "100%", justifyContent: "space-between" },
                    React.createElement(Box, { display: "flex", flexDirection: "column" },
                        React.createElement(Typography, { type: "text14", color: "black-400" }, "Wallet address"),
                        React.createElement(Typography, { type: "text16", color: media.desktop ? "general-white" : undefined, wordBreak: "break-word" }, address.first.address)),
                    React.createElement(Button, { variant: "LightBlue", size: "s", onClick: copy }, "Copy")),
                account && (React.createElement(Typography, { type: "text14", color: media.desktop ? "general-white" : "black-1200", bgColor: media.desktop ? "black-1000" : undefined, borderRadius: "12px", padding: media.desktop ? "20px" : undefined, boxSizing: "border-box", width: "100%", display: "flex", alignItems: "center", gap: "10px" },
                    React.createElement(BaseIcon, { icon: "Info", color: "general-orange", size: 14 }),
                    "Only send ",
                    account.currency.toUpperCase(),
                    " ", "(".concat((_f = account === null || account === void 0 ? void 0 : account.blockchain) === null || _f === void 0 ? void 0 : _f.toUpperCase(), ")"),
                    " ",
                    "to this address")))) })));
});
