// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UH4CayYxsdY2u0o{position:relative;padding:80px 60px;display:flex;flex-direction:column}@media(max-width: 1023px){.UH4CayYxsdY2u0o{padding:20px}}@media(min-width: 1024px){.UH4CayYxsdY2u0o .EzVIoRxjoMi9Sz8{position:absolute;left:20px;top:86px}}@media(max-width: 1023px){.UH4CayYxsdY2u0o .EzVIoRxjoMi9Sz8{margin:0 0 20px}}.eqINbbQVKJdlYfz{display:flex;flex-direction:column;gap:10px}.poG8abCF7RU7coW{margin:0 0 10px}.tgFYruBKqSXmGuc{margin:60px 0 0;display:flex;flex-direction:column;gap:20px}.uapaBsF2JpIBPBP{margin:60px 0 0;display:flex;flex-direction:column;gap:20px;align-items:flex-start}`, "",{"version":3,"sources":["webpack://./src/shared/components/AuthForm/index.module.scss"],"names":[],"mappings":"AAAA,iBACI,iBAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CAEA,0BANJ,iBAOQ,YAAA,CAAA,CAIA,0BADJ,kCAEQ,iBAAA,CACA,SAAA,CACA,QAAA,CAAA,CAGJ,0BAPJ,kCAQQ,eAAA,CAAA,CAKZ,iBACI,YAAA,CACA,qBAAA,CACA,QAAA,CAGJ,iBACI,eAAA,CAGJ,iBACI,eAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAGJ,iBACI,eAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,sBAAA","sourcesContent":[".root {\n    position: relative;\n    padding: 80px 60px;\n    display: flex;\n    flex-direction: column;\n\n    @media (max-width: 1023px) {\n        padding: 20px;\n    }\n\n    .back {\n        @media (min-width: 1024px) {\n            position: absolute;\n            left: 20px;\n            top: 86px;\n        }\n\n        @media (max-width: 1023px) {\n            margin: 0 0 20px;\n        }\n    }\n}\n\n.header {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n.title {\n    margin: 0 0 10px;\n}\n\n.content {\n    margin: 60px 0 0;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.footer {\n    margin: 60px 0 0;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `UH4CayYxsdY2u0o`,
	"back": `EzVIoRxjoMi9Sz8`,
	"header": `eqINbbQVKJdlYfz`,
	"title": `poG8abCF7RU7coW`,
	"content": `tgFYruBKqSXmGuc`,
	"footer": `uapaBsF2JpIBPBP`
};
export default ___CSS_LOADER_EXPORT___;
