// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZHSlaaKKCbP6Xom{display:flex;align-items:center;justify-content:center;width:100%;min-height:100vh;overflow-x:hidden;z-index:1000;position:relative}`, "",{"version":3,"sources":["webpack://./src/shared/components/Overlay/index.module.scss"],"names":[],"mappings":"AAAA,iBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,gBAAA,CACA,iBAAA,CACA,YAAA,CACA,iBAAA","sourcesContent":[".overlay {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    min-height: 100vh;\n    overflow-x: hidden;\n    z-index: 1000;\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `ZHSlaaKKCbP6Xom`
};
export default ___CSS_LOADER_EXPORT___;
