import { formattedTokenAmount } from "@broxus/js-utils";
import * as React from "react";
import { generatePath } from "react-router-dom";
import { ROUTES } from "@/routes";
import { Box, Typography } from "@/shared/components";
import { AccountItem } from "@/shared/components/AccountItem";
import { Amount } from "@/shared/components/Amount";
import { Loader } from "@/shared/components/Loader";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { sliceAddress } from "@/shared/utils/slice-address";
export var WalletItem = function (_a) {
    var amount = _a.amount, id = _a.id, symbol = _a.symbol, address = _a.address;
    return (React.createElement(AccountItem, { to: generatePath(ROUTES.walletItem, {
            id: id,
        }) },
        React.createElement(TokenIcon, { symbol: symbol, size: 45 }),
        React.createElement(Box, { display: "flex", flexDirection: "column", flexGrow: "1" },
            amount
                ? (React.createElement(Typography, { type: "text20" },
                    formattedTokenAmount(amount),
                    " ",
                    React.createElement(Typography, { type: "text20", color: "black-400" }, symbol)))
                : React.createElement(Loader, { size: 16, containerSize: 26 }),
            React.createElement(Typography, { type: "text14", color: "black-400" }, amount ? React.createElement(Amount, { from: symbol, amount: amount }) : "\u200B"),
            React.createElement(Typography, { type: "text14", color: "black-400" }, address ? sliceAddress(address) : "\u200B"))));
};
