import React from "react";
import { TransactionAmount } from "../TransactionAmount";
import { getOperationType } from "@/api/wallet/constants";
import { useGetBalanceLogReceipt } from "@/api/wallet/useGetBalanceLogReceipt";
import { Box, Typography } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
import { DATE_FORMAT, formatDate } from "@/shared/lib";
import { TransactionInfoData } from "@/widgets/Transactions/components/TransactionInfo/Data";
export var WithdrawInfo = function (_a) {
    var _b, _c, _d, _e;
    var transaction = _a.transaction;
    var _f = useGetBalanceLogReceipt((transaction === null || transaction === void 0 ? void 0 : transaction.id) || ""), data = _f.data, isLoading = _f.isLoading;
    if (!transaction)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", padding: "30px", bgColor: "black-50", borderRadius: "22px 22px 0 0" },
            React.createElement(Typography, { color: "black-400", type: "text14", mb: "42px" }, (data === null || data === void 0 ? void 0 : data.createdAt) ? formatDate(data.createdAt, DATE_FORMAT.date_with_time) : "\u200b"),
            React.createElement(Typography, { type: "text30", mb: "30px", textAlign: "center" }, getOperationType(transaction.reasonType)),
            React.createElement(TransactionAmount
            // status={transaction.status || ""}
            , { 
                // status={transaction.status || ""}
                amount: transaction.change, currency: transaction.currency, text: "text20" })),
        React.createElement(Box, { width: "100%" }, isLoading
            ? (React.createElement(Box, { display: "flex", width: "100%", height: "152px", alignItems: "center", justifyContent: "center" },
                React.createElement(Loader, { size: 32 })))
            : (React.createElement(TransactionInfoData, { data: [
                    ["Tx ID", data === null || data === void 0 ? void 0 : data.id],
                    ["Counterparty Name", (_b = data === null || data === void 0 ? void 0 : data.receipt) === null || _b === void 0 ? void 0 : _b.counterparty_name],
                    ["Counterparty Address", (_c = data === null || data === void 0 ? void 0 : data.receipt) === null || _c === void 0 ? void 0 : _c.counterparty_blockchain_address],
                    ["Your bank name", (_d = data === null || data === void 0 ? void 0 : data.receipt) === null || _d === void 0 ? void 0 : _d.originator_name],
                    ["Your bank account number", (_e = data === null || data === void 0 ? void 0 : data.receipt) === null || _e === void 0 ? void 0 : _e.originator_account_number],
                ] })))));
};
