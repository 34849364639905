var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useMutation } from "@tanstack/react-query";
import { walletApi } from "./api";
export var useCreateDirectExchange = function (options) { return useMutation(__assign({ mutationKey: ["directExchange.createDirectExchange"], mutationFn: function (_a) {
        var data = _a.data, dryRun = _a.dryRun;
        return walletApi.directExchange.createDirectExchange(data, { dryRun: dryRun });
    } }, options)); };
