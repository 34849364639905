import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { IndividualFormStore } from "@/pages/KYBPage/stores/IndividualFormStore";
import { IndividualListStore } from "@/pages/KYBPage/stores/IndividualListStore";
import { ROUTES } from "@/routes";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Button } from "@/shared/components/Button";
import { IndividualTypes } from "@/shared/constants/dict";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
export var IndividualItem = observer(function (_a) {
    var _b;
    var uuid = _a.uuid;
    var individualList = useStoreContext(IndividualListStore);
    var individualForm = useStore(IndividualFormStore, individualList);
    var IndividualFormProvider = useProvider(IndividualFormStore);
    var data = individualList.list.find(function (item) { return item.id === uuid; });
    var remove = function () { return individualForm.delete(uuid); };
    if (!data) {
        return null;
    }
    return (React.createElement(IndividualFormProvider, { value: individualForm },
        React.createElement(Box, { bgColor: "black-50", padding: "20px 30px", display: "flex", alignItems: "center", justifyContent: "space-between", borderRadius: "22px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "6px" },
                React.createElement(Typography, { type: "text14", color: "black-500" }, (_b = IndividualTypes.find(function (item) { return item.key === data.individualType; })) === null || _b === void 0 ? void 0 : _b.label),
                React.createElement(Typography, { type: "text16", fontWeight: 500, display: "flex", alignItems: "center", gap: "10px" },
                    data.firstName,
                    " ",
                    data.lastName,
                    React.createElement(BaseIcon, { icon: "Cross", size: 10, containerSize: 24, bgColor: "black-300", borderRadius: 24, color: "general-white", onClick: individualForm.loading ? undefined : remove, disabled: individualForm.loading }))),
            React.createElement(Button, { width: "auto", size: "s", paddingLeft: "25px", paddingRight: "25px", LinkComponent: Link, disabled: individualForm.loading, to: generatePath(ROUTES.kybFormId, { id: 5, param: uuid }) }, "Edit"))));
});
