export var download = function (file, name) {
    var href = URL.createObjectURL(file);
    var link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};
