import * as React from "react";
import { generatePath, Navigate, useParams } from "react-router-dom";
import { Account } from "@/pages/TransferPage/components/Account";
import { Crypto } from "@/pages/TransferPage/components/Crypto";
import { Recipient } from "@/pages/TransferPage/components/Recipient";
import { ROUTES, TransferType } from "@/routes";
export var TransferPage = function () {
    var params = useParams();
    switch (params.type) {
        case TransferType.Account:
            return React.createElement(Account, null);
        case TransferType.Recipient:
            return React.createElement(Recipient, null);
        case TransferType.Crypto:
            return React.createElement(Crypto, null);
        default:
            return (React.createElement(Navigate, { to: generatePath(ROUTES.transfer, { type: TransferType.Account }), replace: true }));
    }
};
