export var PARTNER_ID = "17";
export var CLIENT_ID = "ai1Mah8deezaingei0giebee8EWeixe7";
export var ACQUIRING_API_BASE_URL = "https://gw.api-stg.evercash.eu/acquiring";
export var APPROVER_API_BASE_URL = "https://gw.api-stg.evercash.eu";
export var CARD_HOLDER_API_BASE_URL = "https://gw.api-stg.evercash.eu";
export var CURRENCY_API_BASE_URL = "https://gw.api-stg.evercash.eu/currency";
export var REGISTRATION_API_BASE_URL = "https://gw.api-stg.evercash.eu/reg";
export var WALLET_API_BASE_URL = "https://gw.api-stg.evercash.eu/wallet";
export var STAND_URL = "https://banqeta.charism.ai";
export var ZEN_DESK_ID = "6083da3b-a66c-4c6f-b8e4-789efa9f7e91";
export var ZEN_DESK_NAME = "Banqeta";
export var EMAIL_HELP = "";
export var EMAIL_HELLO = "";
