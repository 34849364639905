var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { formattedTokenAmount } from "@broxus/js-utils";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CounterpartyType } from "@/api/wallet/types";
import { ConfirmPopup } from "@/features/transfer/components/ConfirmPopup";
import { FailPopup } from "@/features/transfer/components/FailPopup";
import { SuccessPopup } from "@/features/transfer/components/SuccessPopup";
import { Header } from "@/pages/TransferPage/components/Header";
import { TransferRecipientFormStore } from "@/pages/TransferPage/stores/TransferRecipientFormStore";
import { ROUTES, TransferType } from "@/routes";
import { Box, Button, FormControlInput } from "@/shared/components";
import { AccountSelect } from "@/shared/components/AccountSelect";
import { BankAccountPopup } from "@/shared/components/BankAccountPopup";
import { BankIcon } from "@/shared/components/BankIcon";
import { ExchangeField } from "@/shared/components/ExchangeField";
import { ExchangeInput } from "@/shared/components/ExchangeInput";
import { FormAlert } from "@/shared/components/FormAlert";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { Info } from "@/shared/components/Info";
import { OperationLayout } from "@/shared/components/OperationLayout";
import { RecipientPopup } from "@/shared/components/RecipientPopup";
import { SectionAmount } from "@/shared/components/SectionAmount";
import { UserPick } from "@/shared/components/UserPick";
import { PaymentPurpose } from "@/shared/constants/dict";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { RecipientListStore } from "@/shared/stores/RecipientListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { sliceAddress } from "@/shared/utils/slice-address";
export var Recipient = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    var media = useMedia();
    var fromPopup = useToggler();
    var navigate = useNavigate();
    var recipientPopup = useToggler();
    var search = useSearchParams()[0];
    var from = search.get("from");
    var to = search.get("to");
    var accountList = useStoreContext(AccountListStore);
    var FormProvider = useProvider(TransferRecipientFormStore);
    var balances = useStoreContext(BalanceListStore);
    var recipientList = useStoreContext(RecipientListStore);
    var addressList = useStoreContext(AddressListStore);
    var form = useStore(TransferRecipientFormStore, balances, accountList, recipientList, addressList);
    var error = form.enough === false ? "Enter a smaller amount" : undefined;
    React.useEffect(function () {
        runInAction(function () {
            form.from = from !== null && from !== void 0 ? from : undefined;
            form.to = to !== null && to !== void 0 ? to : undefined;
        });
    }, [from, to, form]);
    var info = [
        {
            label: "Amount",
            value: ((_a = form.feeData.value) === null || _a === void 0 ? void 0 : _a.data.amount) && form.fromAcc
                ? "".concat(formattedTokenAmount((_c = (_b = form.feeData.value) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.amount), " ").concat(form.fromAcc.currency)
                : "–",
        },
        {
            label: "Transaction fee",
            value: form.fee ? formattedTokenAmount(form.fee) : "–",
        },
        {
            label: "Processing time",
            value: "Instantly",
        },
    ];
    return (React.createElement(FormProvider, { value: form },
        fromPopup.enabled && (React.createElement(BankAccountPopup, { selectedId: (_d = form.fromAcc) === null || _d === void 0 ? void 0 : _d.account, onClose: fromPopup.off, onSelect: function (id) {
                fromPopup.off();
                navigate(generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Recipient,
                }, {
                    from: id,
                    to: undefined,
                }), {
                    replace: true,
                });
            } })),
        recipientPopup.enabled && (React.createElement(RecipientPopup, { type: CounterpartyType.FIAT, currency: (_e = form.fromAcc) === null || _e === void 0 ? void 0 : _e.currency, selectedId: to, onClose: recipientPopup.off, onSelect: function (id) {
                recipientPopup.off();
                navigate(generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Recipient,
                }, {
                    from: from,
                    to: id,
                }), {
                    replace: true,
                });
            } })),
        React.createElement(OperationLayout, { main: React.createElement(Box, null,
                React.createElement(Header, { active: TransferType.Recipient }),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                    React.createElement(ExchangeField, { error: error, input: React.createElement(ExchangeInput, { invalid: !!error, postFix: ((_f = form.fromAcc) === null || _f === void 0 ? void 0 : _f.currency.toUpperCase()) || "", value: form.amount, onChange: action(function (value) {
                                form.amount = value;
                            }) }), label: "You send", select: React.createElement(AccountSelect, { name: ((_h = (_g = form.fromAccAddress) === null || _g === void 0 ? void 0 : _g.first) === null || _h === void 0 ? void 0 : _h.accountNumber)
                                ? sliceAddress(form.fromAccAddress.first.accountNumber)
                                : undefined, descLoading: !form.balance, desc: form.balance
                                ? "".concat(formattedTokenAmount(form.balance.balance), " ").concat(form.balance.currency.toUpperCase())
                                : undefined, iconUri: form.fromAcc ? form.fromAcc.currency : undefined, onClick: fromPopup.on, icon: form.fromAcc
                                ? (React.createElement(BankIcon, { symbol: (_j = form.fromAcc) === null || _j === void 0 ? void 0 : _j.currency, size: 24 }))
                                : undefined }) }),
                    React.createElement(ExchangeField, { value: form.toRecipient ? form.toRecipient.accountNumber : "Bank account", label: "To", select: React.createElement(AccountSelect, { icon: form.toRecipient
                                ? (React.createElement(UserPick, { name: (_k = form.toRecipient) === null || _k === void 0 ? void 0 : _k.data.profile.name, size: 24 }))
                                : undefined, name: (_l = form.toRecipient) === null || _l === void 0 ? void 0 : _l.data.profile.name, onClick: recipientPopup.on }) }),
                    React.createElement(FormControlSelect, { label: "Purpose of payment", options: PaymentPurpose, value: form.paymentPurpose, onChange: action(function (_, v) {
                            form.paymentPurpose = v === null || v === void 0 ? void 0 : v.key;
                        }) }),
                    React.createElement(FormControlInput, { placeholder: "Memo", value: form.memo, onChange: action(function (e) {
                            form.memo = e.currentTarget.value;
                        }) }),
                    form.errors.at(0) && React.createElement(FormAlert, { text: form.errors.at(0).description }))), card: media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "50px" },
                React.createElement(Info, { data: info }),
                React.createElement(Box, { height: "1px", bgColor: "black-1000", width: "100%" }),
                React.createElement(SectionAmount, { amount: form.total, currency: (_m = form.fromAcc) === null || _m === void 0 ? void 0 : _m.currency }),
                React.createElement(Button, { width: "100%", onClick: form.showConfirmation, disabled: !form.ready, loading: form.feeData.loading || form.loading }, "Send"))), side: !media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", width: "100%" },
                React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                React.createElement(Info, { dark: false, data: __spreadArray(__spreadArray([], info, true), [
                        {
                            label: "Total + Fees",
                            value: form.fromAcc
                                ? "".concat((_o = form.total) !== null && _o !== void 0 ? _o : "0.00", " ").concat(form.fromAcc.currency)
                                : "\u200b",
                        },
                    ], false) }),
                React.createElement(Button, { width: "100%", onClick: form.showConfirmation, disabled: !form.ready, loading: form.feeData.loading || form.loading }, "Send"))) }),
        form.confirmation && (React.createElement(ConfirmPopup, { info: info, isLoading: form.loading, onClose: form.hideConfirmation, onSubmit: form.submit, amount: form.amount, currency: (_p = form.fromAcc) === null || _p === void 0 ? void 0 : _p.currency, to: (_q = form.toRecipient) === null || _q === void 0 ? void 0 : _q.data.profile.name })),
        form.result && (React.createElement(SuccessPopup, { minus: true, title: "You've successfully sent funds", address: (_r = form.toRecipient) === null || _r === void 0 ? void 0 : _r.accountNumber, amount: form.total, createdAt: (_s = form.result) === null || _s === void 0 ? void 0 : _s.createdAt, currency: (_t = form.result) === null || _t === void 0 ? void 0 : _t.currency, onClose: form.reset })),
        !!form.submitErrors.length && (React.createElement(FailPopup, { message: (_u = form.submitErrors.at(0)) === null || _u === void 0 ? void 0 : _u.description, onClose: form.hideErrors, onSubmit: form.showConfirmation }))));
});
