var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo, useRef } from "react";
import { NumericFormat } from "react-number-format";
import { mergeRefs, resolveOnChange } from "../../lib/resolveOnChange";
import { BaseInput } from "../Input";
var ImplNumberInput = React.forwardRef(function (_a, ref) {
    var _b = _a.maxValue, maxValue = _b === void 0 ? 9999999 : _b, _c = _a.precision, precision = _c === void 0 ? 2 : _c, _d = _a.value, value = _d === void 0 ? "" : _d, onChange = _a.onChange, _e = _a.allowNegative, allowNegative = _e === void 0 ? false : _e, _f = _a.thousandSeparator, thousandSeparator = _f === void 0 ? " " : _f, mask = _a.mask, format = _a.format, _g = _a.placeholder, placeholder = _g === void 0 ? "Enter Value" : _g, disabled = _a.disabled, onBlur = _a.onBlur, _h = _a.convertValueToNumber, convertValueToNumber = _h === void 0 ? false : _h, inputProps = __rest(_a, ["maxValue", "precision", "value", "onChange", "allowNegative", "thousandSeparator", "mask", "format", "placeholder", "disabled", "onBlur", "convertValueToNumber"]);
    var refInput = useRef();
    var isTel = inputProps.type === "tel";
    var withValueLimit = function (_a) {
        var _b = _a.floatValue, floatValue = _b === void 0 ? 0 : _b;
        return isTel || floatValue <= maxValue;
    };
    var handleChange = function (values, info) {
        if (refInput.current) {
            resolveOnChange(refInput.current, info.event, onChange, values.value);
        }
    };
    var handleBlur = function (e) {
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
        if (refInput.current) {
            if (value && value !== parseFloat("".concat(value))) {
                resolveOnChange(refInput.current, e, onChange, "".concat(parseFloat("".concat(value))));
            }
        }
    };
    var convertedValue = !!value && !!convertValueToNumber ? Number(value) : value;
    return (React.createElement(NumericFormat
    // TODO: Temporary fix. The "react-number-format" component should be investigated.
    // https://jira.softswiss.net/browse/AB-3282
    , __assign({ 
        // TODO: Temporary fix. The "react-number-format" component should be investigated.
        // https://jira.softswiss.net/browse/AB-3282
        value: value === null ? "" : convertedValue, onValueChange: handleChange, customInput: BaseInput, isAllowed: withValueLimit, thousandSeparator: thousandSeparator, decimalScale: precision, allowNegative: allowNegative, allowLeadingZeros: isTel, decimalSeparator: isTel ? undefined : ".", onBlur: handleBlur, getInputRef: mergeRefs(ref, refInput), placeholder: placeholder, disabled: disabled }, inputProps)));
});
export var NumberInput = memo(ImplNumberInput);
