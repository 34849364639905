var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
export var ListItem = function (_a) {
    var name = _a.name, success = _a.success, link = _a.link, loading = _a.loading, disabled = _a.disabled;
    var navigate = useNavigate();
    return (React.createElement(Wrapper, { disabled: disabled, onClick: disabled ? undefined : function () { return navigate(link); } },
        React.createElement(Typography, { type: "text16", color: "black-1200", fontWeight: 500 }, name),
        React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px", minHeight: "30px" },
            loading ? React.createElement(Loader, { size: 18 }) : (success && (React.createElement(Box, { padding: "5px 10px", borderRadius: "100px", bgColor: "general-green-transparent-5" },
                React.createElement(Typography, { type: "text12", fontWeight: 500, color: "general-green" }, "Completed")))),
            !disabled && (React.createElement(BaseIcon, { icon: "Right", containerSize: 24, size: 12, color: "black-500" })))));
};
// dprint-ignore
var Wrapper = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background: ", ";\n    border-radius: 22px;\n    padding: 18px 30px;\n    cursor: ", ";\n\n    &:hover {\n        background: ", ";\n    }\n"], ["\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background: ", ";\n    border-radius: 22px;\n    padding: 18px 30px;\n    cursor: ", ";\n\n    &:hover {\n        background: ", ";\n    }\n"])), function (props) { return props.theme.colors["black-50"]; }, function (props) { return props.disabled ? "default" : "pointer"; }, function (props) { return props.disabled
    ? props.theme.colors["black-50"]
    : props.theme.colors["black-100"]; });
var templateObject_1;
