var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { CreateWalletPopup } from "@/features/create-wallet";
import { BalanceItem } from "@/pages/HomePage/components/Balances/Item";
import { ROUTES } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { EmptyItem } from "@/shared/components/EmptyItem";
import { Loader } from "@/shared/components/Loader";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
export var Balances = observer(function () {
    var _a = useMedia(), tablet = _a.tablet, phone = _a.phone;
    var _b = React.useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var accounts = useStoreContext(AccountListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var addressList = useStoreContext(AddressListStore);
    var loaded = accounts.crypto.every(function (item) { var _a; return (_a = balanceList.data[item.account]) === null || _a === void 0 ? void 0 : _a.balance; });
    React.useEffect(function () {
        addressList.sync.apply(addressList, accounts.crypto);
    }, [balanceList, addressList, accounts.crypto]);
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px" },
        React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
            React.createElement(Typography, { type: "text24" }, "Wallets"),
            React.createElement(Button, { variant: "Gray", LinkComponent: Link, to: generatePath(ROUTES.walletList), size: "s" }, "View all")),
        React.createElement(Box, { display: "flex", flexDirection: "column", bgColor: "general-white", borderRadius: "22px", padding: tablet ? "30px" : "40px", gap: tablet ? "30px" : "40px", marginBottom: "40px" }, !accounts.crypto.length
            ? (React.createElement(React.Fragment, null,
                React.createElement(Box, { display: "grid", gridTemplateColumns: tablet ? "1fr" : "1fr 1fr", gap: "21px" },
                    React.createElement(EmptyItem, null),
                    React.createElement(EmptyItem, null)),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "40px", padding: "25px", alignItems: "center" },
                    React.createElement(Typography, { color: "black-400", type: "text14" }, "Invoicing in crypto never was so close to you"),
                    React.createElement(Button, { onClick: function () { return setIsOpen(true); }, variant: "Black", width: 250 }, "Create new wallet")),
                isOpen && (React.createElement(CreateWalletPopup, { onClose: function () { return setIsOpen(false); }, onSuccess: function () { } }))))
            : (React.createElement(React.Fragment, null,
                React.createElement(Box, { position: "relative" },
                    React.createElement(Grid, { tablet: tablet, visible: loaded }, accounts.crypto.map(function (account) {
                        var _a, _b, _c, _d;
                        var address = (_a = addressList.data[account.account]) === null || _a === void 0 ? void 0 : _a[account.currency];
                        return (React.createElement(BalanceItem, { key: account.account, currency: account.currency, id: account.account, amount: (_b = balanceList.data[account.account]) === null || _b === void 0 ? void 0 : _b.balance, address: (_c = address === null || address === void 0 ? void 0 : address.first) === null || _c === void 0 ? void 0 : _c.address, isEmpty: (_d = address === null || address === void 0 ? void 0 : address.first) === null || _d === void 0 ? void 0 : _d.isEmpty }));
                    })),
                    !loaded && (React.createElement(Loader, { position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px", size: 24 }))),
                React.createElement(Box, { tabIndex: 0, role: "button", display: "flex", gap: "15px", alignItems: "center", alignSelf: "flex-start", onClick: function () { return setIsOpen(true); } },
                    React.createElement(BaseIcon, { icon: "Deposit", size: phone ? 20 : 24, containerSize: phone ? 36 : 42, color: "deep-blue-500", bgColor: "deep-blue-500-transparent-5", borderRadius: "50%" }),
                    React.createElement(Typography, { color: "deep-blue-500" }, "Create new wallet")),
                isOpen && (React.createElement(CreateWalletPopup, { onClose: function () { return setIsOpen(false); }, onSuccess: function () { return setIsOpen(false); } })))))));
});
var Grid = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: grid;\n    grid-template-columns: ", ";\n    gap: 20px 100px;\n    opacity: ", ";\n    transition: 0.2s ease;\n"], ["\n    display: grid;\n    grid-template-columns: ", ";\n    gap: 20px 100px;\n    opacity: ", ";\n    transition: 0.2s ease;\n"])), function (props) { return (props.tablet ? "1fr" : "1fr 1fr"); }, function (props) { return (props.visible ? 1 : 0); });
var templateObject_1;
