var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observer } from "mobx-react-lite";
import * as React from "react";
import { IndividualVerificationStore } from "@/pages/KYBPage/stores/IndividualVerificationStore";
import { UploadStore } from "@/pages/KYBPage/stores/UploadStore";
import { Upload } from "@/shared/components/Upload";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
export var IndividualDocument = observer(function (_a) {
    var _b, _c, _d, _e, _f;
    var label = _a.label, subType = _a.subType, type = _a.type;
    var verification = useStoreContext(IndividualVerificationStore);
    var upload = useStore(UploadStore, verification);
    var doc = (_c = (_b = verification.data) === null || _b === void 0 ? void 0 : _b.docs) === null || _c === void 0 ? void 0 : _c.find(function (item) { return item.type === type && item.sub_type === subType; });
    var onFiles = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    if (!!verification.data) return [3 /*break*/, 2];
                    return [4 /*yield*/, verification.createVerification()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    if (verification.errors.length > 0) {
                        upload.setErrors(verification.errors);
                    }
                    else if (verification.data) {
                        upload.upload(verification.data.id, e[0], type, subType);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _a.sent();
                    console.error(e_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Upload, { size: doc === null || doc === void 0 ? void 0 : doc.size, label: label, onFiles: onFiles, loading: upload.loading || !verification.initialized, error: (_d = upload.errors[0]) === null || _d === void 0 ? void 0 : _d.description, fileName: doc ? label : undefined, disabled: verification.loading, onDownload: ((_e = verification.data) === null || _e === void 0 ? void 0 : _e.id) && (doc === null || doc === void 0 ? void 0 : doc.id)
            ? function () { return upload.download(verification.data.id, doc === null || doc === void 0 ? void 0 : doc.id, doc.filename); }
            : undefined, onRemove: doc && ((_f = verification.data) === null || _f === void 0 ? void 0 : _f.id)
            ? function () { return upload.delete(verification.data.id, doc.id); }
            : undefined }));
});
