var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Autocomplete as MUIAutocomplete, } from "@mui/material";
import { styled } from "@mui/material/styles";
import { compose, spacing } from "@mui/system";
import classNames from "classnames";
import React, { useState } from "react";
import { BaseIcon } from "../BaseIcon";
import { BaseInput } from "../Input";
import { Typography } from "../Typography";
import { getOptionLabel } from "./lib";
import { StyledAutocomplete } from "./styled";
var ImplAutocomplete = function (_a, ref) {
    var multiple = _a.multiple, placeholder = _a.placeholder, freeSolo = _a.freeSolo, groupBy = _a.groupBy, _b = _a.showChips, showChips = _b === void 0 ? true : _b, onChange = _a.onChange, size = _a.size, width = _a.width, maxHeightList = _a.maxHeightList, FormControlProps = _a.FormControlProps, inputProps = _a.inputProps, renderInput = _a.renderInput, isVirtualized = _a.isVirtualized, onBlur = _a.onBlur, options = _a.options, AutocompleteInputProps = _a.InputProps, maxItemChecked = _a.maxItemChecked, value = _a.value, isLoading = _a.isLoading, disableClearable = _a.disableClearable, label = _a.label, _c = _a.ListboxProps, ListboxProps = _c === void 0 ? {} : _c, props = __rest(_a, ["multiple", "placeholder", "freeSolo", "groupBy", "showChips", "onChange", "size", "width", "maxHeightList", "FormControlProps", "inputProps", "renderInput", "isVirtualized", "onBlur", "options", "InputProps", "maxItemChecked", "value", "isLoading", "disableClearable", "label", "ListboxProps"]);
    var _d = useState(""), inputValue = _d[0], setInputValue = _d[1];
    var hasNoSelected = Array.isArray(value) ? !value.length : !value;
    var isEmptyAutocomplete = !inputValue && !options.length && hasNoSelected;
    // const listboxProps: object = {
    //     inputValue,
    //     maxHeightList,
    //     isVirtualized,
    //     isGroup: !!groupBy,
    //     role: "listbox",
    //     ...ListboxProps,
    // }
    var handleChange = function (event, changedValue, reason, details) {
        // To clear input value without changing selected options
        if (multiple && inputValue && reason === "clear")
            return;
        // To prevent selection changing on click Backspace button
        if (multiple && (event === null || event === void 0 ? void 0 : event.key) === "Backspace")
            return;
        // To prevent selection on out of max selected items count
        if (multiple && Array.isArray(changedValue) && maxItemChecked
            && changedValue.length > maxItemChecked) {
            return;
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(event, changedValue, reason, details);
    };
    return (React.createElement(StyledAutocomplete, { "$smallList": multiple && showChips, "$width": width, "$maxHeightList": maxHeightList },
        React.createElement(MUIAutocomplete, __assign({ options: showChips && !options.length ? [{ key: "empty" }] : options, id: "SW-Autocomplete", inputValue: inputValue, onInputChange: function (_, value) { return setInputValue(value); }, groupBy: groupBy, disableClearable: disableClearable !== null && disableClearable !== void 0 ? disableClearable : true, onChange: handleChange, freeSolo: freeSolo, clearOnBlur: freeSolo && multiple, openOnFocus: true, multiple: multiple, disableCloseOnSelect: multiple, 
            // ListboxComponent={ListBox}
            componentsProps: isEmptyAutocomplete
                ? { paper: { sx: { display: "none" } } }
                : undefined, value: value, 
            // clearIcon={<BaseIcon icon="X" size={10} p="4px" opacity="0.4" data-testid="button-clear" />}
            popupIcon: React.createElement(BaseIcon, { icon: "DropDown", size: 24, p: "0", color: "black-500" }), renderInput: renderInput
                || (function (_a) {
                    var _b = _a.InputProps, containerRef = _b.ref, InputProps = __rest(_b, ["ref"]), InputLabelProps = _a.InputLabelProps, params = __rest(_a, ["InputProps", "InputLabelProps"]);
                    return (React.createElement(BaseInput, __assign({}, params, { className: classNames({ "hasOptionsValue": !!(value === null || value === void 0 ? void 0 : value.length) }), containerRef: containerRef, inputProps: __assign(__assign({ placeholder: placeholder }, inputProps), params.inputProps), size: size, width: width, inputRef: ref, onBlur: onBlur, label: label, InputProps: __assign(__assign({}, InputProps), AutocompleteInputProps), error: !!(FormControlProps === null || FormControlProps === void 0 ? void 0 : FormControlProps.error) })));
                }), noOptionsText: React.createElement(Typography, null, "No Options"), getOptionLabel: getOptionLabel, 
            // ListboxProps={listboxProps}
            loading: isLoading }, props))));
};
var BaseAutocomplete = React.forwardRef(ImplAutocomplete);
export var Autocomplete = styled(BaseAutocomplete, {
    shouldForwardProp: function (prop) { return !spacing.filterProps.includes(prop); },
})(compose(spacing));
