/* eslint-disable react/no-array-index-key */
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Typography } from "@/shared/components";
import { useCopy } from "@/shared/hooks/useCopy";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore, EAddressTypes } from "@/shared/stores/AddressListStore";
import { dataToText, saveToPDF } from "@/shared/utils/save-to-pdf";
export var DepositFiatInfo = observer(function () {
    var _a;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
    var params = useParams();
    var media = useMedia();
    var accountList = useStoreContext(AccountListStore);
    var addressList = useStoreContext(AddressListStore);
    var account = params.id ? accountList.fiatById[params.id] : undefined;
    var address = account
        ? (_b = addressList.data[account === null || account === void 0 ? void 0 : account.account]) === null || _b === void 0 ? void 0 : _b[account === null || account === void 0 ? void 0 : account.currency]
        : undefined;
    var _6 = React.useState(0), activeIndex = _6[0], setActiveIndex = _6[1];
    var activeAddress = address === null || address === void 0 ? void 0 : address.list.at(activeIndex);
    var infoByType = (_a = {},
        _a[EAddressTypes.ACH] = [
            ["Beneficiary", (_c = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderName) !== null && _c !== void 0 ? _c : "—"],
            ["Bank account number", (_d = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountNumber) !== null && _d !== void 0 ? _d : "—"],
            [
                "Account routing number",
                (_e = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountRoutingNumber) !== null && _e !== void 0 ? _e : "—",
            ],
            ["Bank name", (_f = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankName) !== null && _f !== void 0 ? _f : "—"],
            ["Memo", (_g = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.memo) !== null && _g !== void 0 ? _g : "—"],
        ],
        _a[EAddressTypes.FEDWIRE] = [
            ["Beneficiary", (_h = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderName) !== null && _h !== void 0 ? _h : "—"],
            ["Beneficiary address", (_j = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderAddress) !== null && _j !== void 0 ? _j : "—"],
            ["Bank account number", (_k = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountNumber) !== null && _k !== void 0 ? _k : "—"],
            [
                "Account routing number",
                (_l = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountRoutingNumber) !== null && _l !== void 0 ? _l : "—",
            ],
            ["Bank name", (_m = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankName) !== null && _m !== void 0 ? _m : "—"],
            ["Bank address", (_o = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankAddress) !== null && _o !== void 0 ? _o : "—"],
            ["Memo", (_p = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.memo) !== null && _p !== void 0 ? _p : "—"],
        ],
        _a[EAddressTypes.SWIFT] = [
            ["Beneficiary", (_q = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderName) !== null && _q !== void 0 ? _q : "—"],
            ["Beneficiary address", (_r = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderAddress) !== null && _r !== void 0 ? _r : "—"],
            ["Bank account number", (_s = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountNumber) !== null && _s !== void 0 ? _s : "—"],
            ["Bank name", (_t = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankName) !== null && _t !== void 0 ? _t : "—"],
            ["Bank address", (_u = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankAddress) !== null && _u !== void 0 ? _u : "—"],
            ["Bic", (_v = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.swiftBic) !== null && _v !== void 0 ? _v : "—"],
            ["Memo", (_w = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.memo) !== null && _w !== void 0 ? _w : "—"],
        ],
        _a[EAddressTypes.EFT] = [
            ["Beneficiary", (_x = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderName) !== null && _x !== void 0 ? _x : "—"],
            ["Beneficiary address", (_y = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountHolderAddress) !== null && _y !== void 0 ? _y : "—"],
            ["Bank account number", (_z = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.accountNumber) !== null && _z !== void 0 ? _z : "—"],
            ["Bank name", (_0 = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankName) !== null && _0 !== void 0 ? _0 : "—"],
            ["Bank address", (_1 = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.bankAddress) !== null && _1 !== void 0 ? _1 : "—"],
            ["Institution number", (_2 = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.institutionNumber) !== null && _2 !== void 0 ? _2 : "—"],
            ["Transit number", (_3 = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.transitNumber) !== null && _3 !== void 0 ? _3 : "—"],
            ["Memo", (_4 = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.memo) !== null && _4 !== void 0 ? _4 : "—"],
        ],
        _a[EAddressTypes.EMPTY] = [],
        _a);
    var infoFullData = infoByType[(_5 = activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.type) !== null && _5 !== void 0 ? _5 : EAddressTypes.EMPTY];
    var copy = useCopy(dataToText(infoFullData));
    var savePDF = function () {
        saveToPDF(dataToText(infoFullData, 60), "bank.pdf");
    };
    React.useEffect(function () {
        addressList.sync(account);
    }, [account, addressList]);
    var infoEmpty = infoFullData.every(function (_) { return _[1] === "—"; });
    return (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "40px" },
        React.createElement(Box, { width: "100%", display: "grid", gridTemplateColumns: media.phone ? "1fr" : "1fr 1fr 1fr", gap: "10px" }, address === null || address === void 0 ? void 0 : address.list.map(function (item, index) {
            var _a;
            return (React.createElement(Button, { size: "xs", variant: activeIndex === index ? undefined : "Gray", onClick: function () {
                    setActiveIndex(index);
                } }, (_a = item.type) === null || _a === void 0 ? void 0 : _a.toUpperCase()));
        })),
        !infoEmpty && (React.createElement(React.Fragment, null,
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px", width: "100%" }, infoFullData.map(function (item, index) { return (React.createElement(Box, { display: "flex", flexDirection: "column", key: index },
                React.createElement(Typography, { type: "text14", color: "black-400" }, item[0]),
                React.createElement(Typography, { type: "text16", color: media.desktop
                        ? "general-white"
                        : undefined }, item[1]))); })),
            React.createElement(Box, { width: "100%", display: media.desktop ? "grid" : "flex", flexDirection: media.desktop ? undefined : "column", alignItems: media.desktop ? "center" : "stretch", gridAutoFlow: "column", gridAutoColumns: "minmax(0, 1fr)", gap: media.desktop ? "30px" : "10px", whiteSpace: "nowrap" },
                React.createElement(Button, { variant: "LightBlue", size: "s", onClick: copy }, "Copy details"),
                React.createElement(Button, { variant: "LightBlue", size: "s", onClick: savePDF }, "Save details in PDF"))))));
});
