import * as React from "react";
import { Navigate, useParams } from "react-router-dom";
import { Exchange } from "@/pages/ExchangePage/components/Exchange";
import { ExchangeType, ROUTES } from "@/routes";
import { generateQueryPath } from "@/shared/utils/path";
export var ExchangePage = function () {
    var params = useParams();
    switch (params.type) {
        case ExchangeType.Crypto:
            return React.createElement(Exchange, { type: ExchangeType.Crypto });
        case ExchangeType.Fiat:
            return React.createElement(Exchange, { type: ExchangeType.Fiat });
        case ExchangeType.FiatFiat:
            return React.createElement(Exchange, { type: ExchangeType.FiatFiat });
        default:
            return (React.createElement(Navigate, { to: generateQueryPath(ROUTES.exchange, { type: ExchangeType.Crypto }), replace: true }));
    }
};
