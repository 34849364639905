var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeAutoObservable, runInAction } from "mobx";
import { registrationApi } from "@/api/registration";
import { parseApiError } from "@/shared/utils/error";
var PaymentPromoStore = /** @class */ (function () {
    function PaymentPromoStore(subscription) {
        this.subscription = subscription;
        this.discounts = [];
        this.discountErrors = [];
        this.code = undefined;
        this.loader = 0;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    PaymentPromoStore.prototype.init = function () {
        this.syncDiscounts();
    };
    PaymentPromoStore.prototype.syncDiscounts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var discounts, discountErrors, discountsResp, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        discounts = [];
                        discountErrors = [];
                        runInAction(function () {
                            _this.loader += 1;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, registrationApi.discounts.getDiscounts()];
                    case 2:
                        discountsResp = _a.sent();
                        discounts = discountsResp.data;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        discountErrors = parseApiError(e_1);
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        runInAction(function () {
                            var _a;
                            _this.discountErrors = discountErrors;
                            _this.discounts = discounts;
                            _this.code = (_a = _this.discountHighest) === null || _a === void 0 ? void 0 : _a.code;
                            _this.loader -= 1;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PaymentPromoStore.prototype.submitDiscount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var discountErrors, e_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        discountErrors = [];
                        runInAction(function () {
                            _this.loader += 1;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        if (!this.code) {
                            throw new Error("Code must be defined");
                        }
                        return [4 /*yield*/, this.subscription.applyPromo(this.code)];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.syncDiscounts()];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_2 = _a.sent();
                        discountErrors = parseApiError(e_2);
                        console.error(e_2);
                        return [3 /*break*/, 5];
                    case 5:
                        runInAction(function () {
                            _this.loader -= 1;
                            _this.discountErrors = discountErrors;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(PaymentPromoStore.prototype, "discountHighest", {
        // if more than 1 promo code, we should use highest, business logic
        get: function () {
            if (this.discounts.length === 0)
                return undefined;
            var percentArr = this.discounts.map(function (_) { var _a; return (_a = _.discount_percent) !== null && _a !== void 0 ? _a : 0; });
            var index = percentArr.indexOf(Math.max.apply(Math, percentArr));
            return this.discounts[index];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentPromoStore.prototype, "discount", {
        get: function () {
            if (this.discounts.length === 0)
                return undefined;
            return this.discounts[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentPromoStore.prototype, "loading", {
        get: function () {
            return this.loader > 0;
        },
        enumerable: false,
        configurable: true
    });
    return PaymentPromoStore;
}());
export { PaymentPromoStore };
