var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import BigNumber from "bignumber.js";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { currencyApi } from "@/api/currency/api";
import { walletApi } from "@/api/wallet/api";
import { DataSync } from "@/shared/stores/DataStore";
import { parseApiError } from "@/shared/utils/error";
import { Reactions } from "@/shared/utils/reactions";
var TransferAccountFormStore = /** @class */ (function () {
    function TransferAccountFormStore(balanceList, accountList, addressList) {
        this.balanceList = balanceList;
        this.accountList = accountList;
        this.addressList = addressList;
        this.loading = false;
        this.from = undefined;
        this.to = undefined;
        this.amount = undefined;
        this.errors = [];
        this.result = undefined;
        this.confirmation = false;
        this.settings = new DataSync(currencyApi.currency.getCurrencyBySlug.bind(currencyApi), function (d) { return d.data.settings; });
        this.reactions = new Reactions();
        makeAutoObservable(this, {}, { autoBind: true });
    }
    TransferAccountFormStore.prototype.init = function () {
        var _this = this;
        this.reactions.create(reaction(function () { var _a; return (_a = _this.fromAcc) === null || _a === void 0 ? void 0 : _a.currency; }, function () {
            var _a;
            if ((_a = _this.fromAcc) === null || _a === void 0 ? void 0 : _a.currency) {
                _this.settings.sync(_this.fromAcc.currency.toLowerCase());
            }
            else {
                _this.settings.reset();
            }
        }, {
            fireImmediately: true,
        }), reaction(function () { return [_this.from, _this.to]; }, function () {
            _this.balanceList.sync(_this.from, _this.to);
        }, {
            fireImmediately: true,
        }), reaction(function () { return [_this.fromAcc, _this.toAcc]; }, function () {
            _this.addressList.sync(_this.fromAcc, _this.toAcc);
        }, {
            fireImmediately: true,
        }));
    };
    TransferAccountFormStore.prototype.dispose = function () {
        this.reactions.destroy();
    };
    TransferAccountFormStore.prototype.reset = function () {
        this.loading = false;
        this.amount = undefined;
        this.errors = [];
        this.result = undefined;
        this.confirmation = false;
        this.settings.reset();
    };
    TransferAccountFormStore.prototype.showConfirmation = function () {
        this.confirmation = true;
        this.errors = [];
    };
    TransferAccountFormStore.prototype.hideConfirmation = function () {
        this.confirmation = false;
    };
    TransferAccountFormStore.prototype.hideErrors = function () {
        this.errors = [];
    };
    TransferAccountFormStore.prototype.submit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var errors, result, resp, e_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        errors = [];
                        runInAction(function () {
                            _this.loading = true;
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        if (!this.amount) {
                            throw new Error("amount must be defined");
                        }
                        if (!this.fromAcc) {
                            throw new Error("fromAcc must be defined");
                        }
                        if (!this.toAcc) {
                            throw new Error("toAcc must be defined");
                        }
                        return [4 /*yield*/, walletApi.transfer.createTransfer({
                                amount: this.amount,
                                fromAccount: this.fromAcc.account,
                                toAccount: this.toAcc.account,
                                currency: this.fromAcc.currency,
                                type: "ORIGINAL",
                            })];
                    case 2:
                        resp = _a.sent();
                        result = resp.data;
                        return [4 /*yield*/, this.balanceList.sync(this.from, this.to)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        errors = parseApiError(e_1);
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 5:
                        runInAction(function () {
                            _this.loading = false;
                            _this.errors = errors;
                            _this.result = result;
                            _this.confirmation = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(TransferAccountFormStore.prototype, "isExchange", {
        get: function () {
            return this.fromAcc && this.toAcc
                ? this.fromAcc.currency !== this.toAcc.currency
                : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "fromAcc", {
        get: function () {
            return this.from ? this.accountList.fiatById[this.from] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "toAcc", {
        get: function () {
            return this.to ? this.accountList.fiatById[this.to] : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "fromBalance", {
        get: function () {
            return this.fromAcc
                ? this.balanceList.data[this.fromAcc.account]
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "toBalance", {
        get: function () {
            return this.toAcc
                ? this.balanceList.data[this.toAcc.account]
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "fromEnough", {
        get: function () {
            return this.total && this.fromBalance
                ? new BigNumber(this.total).lte(this.fromBalance.balance)
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "toAccAddress", {
        get: function () {
            var _a;
            return this.toAcc
                ? (_a = this.addressList.data[this.toAcc.account]) === null || _a === void 0 ? void 0 : _a[this.toAcc.currency]
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "fromAccAddress", {
        get: function () {
            var _a;
            return this.fromAcc
                ? (_a = this.addressList.data[this.fromAcc.account]) === null || _a === void 0 ? void 0 : _a[this.fromAcc.currency]
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "valid", {
        get: function () {
            return (this.amount
                && this.toAcc
                && this.fromEnough
                && new BigNumber(this.amount).gt(0));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "fee", {
        get: function () {
            var _a;
            return this.amount && this.settings.value
                ? new BigNumber(this.amount)
                    .times((_a = this.settings.value.transferFeePercent) !== null && _a !== void 0 ? _a : "0")
                    .dividedBy(100)
                    .toFixed()
                : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TransferAccountFormStore.prototype, "total", {
        get: function () {
            return this.amount && this.fee
                ? new BigNumber(this.amount).plus(this.fee).toFixed()
                : "0.00";
        },
        enumerable: false,
        configurable: true
    });
    return TransferAccountFormStore;
}());
export { TransferAccountFormStore };
