import { observer } from "mobx-react-lite";
import * as React from "react";
import { CompanyVerificationStore } from "@/pages/KYBPage/stores/CompanyVerificationStore";
import { UploadStore } from "@/pages/KYBPage/stores/UploadStore";
import { Upload } from "@/shared/components/Upload";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
export var CompanyDocument = observer(function (_a) {
    var _b, _c, _d, _e, _f;
    var label = _a.label, subType = _a.subType, type = _a.type;
    var verification = useStoreContext(CompanyVerificationStore);
    var upload = useStore(UploadStore, verification);
    var doc = (_c = (_b = verification.data) === null || _b === void 0 ? void 0 : _b.docs) === null || _c === void 0 ? void 0 : _c.find(function (item) { return item.type === type && item.sub_type === subType; });
    return (React.createElement(Upload, { size: doc === null || doc === void 0 ? void 0 : doc.size, label: label, onFiles: function (e) { return upload.upload(verification.data.id, e[0], type, subType); }, loading: upload.loading || !verification.ready, error: (_d = upload.errors[0]) === null || _d === void 0 ? void 0 : _d.description, fileName: doc ? label : undefined, disabled: !verification.data || verification.loading, onDownload: ((_e = verification.data) === null || _e === void 0 ? void 0 : _e.id) && (doc === null || doc === void 0 ? void 0 : doc.id)
            ? function () { return upload.download(verification.data.id, doc === null || doc === void 0 ? void 0 : doc.id, doc.filename); }
            : undefined, onRemove: doc && ((_f = verification.data) === null || _f === void 0 ? void 0 : _f.id)
            ? function () { return upload.delete(verification.data.id, doc.id); }
            : undefined }));
});
