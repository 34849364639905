var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { compose, spacing } from "@mui/system";
import React, { memo, useRef } from "react";
import { StyledTypography } from "./styled";
export var ImplTypography = React.forwardRef(function (_a, ref) {
    var children = _a.children, className = _a.className, _b = _a.variant, variant = _b === void 0 ? "span" : _b, _c = _a.color, color = _c === void 0 ? "black-1200" : _c, lineClamp = _a.lineClamp, _d = _a.type, type = _d === void 0 ? "text16" : _d, bgColor = _a.bgColor, textIndent = _a.textIndent, wordBreak = _a.wordBreak, textDecoration = _a.textDecoration, props = __rest(_a, ["children", "className", "variant", "color", "lineClamp", "type", "bgColor", "textIndent", "wordBreak", "textDecoration"]);
    var isButton = !!props.onClick;
    var containerRef = useRef(null);
    return (React.createElement(StyledTypography, __assign({ className: className, tabIndex: isButton ? 0 : undefined, role: isButton ? "button" : undefined, "$textColor": color, "$bgColor": bgColor, "$text": type, "$lineClamp": lineClamp, "$wordBreak": wordBreak, "$textIndent": textIndent, "$textDecoration": textDecoration, variant: variant }, props, { ref: ref || containerRef }), children));
});
export var Typography = memo(styled(ImplTypography, {
    shouldForwardProp: function (prop) { return !spacing.filterProps.includes(prop); },
})(compose(spacing)));
