import { makeAutoObservable } from "mobx";
var CreateRecipientStore = /** @class */ (function () {
    function CreateRecipientStore() {
        this.isOpen = false;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    CreateRecipientStore.prototype.setIsOpen = function (isOpen) {
        this.isOpen = isOpen;
    };
    return CreateRecipientStore;
}());
export { CreateRecipientStore };
