import { action } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";
import { SuccessPopup } from "./ModalSuccess";
import { Box, Typography } from "@/shared/components";
export var SuccessCardPage = observer(function () {
    var navigate = useNavigate();
    return (React.createElement(Box, { display: "flex", gap: "40px", mt: "20px", flexDirection: "column" },
        React.createElement(Typography, { type: "text32Bold" }, "Payment status"),
        React.createElement(SuccessPopup, { onClose: action(function () {
                navigate("/");
            }) })));
});
