export var sliceAddress = function (value, start, end) {
    if (start === void 0) { start = 5; }
    if (end === void 0) { end = 3; }
    if (start === 0) {
        return value.toLowerCase();
    }
    if (value.length <= start + end) {
        return value;
    }
    var startStr = value.substr(0, start).toLowerCase();
    var endStr = value.substr(value.length - end, end).toLowerCase();
    return "".concat(startStr, "...").concat(endStr);
};
