var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useSwitch } from "@mui/base/useSwitch";
import classNames from "classnames";
import React, { memo } from "react";
import { SwitchInput, SwitchRoot, SwitchThumb, SwitchTrack } from "./styled";
var ImplSwitch = React.forwardRef(function (_a, ref) {
    var isHover = _a.isHover, name = _a.name, id = _a.id, props = __rest(_a, ["isHover", "name", "id"]);
    var _b = useSwitch(props), getInputProps = _b.getInputProps, checked = _b.checked, disabled = _b.disabled, focusVisible = _b.focusVisible;
    var stateClasses = {
        checked: checked,
        disabled: disabled,
        focusVisible: focusVisible,
    };
    return (React.createElement(SwitchRoot, { className: classNames(stateClasses), "$isHover": isHover },
        React.createElement(SwitchTrack, null,
            React.createElement(SwitchThumb, { className: classNames(stateClasses, "SwitchThumb") })),
        React.createElement(SwitchInput, __assign({}, getInputProps(), { name: name, id: id, ref: ref }))));
});
export var Switch = memo(ImplSwitch);
