var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Loader } from "@/shared/components/Loader";
import { Typography } from "@/shared/components/Typography";
export var AccountSelect = function (_a) {
    var disabled = _a.disabled, icon = _a.icon, iconUri = _a.iconUri, name = _a.name, desc = _a.desc, descLoading = _a.descLoading, onClick = _a.onClick;
    return (React.createElement(Wrapper, { disabled: disabled, onClick: onClick },
        React.createElement(Box, { display: "flex", gap: "10px", alignItems: "center" }, icon !== null && icon !== void 0 ? icon : (iconUri && React.createElement("img", { src: iconUri, width: 24, alt: "" })),
            (name || desc) && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "flex-start" },
                name && (React.createElement(Typography, { type: "text14", fontWeight: 500 }, name)),
                descLoading
                    ? React.createElement(Loader, { size: 13, containerSize: 18 })
                    : desc
                        ? (React.createElement(Desc, { type: "text12", fontWeight: 500, color: "black-400", lineHeight: "18px" }, desc))
                        : null))),
        React.createElement(BaseIcon, { icon: "DropDown", size: 24, color: "black-500" })));
};
var Wrapper = styled("button")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: ", ";\n    border-radius: 16px;\n    padding: 8px 20px;\n    display: flex;\n    align-items: center;\n    min-height: 55px;\n    gap: 10px;\n    justify-content: space-between;\n    min-width: 212px;\n"], ["\n    background: ", ";\n    border-radius: 16px;\n    padding: 8px 20px;\n    display: flex;\n    align-items: center;\n    min-height: 55px;\n    gap: 10px;\n    justify-content: space-between;\n    min-width: 212px;\n"])), function (props) { return props.theme.colors["general-white"]; });
var Desc = styled(Typography)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    line-height: 18px;\n"], ["\n    line-height: 18px;\n"])));
var templateObject_1, templateObject_2;
