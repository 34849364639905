var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material";
import * as React from "react";
import btcSvg from "@/shared/img/tokens/btc.svg";
import ethSvg from "@/shared/img/tokens/eth.svg";
import usdcSvg from "@/shared/img/tokens/usdc.svg";
import usdtSvg from "@/shared/img/tokens/usdt.svg";
export var TokenIcon = function (_a) {
    var symbol = _a.symbol, _b = _a.size, size = _b === void 0 ? 20 : _b, iconUrl = _a.iconUrl;
    var url = iconUrl !== null && iconUrl !== void 0 ? iconUrl : (function () {
        switch (symbol === null || symbol === void 0 ? void 0 : symbol.toLowerCase()) {
            case "eth":
                return ethSvg;
            case "btc":
                return btcSvg;
            case "usdt":
                return usdtSvg;
            case "usdc":
                return usdcSvg;
            default:
                return null;
        }
    })();
    return (React.createElement(Img, { size: size, url: url }));
};
var Img = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: ", "px;\n    height: ", "px;\n    border-radius: 50%;\n    background-image: ", ";\n    background-size: cover;\n    background-color: ", ";\n    background-repeat: no-repeat;\n"], ["\n    width: ", "px;\n    height: ", "px;\n    border-radius: 50%;\n    background-image: ", ";\n    background-size: cover;\n    background-color: ", ";\n    background-repeat: no-repeat;\n"])), function (props) { return props.size; }, function (props) { return props.size; }, function (props) { return props.url ? "url(".concat(props.url, ")") : undefined; }, function (props) { return props.theme.colors["black-100"]; });
var templateObject_1;
