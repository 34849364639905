import React from "react";
import { TransactionAmount } from "../TransactionAmount";
import { useGetBalanceLogReceipt } from "@/api/wallet/useGetBalanceLogReceipt";
import { Box, Typography } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
import { DATE_FORMAT, formatDate } from "@/shared/lib";
import { sliceAddress } from "@/shared/utils/slice-address";
import { TransactionInfoData } from "@/widgets/Transactions/components/TransactionInfo/Data";
export var DepositInfo = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var transaction = _a.transaction;
    var _h = useGetBalanceLogReceipt((transaction === null || transaction === void 0 ? void 0 : transaction.id) || ""), data = _h.data, isLoading = _h.isLoading;
    if (!transaction)
        return null;
    var isCrypto = ((_b = data === null || data === void 0 ? void 0 : data.receipt) === null || _b === void 0 ? void 0 : _b.rail) === "CRYPTO";
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", padding: "30px", bgColor: "black-50", borderRadius: "22px 22px 0 0" },
            React.createElement(Typography, { color: "black-400", type: "text14", mb: "42px" }, (data === null || data === void 0 ? void 0 : data.createdAt) ? formatDate(data.createdAt, DATE_FORMAT.date_with_time) : "\u200b"),
            React.createElement(Box, { mb: "30px", display: "flex", flexDirection: "column", gap: "5px", alignItems: "center" },
                React.createElement(Typography, { type: "text30", textAlign: "center" }, isCrypto ? "Transfer from digital Wallet" : "Transfer from an external bank account"),
                !isCrypto && (React.createElement(Typography, { type: "text16" }, (data === null || data === void 0 ? void 0 : data.account) ? "To ".concat(sliceAddress(data.account)) : "\u200b"))),
            React.createElement(TransactionAmount
            // status={transaction.status}
            , { 
                // status={transaction.status}
                amount: transaction.change, currency: transaction.currency, text: "text20" })),
        React.createElement(Box, { width: "100%" }, isLoading
            ? (React.createElement(Box, { display: "flex", width: "100%", height: "226px", alignItems: "center", justifyContent: "center" },
                React.createElement(Loader, { size: 32 })))
            : (React.createElement(TransactionInfoData, { data: isCrypto
                    ? [
                        ["Tx ID", data === null || data === void 0 ? void 0 : data.id],
                        ["From wallet", (_c = data === null || data === void 0 ? void 0 : data.receipt) === null || _c === void 0 ? void 0 : _c.originator_blockchain_address],
                        ["Network", (_d = data === null || data === void 0 ? void 0 : data.receipt) === null || _d === void 0 ? void 0 : _d.originator_blockchain_network],
                        ["Hash", (_e = data === null || data === void 0 ? void 0 : data.receipt) === null || _e === void 0 ? void 0 : _e.rail_reference],
                    ]
                    : [
                        ["Tx ID", data === null || data === void 0 ? void 0 : data.id],
                        ["To your bank name", (_f = data === null || data === void 0 ? void 0 : data.receipt) === null || _f === void 0 ? void 0 : _f.originator_name],
                        ["Bank account number", (_g = data === null || data === void 0 ? void 0 : data.receipt) === null || _g === void 0 ? void 0 : _g.originator_account_number],
                        ["To your bank account", data === null || data === void 0 ? void 0 : data.account],
                    ] })))));
};
