import React from "react";
import { Card, WrapperStatus } from "./styled";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { useMedia } from "@/shared/hooks/useMedia";
export var RecipientItem = function (_a) {
    var onClick = _a.onClick, id = _a.id, account = _a.account, status = _a.status, name = _a.name;
    var media = useMedia();
    var shortName = name
        .split(" ")
        .map(function (item) { return item[0]; })
        .join("");
    return (React.createElement(Card, { position: "relative", padding: media.phone ? "15px 0" : "20px 30px", borderStyle: "solid", borderWidth: media.phone ? 0 : 1, borderColor: "black-50", borderRadius: "22px", display: "flex", gap: "15px", alignItems: "center", tabIndex: 0, role: "button", onClick: function () { return id && onClick(id); } },
        React.createElement(WrapperStatus, null, status),
        React.createElement(Box, { borderRadius: "100%", bgColor: "deep-blue-500-transparent-5", display: "flex", alignItems: "center", justifyContent: "center", width: "45px", height: "45px", flexShrink: 0 },
            React.createElement(Typography, { color: "deep-blue-500", textTransform: "uppercase" }, shortName)),
        React.createElement(Box, { display: "flex", flexDirection: "column", width: "100%" },
            React.createElement(Typography, null, name),
            React.createElement(Typography, { color: "black-400", type: "text14" }, account)),
        React.createElement(BaseIcon, { icon: "Navigation", color: "black-1200", size: 24 })));
};
