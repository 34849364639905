var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ClickAwayListener, FormControl } from "@mui/base";
import { InputAdornment, Popper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { RichTreeView, TreeItem, treeItemClasses } from "@mui/x-tree-view";
import React, { useEffect, useId, useMemo, useRef, useState } from "react";
import { BaseIcon } from "../BaseIcon";
import { FormControlInput } from "../FormControlInput";
function extractChildrenRecursive(data) {
    var childrenObj = {};
    function traverse(node) {
        if (node.children && node.children.length > 0) {
            node.children.forEach(function (child) {
                traverse(child);
            });
        }
        else {
            childrenObj[node.id] = node.label;
        }
    }
    data.forEach(function (item) {
        traverse(item);
    });
    return childrenObj;
}
var changeLabel = function (opts) {
    return opts.map(function (item) { return ({
        id: item.id,
        label: "".concat(item.id, " \u2014 ").concat(item.label),
        children: item.children ? changeLabel(item.children) : [],
    }); });
};
export var FormControlTreeSelect = function (_a) {
    var options = _a.options, value = _a.value, onSelectTree = _a.onSelectTree, props = __rest(_a, ["options", "value", "onSelectTree"]);
    var opt = useMemo(function () { return changeLabel(options); }, [options]);
    var mapValues = useMemo(function () { return extractChildrenRecursive(options); }, [options]);
    var mapOptValues = useMemo(function () { return extractChildrenRecursive(opt); }, [opt]);
    var values = useMemo(function () {
        return Object.entries(mapOptValues).map(function (_a) {
            var key = _a[0], value = _a[1];
            return ({
                id: key,
                label: value,
            });
        });
    }, [mapOptValues]);
    var _b = React.useState([]), expandedItems = _b[0], setExpandedItems = _b[1];
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState(""), query = _d[0], setQuery = _d[1];
    var mapValue = value ? mapOptValues[value] : "";
    var filteredValues = useMemo(function () {
        return query
            ? values.filter(function (item) { return item.label.toLowerCase().includes(query.toLowerCase()); })
            : undefined;
    }, [query, values]);
    var id = useId();
    var anchorRef = useRef(null);
    var handleHide = function () {
        setOpen(false);
    };
    var handleExpandedItemsChange = function (_, itemIds) {
        setExpandedItems(itemIds);
    };
    useEffect(function () {
        var _a;
        if (open && value && anchorRef.current) {
            var body = (_a = document.getElementById(id)) === null || _a === void 0 ? void 0 : _a.parentElement;
            if (!body)
                return;
            var selectedElement = document.getElementById("".concat(id, "-").concat(value));
            if (!selectedElement) {
                return;
            }
            var elementRect = selectedElement.getBoundingClientRect();
            var listRect = body.getBoundingClientRect();
            var y = body.scrollTop
                + elementRect.top
                - listRect.top
                - listRect.height / 2
                + elementRect.height / 2;
            body.scrollTo({ top: y });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);
    useEffect(function () {
        setQuery(mapValue);
    }, [mapValue]);
    return (React.createElement(ClickAwayListener, { onClickAway: handleHide, mouseEvent: "onMouseDown" },
        React.createElement(FormControl, { ref: anchorRef, style: { position: "relative" } },
            React.createElement(FormControlInput, __assign({}, props, { autoComplete: "off", focused: open, 
                // value={mapValues[value as string] || ""}
                value: query, onChange: function (e) { return setQuery(e.currentTarget.value); }, onClick: function () { return setOpen(function (p) { return !p; }); }, InputProps: {
                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(CustomBaseIcon, { "$isOpen": open, icon: "DropDown" }))),
                } })),
            React.createElement(Popper, { style: {
                    width: "100%",
                    background: "white",
                    borderRadius: "12px",
                    maxHeight: "300px",
                    zIndex: 9999,
                    boxShadow: "0px 4px 20px 0px #00000014",
                    overflow: "auto",
                    marginTop: "8px",
                }, open: open, anchorEl: anchorRef.current, role: undefined, disablePortal: true },
                React.createElement(RichTreeView, { id: id, items: mapValue === query ? opt : filteredValues !== null && filteredValues !== void 0 ? filteredValues : opt, expandedItems: expandedItems, onExpandedItemsChange: handleExpandedItemsChange, selectedItems: value || undefined, onSelectedItemsChange: function (_, v) {
                        if (v && mapValues[v]) {
                            onSelectTree === null || onSelectTree === void 0 ? void 0 : onSelectTree(v, mapValues[v]);
                            setQuery(mapOptValues[v]);
                            setOpen(false);
                        }
                    }, slots: {
                        item: CustomTreeItem,
                    } })))));
};
var CustomBaseIcon = styled(BaseIcon)(function (_a) {
    var $isOpen = _a.$isOpen;
    return ({
        transition: "unset",
        transform: $isOpen ? "rotate(180deg)" : "unset",
    });
});
var CustomTreeItem = styled(TreeItem)(function (_a) {
    var _b, _c;
    var theme = _a.theme;
    return (_b = {},
        _b["& .".concat(treeItemClasses.content)] = {
            width: "100%",
            display: "flex",
            gap: "12px",
            alignItems: "center",
            padding: "15px 20px",
            boxSizing: "border-box",
            transition: "background 0.2s ease",
            ".MuiTreeItem-label": {
                color: theme.colors["black-600"],
            },
            "&.Mui-selected, &.Mui-focused, &.Mui-focused.Mui-selected": {
                backgroundColor: "transparent",
            },
            "&.Mui-selected .MuiTreeItem-label": {
                color: theme.colors["black-1200"],
            },
            "&:hover .MuiTreeItem-label": {
                color: theme.colors["black-1200"],
            },
        },
        _b["& .".concat(treeItemClasses.content, ":hover")] = {
            backgroundColor: "transparent",
            cursor: "pointer",
        },
        _b["& .".concat(treeItemClasses.root, ":not([aria-expanded=false]):not([aria-expanded=true])")] = (_c = {},
            _c["& .".concat(treeItemClasses.content, ":hover")] = {
                backgroundColor: theme.colors["black-50"],
                cursor: "pointer",
                ".MuiTreeItem-label": {
                    color: theme.colors["deep-blue-500"],
                },
            },
            _c),
        _b);
});
