var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { borders as MUBorders, compose, display, flexbox, grid, positions, sizing, spacing, } from "@mui/system";
import classNames from "classnames";
import React, { memo } from "react";
import { BaseIcon } from "../BaseIcon";
import { Box } from "../Box";
import { Typography } from "../Typography";
export var ImplAlert = function (_a) {
    var text = _a.text, className = _a.className, props = __rest(_a, ["text", "className"]);
    return (React.createElement(Box, __assign({ bgColor: "black-alert", borderRadius: "12px", padding: "20px", display: "flex", alignItems: "center", gap: "15px", boxSizing: "border-box", className: classNames(className, "Alert") }, props),
        React.createElement(BaseIcon, { icon: "AlertClose", size: 28, color: "general-red" }),
        React.createElement(Typography, { type: "text12", color: "general-white", textAlign: "left", wordBreak: "break-word" }, text)));
};
export var FormAlert = memo(styled(ImplAlert, {
    shouldForwardProp: function (prop) {
        return !compose(spacing, sizing, flexbox, grid, display, positions)
            .filterProps.includes(prop);
    },
})(compose(spacing, sizing, flexbox, grid, display, MUBorders, positions)));
