import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import { Tabs as BaseTabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { styled } from "@mui/material/styles";
export var Tab = styled(BaseTab)(function (_a) {
    var theme = _a.theme;
    return "\n  background-color: ".concat(theme.colors["deep-blue-500-transparent-5"], ";\n  color: ").concat(theme.colors["deep-blue-500"], ";\n  cursor: pointer;\n  font-size: ").concat(theme.text.text14.fontSize, ";\n  line-height: ").concat(theme.text.text14.lineHeight, ";\n  font-weight: 500;\n  width: 100%;\n  height: 36px;\n  border: none;\n  border-radius: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: background-color 0.3s ease;\n  min-width: 130px;\n\n  &:hover:not(.").concat(buttonClasses.disabled, "):not(.").concat(tabClasses.selected, ") {\n    background-color: ").concat(theme.colors["deep-blue-500-transparent-10"], ";\n  }\n\n  &:hover:not(.").concat(buttonClasses.disabled, ").").concat(tabClasses.selected, ") {\n    background-color: #0017E5;\n  }\n\n\n  &.").concat(tabClasses.selected, " {\n    background-color: ").concat(theme.colors["deep-blue-500"], ";\n    color: ").concat(theme.colors["general-white"], ";\n  }\n\n  &.").concat(buttonClasses.disabled, " {\n    background-color: ").concat(theme.colors["black-100"], ";\n    color: ").concat(theme.colors["black-500"], ";\n  }\n");
});
export var TabsList = styled(BaseTabsList)(function () { return "\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 10px;\n  "; });
export var Tabs = styled(BaseTabs)(function () { return "\n  display: flex;\n  "; });
