var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from "react";
import { BaseIcon } from "../BaseIcon";
import { StyledCheckbox } from "./styled";
var ImplCheckbox = React.forwardRef(function (_a, ref) {
    var isHover = _a.isHover, _b = _a.size, size = _b === void 0 ? "small" : _b, props = __rest(_a, ["isHover", "size"]);
    return (React.createElement(StyledCheckbox, __assign({ ref: ref, "$isHover": isHover }, props, { size: size, disableRipple: true, disableFocusRipple: true, disableTouchRipple: true, checkedIcon: React.createElement(BaseIcon, { icon: "Checked", size: size === "small" ? 15 : 23 }), icon: React.createElement(BaseIcon, { icon: "UnChecked", size: size === "small" ? 15 : 20 }) })));
});
export var Checkbox = memo(ImplCheckbox);
