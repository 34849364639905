import { useMemo } from "react";
export function useCsv(headers, data) {
    return useMemo(function () {
        var result = "".concat(headers.map(function (item) { return "\"".concat(item, "\""); }).join(";"), "\n");
        data.forEach(function (items) {
            for (var i = 0; i < items.length; i += 1) {
                result += i !== items.length - 1 ? "".concat(items[i], ";") : "".concat(items[i], ";\n");
            }
        });
        return result;
    }, [headers, data]);
}
