import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "@/routes";
export var useBack = function () {
    var navigate = useNavigate();
    return function () {
        var _a, _b;
        if (((_b = (_a = window === null || window === void 0 ? void 0 : window.history) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.idx) === 0) {
            navigate(generatePath(ROUTES.root));
        }
        else {
            navigate(-1);
        }
    };
};
