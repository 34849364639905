import React from "react";
import { InformationTitle } from "../InformationTitle";
import { UploadDocuments } from "../UploadDocuments";
import { useGetCompanyInfo, useGetVerificationInfo } from "@/api/registration";
import { BaseIcon, Box, Button, FormControlInput, Typography } from "@/shared/components";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { countries } from "@/shared/constants/countries";
import { KYBEntityTypes } from "@/shared/constants/dict";
import { useMedia } from "@/shared/hooks/useMedia";
import { humanFileSize } from "@/shared/utils/file-size";
export var CompanyInformation = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var companyInfo = useGetCompanyInfo().data;
    var verificationInfo = useGetVerificationInfo().data;
    var media = useMedia();
    if (!companyInfo)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { bgColor: "general-white", borderRadius: "22px", padding: media.phone ? "30px" : "60px", gap: media.phone ? "30px" : "60px", display: "flex", flexDirection: "column" },
            React.createElement(InformationTitle, { title: companyInfo === null || companyInfo === void 0 ? void 0 : companyInfo.companyName }),
            React.createElement(Box, { display: "grid", gridTemplateColumns: media.phone ? "1fr" : "1fr 1fr", maxWidth: "780px", gap: "40px" },
                React.createElement(FormControlInput, { disabled: true, label: "Business full legal entity name", value: companyInfo.companyName }),
                React.createElement(FormControlSelect, { disabled: true, value: (_a = companyInfo.meta) === null || _a === void 0 ? void 0 : _a.entityType, options: KYBEntityTypes, label: "Legal structure" }),
                React.createElement(Box, { gridColumn: "1/-1" },
                    React.createElement(FormControlInput, { disabled: true, label: "NAICS", value: (_b = companyInfo.meta) === null || _b === void 0 ? void 0 : _b.naics })),
                React.createElement(Box, { gridColumn: "1/-1" },
                    React.createElement(FormControlInput, { disabled: true, label: "NAICS description", value: (_c = companyInfo.meta) === null || _c === void 0 ? void 0 : _c.naicsDescription })),
                React.createElement(Box, { gridColumn: "1/-1" },
                    React.createElement(FormControlInput, { disabled: true, label: "Description of business nature", value: (_d = companyInfo.meta) === null || _d === void 0 ? void 0 : _d.descriptionOfBusinessNature })),
                React.createElement(FormControlInput, { disabled: true, value: companyInfo.registrationNumber, label: "Registered Number" }),
                React.createElement(FormControlSelect, { disabled: true, value: (_e = companyInfo.meta) === null || _e === void 0 ? void 0 : _e.registeredCountryCode, options: countries.map(function (item) { return ({
                        key: item.codeAlpha2,
                        label: "".concat(item.flag, " ").concat(item.name),
                    }); }), label: "Registered Country" }),
                React.createElement(FormControlInput, { value: (_f = companyInfo.meta) === null || _f === void 0 ? void 0 : _f.registeredState, disabled: true, label: "Registered State/Province" }),
                React.createElement(FormControlInput, { value: (_g = companyInfo.meta) === null || _g === void 0 ? void 0 : _g.registeredCity, disabled: true, label: "Registered City" }),
                React.createElement(FormControlInput, { value: (_h = companyInfo.meta) === null || _h === void 0 ? void 0 : _h.registeredStreetAddress, disabled: true, label: "Registered Street Address" }),
                React.createElement(FormControlInput, { value: (_j = companyInfo.meta) === null || _j === void 0 ? void 0 : _j.registeredZipCode, disabled: true, label: "Registered Zip Code/Postal Code" }),
                React.createElement(FormControlInput, { value: (_k = companyInfo.meta) === null || _k === void 0 ? void 0 : _k.companyPhoneNumber, disabled: true, label: "Phone number" }),
                React.createElement(FormControlInput, { value: (_l = companyInfo.meta) === null || _l === void 0 ? void 0 : _l.companyEmail, disabled: true, label: "Email" }),
                React.createElement(FormControlInput, { value: (_m = companyInfo.meta) === null || _m === void 0 ? void 0 : _m.establishedOn, disabled: true, label: "Established on" }),
                React.createElement(FormControlInput, { value: (_o = companyInfo.meta) === null || _o === void 0 ? void 0 : _o.stateOfIncorporation, disabled: true, label: "State of Incorporation" })),
            React.createElement(Box, { height: "1px", width: "100%", bgColor: "black-100" }),
            (verificationInfo === null || verificationInfo === void 0 ? void 0 : verificationInfo.docs) && verificationInfo.docs.length > 0 && (React.createElement(React.Fragment, null,
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                    React.createElement(Typography, { type: "text24", fontWeight: 500, variant: "body1" }, "Company documents"),
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px" }, (_p = verificationInfo === null || verificationInfo === void 0 ? void 0 : verificationInfo.docs) === null || _p === void 0 ? void 0 : _p.map(function (_a) {
                        var filename = _a.filename, size = _a.size, id = _a.id;
                        return (React.createElement("div", { key: id, style: {
                                overflowAnchor: "none",
                            } },
                            React.createElement(Box, { padding: "25px 30px", display: "flex", justifyContent: "space-between", alignItems: "center", bgColor: "black-50", borderRadius: "16px" },
                                React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" },
                                    React.createElement(BaseIcon, { icon: "Doc", color: "black-1200" }),
                                    " ",
                                    React.createElement(Typography, null, filename)),
                                React.createElement(Typography, { padding: "4px 10px", bgColor: "general-white", borderRadius: "100px", color: "black-500", type: "text14", fontWeight: "500" }, humanFileSize(size || 0, true)))));
                    }))),
                React.createElement(Box, { height: "1px", width: "100%", bgColor: "black-100" }))),
            React.createElement(Box, { display: "flex", flexDirection: media.phone ? "column" : undefined, alignItems: "center", justifyContent: "space-between", gap: "30px", maxWidth: "780px" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" },
                    React.createElement(Typography, { type: "text24", fontWeight: 500 }, "Change personal details"),
                    React.createElement(Typography, { color: "black-500" }, "After the verification process, the personal data in your profile isn\u2019t normally changeable. If you absolutely need to change it, please contact our support")),
                React.createElement(Button, { width: media.phone ? "100%" : undefined, size: "s" }, "Support"))),
        React.createElement(UploadDocuments, null)));
};
