import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { SignupPageStore } from "@/pages/SignupPage/stores/SignupPageStore";
import { FormControlInput, Typography } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { Input } from "@/shared/components/Input";
import { ResendCodeBtn } from "@/shared/components/ResendCodeBtn";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
export var EmailCodeForm = observer(function () {
    var _a;
    var page = useStoreContext(SignupPageStore);
    return (React.createElement(AuthForm, { onBack: page.resetEmail, onSubmit: page.submitEmailCode, title: "Confirm your email", desc: React.createElement(React.Fragment, null,
            "Enter the code we've sent you at ",
            React.createElement(Typography, { color: "deep-blue-500" }, page.email),
            ". Please enter it below."), content: React.createElement(React.Fragment, null,
            React.createElement(Input, { disabled: true, label: "Email", type: "text", value: page.email }),
            React.createElement(FormControlInput, { label: "Verification code", type: "text", value: page.emailCode, onChange: onChange(action(function (value) {
                    page.emailCode = value;
                })), disabled: page.loading, variantError: "alert", error: (_a = page.errors[0]) === null || _a === void 0 ? void 0 : _a.description, InputProps: {
                    endAdornment: (React.createElement(ResendCodeBtn, { loading: page.loading, onSubmit: page.submitEmail, count: page.timer.count })),
                } })), footer: React.createElement(Button, { type: "submit", width: "100%", disabled: !page.isEmailCodeValid || page.loading }, "Continue") }));
});
