/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/** country code for invoice billing */
export var CountryCode;
(function (CountryCode) {
    CountryCode["AF"] = "AF";
    CountryCode["AX"] = "AX";
    CountryCode["AL"] = "AL";
    CountryCode["DZ"] = "DZ";
    CountryCode["AS"] = "AS";
    CountryCode["AD"] = "AD";
    CountryCode["AO"] = "AO";
    CountryCode["AI"] = "AI";
    CountryCode["AG"] = "AG";
    CountryCode["AR"] = "AR";
    CountryCode["AM"] = "AM";
    CountryCode["AW"] = "AW";
    CountryCode["AC"] = "AC";
    CountryCode["AU"] = "AU";
    CountryCode["AQ"] = "AQ";
    CountryCode["AT"] = "AT";
    CountryCode["AZ"] = "AZ";
    CountryCode["BS"] = "BS";
    CountryCode["BH"] = "BH";
    CountryCode["BD"] = "BD";
    CountryCode["BB"] = "BB";
    CountryCode["BY"] = "BY";
    CountryCode["BE"] = "BE";
    CountryCode["BZ"] = "BZ";
    CountryCode["BJ"] = "BJ";
    CountryCode["BM"] = "BM";
    CountryCode["BT"] = "BT";
    CountryCode["BO"] = "BO";
    CountryCode["BQ"] = "BQ";
    CountryCode["BA"] = "BA";
    CountryCode["BW"] = "BW";
    CountryCode["BV"] = "BV";
    CountryCode["BR"] = "BR";
    CountryCode["IO"] = "IO";
    CountryCode["VG"] = "VG";
    CountryCode["BN"] = "BN";
    CountryCode["BG"] = "BG";
    CountryCode["BF"] = "BF";
    CountryCode["BI"] = "BI";
    CountryCode["KH"] = "KH";
    CountryCode["CM"] = "CM";
    CountryCode["CA"] = "CA";
    CountryCode["CV"] = "CV";
    CountryCode["KY"] = "KY";
    CountryCode["CF"] = "CF";
    CountryCode["TD"] = "TD";
    CountryCode["CL"] = "CL";
    CountryCode["CN"] = "CN";
    CountryCode["TW"] = "TW";
    CountryCode["CX"] = "CX";
    CountryCode["PF"] = "PF";
    CountryCode["CC"] = "CC";
    CountryCode["CO"] = "CO";
    CountryCode["KM"] = "KM";
    CountryCode["CG"] = "CG";
    CountryCode["CD"] = "CD";
    CountryCode["CK"] = "CK";
    CountryCode["CR"] = "CR";
    CountryCode["CI"] = "CI";
    CountryCode["HR"] = "HR";
    CountryCode["CU"] = "CU";
    CountryCode["CW"] = "CW";
    CountryCode["CY"] = "CY";
    CountryCode["CZ"] = "CZ";
    CountryCode["DK"] = "DK";
    CountryCode["DJ"] = "DJ";
    CountryCode["DM"] = "DM";
    CountryCode["DO"] = "DO";
    CountryCode["EC"] = "EC";
    CountryCode["EG"] = "EG";
    CountryCode["SV"] = "SV";
    CountryCode["GQ"] = "GQ";
    CountryCode["ER"] = "ER";
    CountryCode["EE"] = "EE";
    CountryCode["SZ"] = "SZ";
    CountryCode["ET"] = "ET";
    CountryCode["FK"] = "FK";
    CountryCode["FO"] = "FO";
    CountryCode["FJ"] = "FJ";
    CountryCode["FI"] = "FI";
    CountryCode["FR"] = "FR";
    CountryCode["GF"] = "GF";
    CountryCode["TF"] = "TF";
    CountryCode["GA"] = "GA";
    CountryCode["GM"] = "GM";
    CountryCode["GE"] = "GE";
    CountryCode["DE"] = "DE";
    CountryCode["GH"] = "GH";
    CountryCode["GI"] = "GI";
    CountryCode["GR"] = "GR";
    CountryCode["GL"] = "GL";
    CountryCode["GD"] = "GD";
    CountryCode["GP"] = "GP";
    CountryCode["GU"] = "GU";
    CountryCode["GT"] = "GT";
    CountryCode["GG"] = "GG";
    CountryCode["GN"] = "GN";
    CountryCode["GW"] = "GW";
    CountryCode["GY"] = "GY";
    CountryCode["HT"] = "HT";
    CountryCode["HM"] = "HM";
    CountryCode["HN"] = "HN";
    CountryCode["HK"] = "HK";
    CountryCode["HU"] = "HU";
    CountryCode["IS"] = "IS";
    CountryCode["IN"] = "IN";
    CountryCode["ID"] = "ID";
    CountryCode["IR"] = "IR";
    CountryCode["IQ"] = "IQ";
    CountryCode["IE"] = "IE";
    CountryCode["IM"] = "IM";
    CountryCode["IL"] = "IL";
    CountryCode["IT"] = "IT";
    CountryCode["JM"] = "JM";
    CountryCode["JP"] = "JP";
    CountryCode["JE"] = "JE";
    CountryCode["JO"] = "JO";
    CountryCode["KZ"] = "KZ";
    CountryCode["KE"] = "KE";
    CountryCode["KI"] = "KI";
    CountryCode["KP"] = "KP";
    CountryCode["KR"] = "KR";
    CountryCode["KW"] = "KW";
    CountryCode["KG"] = "KG";
    CountryCode["LA"] = "LA";
    CountryCode["LV"] = "LV";
    CountryCode["LB"] = "LB";
    CountryCode["LS"] = "LS";
    CountryCode["LR"] = "LR";
    CountryCode["LY"] = "LY";
    CountryCode["LI"] = "LI";
    CountryCode["LT"] = "LT";
    CountryCode["LU"] = "LU";
    CountryCode["MO"] = "MO";
    CountryCode["MK"] = "MK";
    CountryCode["MG"] = "MG";
    CountryCode["MW"] = "MW";
    CountryCode["MY"] = "MY";
    CountryCode["MV"] = "MV";
    CountryCode["ML"] = "ML";
    CountryCode["MT"] = "MT";
    CountryCode["MH"] = "MH";
    CountryCode["MQ"] = "MQ";
    CountryCode["MR"] = "MR";
    CountryCode["MU"] = "MU";
    CountryCode["YT"] = "YT";
    CountryCode["MX"] = "MX";
    CountryCode["FM"] = "FM";
    CountryCode["MD"] = "MD";
    CountryCode["MC"] = "MC";
    CountryCode["MN"] = "MN";
    CountryCode["ME"] = "ME";
    CountryCode["MS"] = "MS";
    CountryCode["MA"] = "MA";
    CountryCode["MZ"] = "MZ";
    CountryCode["MM"] = "MM";
    CountryCode["NA"] = "NA";
    CountryCode["NR"] = "NR";
    CountryCode["NP"] = "NP";
    CountryCode["NL"] = "NL";
    CountryCode["AN"] = "AN";
    CountryCode["NC"] = "NC";
    CountryCode["NZ"] = "NZ";
    CountryCode["NI"] = "NI";
    CountryCode["NE"] = "NE";
    CountryCode["NG"] = "NG";
    CountryCode["NU"] = "NU";
    CountryCode["NF"] = "NF";
    CountryCode["MP"] = "MP";
    CountryCode["NO"] = "NO";
    CountryCode["OM"] = "OM";
    CountryCode["PK"] = "PK";
    CountryCode["PW"] = "PW";
    CountryCode["PA"] = "PA";
    CountryCode["PG"] = "PG";
    CountryCode["PY"] = "PY";
    CountryCode["PE"] = "PE";
    CountryCode["PH"] = "PH";
    CountryCode["PN"] = "PN";
    CountryCode["PL"] = "PL";
    CountryCode["PT"] = "PT";
    CountryCode["PR"] = "PR";
    CountryCode["QA"] = "QA";
    CountryCode["RE"] = "RE";
    CountryCode["RO"] = "RO";
    CountryCode["RU"] = "RU";
    CountryCode["RW"] = "RW";
    CountryCode["BL"] = "BL";
    CountryCode["SH"] = "SH";
    CountryCode["KN"] = "KN";
    CountryCode["LC"] = "LC";
    CountryCode["MF"] = "MF";
    CountryCode["PM"] = "PM";
    CountryCode["VC"] = "VC";
    CountryCode["WS"] = "WS";
    CountryCode["SM"] = "SM";
    CountryCode["ST"] = "ST";
    CountryCode["SA"] = "SA";
    CountryCode["SN"] = "SN";
    CountryCode["RS"] = "RS";
    CountryCode["SC"] = "SC";
    CountryCode["SL"] = "SL";
    CountryCode["SG"] = "SG";
    CountryCode["SX"] = "SX";
    CountryCode["SK"] = "SK";
    CountryCode["SI"] = "SI";
    CountryCode["SB"] = "SB";
    CountryCode["SO"] = "SO";
    CountryCode["ZA"] = "ZA";
    CountryCode["GS"] = "GS";
    CountryCode["SS"] = "SS";
    CountryCode["ES"] = "ES";
    CountryCode["LK"] = "LK";
    CountryCode["SD"] = "SD";
    CountryCode["SR"] = "SR";
    CountryCode["SJ"] = "SJ";
    CountryCode["SE"] = "SE";
    CountryCode["CH"] = "CH";
    CountryCode["SY"] = "SY";
    CountryCode["TJ"] = "TJ";
    CountryCode["TZ"] = "TZ";
    CountryCode["TH"] = "TH";
    CountryCode["TL"] = "TL";
    CountryCode["TG"] = "TG";
    CountryCode["TK"] = "TK";
    CountryCode["TO"] = "TO";
    CountryCode["TT"] = "TT";
    CountryCode["TA"] = "TA";
    CountryCode["TN"] = "TN";
    CountryCode["TR"] = "TR";
    CountryCode["TM"] = "TM";
    CountryCode["TC"] = "TC";
    CountryCode["TV"] = "TV";
    CountryCode["VI"] = "VI";
    CountryCode["UG"] = "UG";
    CountryCode["UA"] = "UA";
    CountryCode["AE"] = "AE";
    CountryCode["GB"] = "GB";
    CountryCode["US"] = "US";
    CountryCode["UY"] = "UY";
    CountryCode["UZ"] = "UZ";
    CountryCode["VU"] = "VU";
    CountryCode["VA"] = "VA";
    CountryCode["VE"] = "VE";
    CountryCode["VN"] = "VN";
    CountryCode["UM"] = "UM";
    CountryCode["WF"] = "WF";
    CountryCode["EH"] = "EH";
    CountryCode["YE"] = "YE";
    CountryCode["ZM"] = "ZM";
    CountryCode["ZW"] = "ZW";
    CountryCode["PS"] = "PS";
    CountryCode["QZ"] = "QZ";
    CountryCode["XK"] = "XK";
})(CountryCode || (CountryCode = {}));
export var HttpStatus;
(function (HttpStatus) {
    HttpStatus["Value100CONTINUE"] = "100 CONTINUE";
    HttpStatus["Value101SWITCHINGPROTOCOLS"] = "101 SWITCHING_PROTOCOLS";
    HttpStatus["Value102PROCESSING"] = "102 PROCESSING";
    HttpStatus["Value103EARLYHINTS"] = "103 EARLY_HINTS";
    HttpStatus["Value103CHECKPOINT"] = "103 CHECKPOINT";
    HttpStatus["Value200OK"] = "200 OK";
    HttpStatus["Value201CREATED"] = "201 CREATED";
    HttpStatus["Value202ACCEPTED"] = "202 ACCEPTED";
    HttpStatus["Value203NONAUTHORITATIVEINFORMATION"] = "203 NON_AUTHORITATIVE_INFORMATION";
    HttpStatus["Value204NOCONTENT"] = "204 NO_CONTENT";
    HttpStatus["Value205RESETCONTENT"] = "205 RESET_CONTENT";
    HttpStatus["Value206PARTIALCONTENT"] = "206 PARTIAL_CONTENT";
    HttpStatus["Value207MULTISTATUS"] = "207 MULTI_STATUS";
    HttpStatus["Value208ALREADYREPORTED"] = "208 ALREADY_REPORTED";
    HttpStatus["Value226IMUSED"] = "226 IM_USED";
    HttpStatus["Value300MULTIPLECHOICES"] = "300 MULTIPLE_CHOICES";
    HttpStatus["Value301MOVEDPERMANENTLY"] = "301 MOVED_PERMANENTLY";
    HttpStatus["Value302FOUND"] = "302 FOUND";
    HttpStatus["Value302MOVEDTEMPORARILY"] = "302 MOVED_TEMPORARILY";
    HttpStatus["Value303SEEOTHER"] = "303 SEE_OTHER";
    HttpStatus["Value304NOTMODIFIED"] = "304 NOT_MODIFIED";
    HttpStatus["Value305USEPROXY"] = "305 USE_PROXY";
    HttpStatus["Value307TEMPORARYREDIRECT"] = "307 TEMPORARY_REDIRECT";
    HttpStatus["Value308PERMANENTREDIRECT"] = "308 PERMANENT_REDIRECT";
    HttpStatus["Value400BADREQUEST"] = "400 BAD_REQUEST";
    HttpStatus["Value401UNAUTHORIZED"] = "401 UNAUTHORIZED";
    HttpStatus["Value402PAYMENTREQUIRED"] = "402 PAYMENT_REQUIRED";
    HttpStatus["Value403FORBIDDEN"] = "403 FORBIDDEN";
    HttpStatus["Value404NOTFOUND"] = "404 NOT_FOUND";
    HttpStatus["Value405METHODNOTALLOWED"] = "405 METHOD_NOT_ALLOWED";
    HttpStatus["Value406NOTACCEPTABLE"] = "406 NOT_ACCEPTABLE";
    HttpStatus["Value407PROXYAUTHENTICATIONREQUIRED"] = "407 PROXY_AUTHENTICATION_REQUIRED";
    HttpStatus["Value408REQUESTTIMEOUT"] = "408 REQUEST_TIMEOUT";
    HttpStatus["Value409CONFLICT"] = "409 CONFLICT";
    HttpStatus["Value410GONE"] = "410 GONE";
    HttpStatus["Value411LENGTHREQUIRED"] = "411 LENGTH_REQUIRED";
    HttpStatus["Value412PRECONDITIONFAILED"] = "412 PRECONDITION_FAILED";
    HttpStatus["Value413PAYLOADTOOLARGE"] = "413 PAYLOAD_TOO_LARGE";
    HttpStatus["Value413REQUESTENTITYTOOLARGE"] = "413 REQUEST_ENTITY_TOO_LARGE";
    HttpStatus["Value414URITOOLONG"] = "414 URI_TOO_LONG";
    HttpStatus["Value414REQUESTURITOOLONG"] = "414 REQUEST_URI_TOO_LONG";
    HttpStatus["Value415UNSUPPORTEDMEDIATYPE"] = "415 UNSUPPORTED_MEDIA_TYPE";
    HttpStatus["Value416REQUESTEDRANGENOTSATISFIABLE"] = "416 REQUESTED_RANGE_NOT_SATISFIABLE";
    HttpStatus["Value417EXPECTATIONFAILED"] = "417 EXPECTATION_FAILED";
    HttpStatus["Value418IAMATEAPOT"] = "418 I_AM_A_TEAPOT";
    HttpStatus["Value419INSUFFICIENTSPACEONRESOURCE"] = "419 INSUFFICIENT_SPACE_ON_RESOURCE";
    HttpStatus["Value420METHODFAILURE"] = "420 METHOD_FAILURE";
    HttpStatus["Value421DESTINATIONLOCKED"] = "421 DESTINATION_LOCKED";
    HttpStatus["Value422UNPROCESSABLEENTITY"] = "422 UNPROCESSABLE_ENTITY";
    HttpStatus["Value423LOCKED"] = "423 LOCKED";
    HttpStatus["Value424FAILEDDEPENDENCY"] = "424 FAILED_DEPENDENCY";
    HttpStatus["Value425TOOEARLY"] = "425 TOO_EARLY";
    HttpStatus["Value426UPGRADEREQUIRED"] = "426 UPGRADE_REQUIRED";
    HttpStatus["Value428PRECONDITIONREQUIRED"] = "428 PRECONDITION_REQUIRED";
    HttpStatus["Value429TOOMANYREQUESTS"] = "429 TOO_MANY_REQUESTS";
    HttpStatus["Value431REQUESTHEADERFIELDSTOOLARGE"] = "431 REQUEST_HEADER_FIELDS_TOO_LARGE";
    HttpStatus["Value451UNAVAILABLEFORLEGALREASONS"] = "451 UNAVAILABLE_FOR_LEGAL_REASONS";
    HttpStatus["Value500INTERNALSERVERERROR"] = "500 INTERNAL_SERVER_ERROR";
    HttpStatus["Value501NOTIMPLEMENTED"] = "501 NOT_IMPLEMENTED";
    HttpStatus["Value502BADGATEWAY"] = "502 BAD_GATEWAY";
    HttpStatus["Value503SERVICEUNAVAILABLE"] = "503 SERVICE_UNAVAILABLE";
    HttpStatus["Value504GATEWAYTIMEOUT"] = "504 GATEWAY_TIMEOUT";
    HttpStatus["Value505HTTPVERSIONNOTSUPPORTED"] = "505 HTTP_VERSION_NOT_SUPPORTED";
    HttpStatus["Value506VARIANTALSONEGOTIATES"] = "506 VARIANT_ALSO_NEGOTIATES";
    HttpStatus["Value507INSUFFICIENTSTORAGE"] = "507 INSUFFICIENT_STORAGE";
    HttpStatus["Value508LOOPDETECTED"] = "508 LOOP_DETECTED";
    HttpStatus["Value509BANDWIDTHLIMITEXCEEDED"] = "509 BANDWIDTH_LIMIT_EXCEEDED";
    HttpStatus["Value510NOTEXTENDED"] = "510 NOT_EXTENDED";
    HttpStatus["Value511NETWORKAUTHENTICATIONREQUIRED"] = "511 NETWORK_AUTHENTICATION_REQUIRED";
})(HttpStatus || (HttpStatus = {}));
export var InvoiceCallbackStatus;
(function (InvoiceCallbackStatus) {
    InvoiceCallbackStatus["SUCCESS"] = "SUCCESS";
    InvoiceCallbackStatus["FAILED"] = "FAILED";
})(InvoiceCallbackStatus || (InvoiceCallbackStatus = {}));
/** Invoice payment type */
export var InvoicePaymentType;
(function (InvoicePaymentType) {
    InvoicePaymentType["INTERNAL"] = "INTERNAL";
    InvoicePaymentType["CHECKOUT"] = "CHECKOUT";
    InvoicePaymentType["EXTERNAL"] = "EXTERNAL";
})(InvoicePaymentType || (InvoicePaymentType = {}));
/** Status of current invoice different for sub types */
export var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus["INIT"] = "INIT";
    InvoiceStatus["PENDING"] = "PENDING";
    InvoiceStatus["PROCESSING"] = "PROCESSING";
    InvoiceStatus["COMPLETED"] = "COMPLETED";
    InvoiceStatus["CANCELED"] = "CANCELED";
    InvoiceStatus["FAILED"] = "FAILED";
    InvoiceStatus["REJECTED"] = "REJECTED";
})(InvoiceStatus || (InvoiceStatus = {}));
export var InvoiceType;
(function (InvoiceType) {
    InvoiceType["ORIGINAL"] = "ORIGINAL";
    InvoiceType["RECURRENT"] = "RECURRENT";
})(InvoiceType || (InvoiceType = {}));
/** Stripe payment method type */
export var PaymentMethodType;
(function (PaymentMethodType) {
    PaymentMethodType["CARD"] = "CARD";
})(PaymentMethodType || (PaymentMethodType = {}));
/** payment status */
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["INIT"] = "INIT";
    PaymentStatus["PROCESSING"] = "PROCESSING";
    PaymentStatus["COMPLETED"] = "COMPLETED";
    PaymentStatus["COMPLETED_WITHOUT_FEE"] = "COMPLETED_WITHOUT_FEE";
    PaymentStatus["FAILED"] = "FAILED";
})(PaymentStatus || (PaymentStatus = {}));
/** interval of recurrent invoice */
export var RecurrentInvoiceInterval;
(function (RecurrentInvoiceInterval) {
    RecurrentInvoiceInterval["DAYS30"] = "DAYS30";
    RecurrentInvoiceInterval["DAYS365"] = "DAYS365";
})(RecurrentInvoiceInterval || (RecurrentInvoiceInterval = {}));
/** status of recurrent invoice */
export var RecurrentInvoiceStatus;
(function (RecurrentInvoiceStatus) {
    RecurrentInvoiceStatus["INIT"] = "INIT";
    RecurrentInvoiceStatus["TRIAL"] = "TRIAL";
    RecurrentInvoiceStatus["ACTIVE"] = "ACTIVE";
    RecurrentInvoiceStatus["PAYMENT_FAILED"] = "PAYMENT_FAILED";
    RecurrentInvoiceStatus["PAYMENT_REJECTED"] = "PAYMENT_REJECTED";
    RecurrentInvoiceStatus["CANCELLED"] = "CANCELLED";
    RecurrentInvoiceStatus["DEACTIVATING"] = "DEACTIVATING";
})(RecurrentInvoiceStatus || (RecurrentInvoiceStatus = {}));
import axios from "axios";
export var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
    ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
var HttpClient = /** @class */ (function () {
    function HttpClient(_a) {
        if (_a === void 0) { _a = {}; }
        var _this = this;
        var securityWorker = _a.securityWorker, secure = _a.secure, format = _a.format, axiosConfig = __rest(_a, ["securityWorker", "secure", "format"]);
        this.securityData = null;
        this.setSecurityData = function (data) {
            _this.securityData = data;
        };
        this.request = function (_a) { return __awaiter(_this, void 0, Promise, function () {
            var secureParams, _b, requestParams, responseFormat;
            var secure = _a.secure, path = _a.path, type = _a.type, query = _a.query, format = _a.format, body = _a.body, params = __rest(_a, ["secure", "path", "type", "query", "format", "body"]);
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (typeof secure === "boolean" ? secure : this.secure)
                            && this.securityWorker;
                        if (!_b) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.securityWorker(this.securityData)];
                    case 1:
                        _b = (_c.sent());
                        _c.label = 2;
                    case 2:
                        secureParams = (_b)
                            || {};
                        requestParams = this.mergeRequestParams(params, secureParams);
                        responseFormat = format || this.format || undefined;
                        if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
                            body = this.createFormData(body);
                        }
                        if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
                            body = JSON.stringify(body);
                        }
                        return [2 /*return*/, this.instance.request(__assign(__assign({}, requestParams), { headers: __assign(__assign({}, (requestParams.headers || {})), (type && type !== ContentType.FormData ? { "Content-Type": type } : {})), params: query, responseType: responseFormat, data: body, url: path }))];
                }
            });
        }); };
        this.instance = axios.create(__assign(__assign({}, axiosConfig), { baseURL: axiosConfig.baseURL || "https://gw.api-stg.evercash.eu/acquiring" }));
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }
    HttpClient.prototype.mergeRequestParams = function (params1, params2) {
        var method = params1.method || (params2 && params2.method);
        return __assign(__assign(__assign(__assign({}, this.instance.defaults), params1), (params2 || {})), { headers: __assign(__assign(__assign({}, ((method && this.instance.defaults.headers[method.toLowerCase()]) || {})), (params1.headers || {})), ((params2 && params2.headers) || {})) });
    };
    HttpClient.prototype.stringifyFormItem = function (formItem) {
        if (typeof formItem === "object" && formItem !== null) {
            return JSON.stringify(formItem);
        }
        else {
            return "".concat(formItem);
        }
    };
    HttpClient.prototype.createFormData = function (input) {
        var _this = this;
        return Object.keys(input || {}).reduce(function (formData, key) {
            var property = input[key];
            var propertyContent = property instanceof Array ? property : [property];
            for (var _i = 0, propertyContent_1 = propertyContent; _i < propertyContent_1.length; _i++) {
                var formItem = propertyContent_1[_i];
                var isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : _this.stringifyFormItem(formItem));
            }
            return formData;
        }, new FormData());
    };
    return HttpClient;
}());
export { HttpClient };
/**
 * @title Acquiring
 * @version 0.3.0-b8decd3e
 * @baseUrl https://gw.api-stg.evercash.eu/acquiring
 */
var AcquiringApi = /** @class */ (function (_super) {
    __extends(AcquiringApi, _super);
    function AcquiringApi() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.merchant = {
            /**
             * @description Set payment account, available for merchant with
             *
             * @tags merchant
             * @name UpdateRecurrentInvoicePaymentAccount
             * @summary Update payment account of recurrent invoice
             * @request PUT:/merchant/invoice/recurrent/{invoiceId}/payment/account
             * @secure
             */
            updateRecurrentInvoicePaymentAccount: function (invoiceId, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/merchant/invoice/recurrent/".concat(invoiceId, "/payment/account"), method: "PUT", query: query, secure: true }, params));
            },
            /**
             * @description If Success returns Invoice id and pay url
             *
             * @tags merchant
             * @name Invoice
             * @summary Allow merchant to create new invoices
             * @request POST:/merchant/invoice
             * @secure
             */
            invoice: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/merchant/invoice", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description invoice info for merchant
             *
             * @tags merchant
             * @name GetInvoice
             * @summary Get invoice object
             * @request GET:/merchant/invoice/{invoiceId}
             * @secure
             */
            getInvoice: function (invoiceId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/merchant/invoice/".concat(invoiceId), method: "GET", secure: true }, params));
            },
            /**
             * @description If Success returns 200
             *
             * @tags merchant
             * @name RevokeInvoice
             * @summary Allow merchant to revoke invoice
             * @request DELETE:/merchant/invoice/{invoiceId}
             * @secure
             */
            revokeInvoice: function (invoiceId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/merchant/invoice/".concat(invoiceId), method: "DELETE", secure: true }, params));
            },
            /**
             * @description If Success return InvoiceStatus object
             *
             * @tags merchant
             * @name Status
             * @summary Allow merchant to get info about invoice by Id
             * @request GET:/merchant/invoice/{invoiceId}/status
             * @secure
             */
            status: function (invoiceId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/merchant/invoice/".concat(invoiceId, "/status"), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description If Success returns InvoiceStatus object and sends callback
             *
             * @tags merchant
             * @name SendCallback
             * @summary Allow merchant to force send callback
             * @request GET:/merchant/invoice/{invoiceId}/sendCallback
             * @secure
             */
            sendCallback: function (invoiceId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/merchant/invoice/".concat(invoiceId, "/sendCallback"), method: "GET", secure: true, format: "json" }, params));
            },
        };
        _this.invoice = {
            /**
             * No description
             *
             * @tags invoice
             * @name UpdatePayment
             * @summary Invoice payment update
             * @request PUT:/invoice/payment
             * @secure
             */
            updatePayment: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/invoice/payment", method: "PUT", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags invoice
             * @name PaymentGetOrCreate
             * @summary Invoice payment get or create
             * @request POST:/invoice/payment
             * @secure
             */
            paymentGetOrCreate: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/invoice/payment", method: "POST", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * @description Update invoice status, but only for subscription's invoice
             *
             * @tags invoice
             * @name Webhook
             * @summary Update invoice status
             * @request POST:/invoice/webhook
             * @secure
             */
            webhook: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/invoice/webhook", method: "POST", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags invoice
             * @name Pay
             * @summary Invoice payment confirm
             * @request POST:/invoice/payment/pay
             * @secure
             */
            pay: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/invoice/payment/pay", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description return invoices linked to user
             *
             * @tags invoice
             * @name GetInvoices
             * @summary Get User Invoices
             * @request GET:/invoice
             * @secure
             */
            getInvoices: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/invoice", method: "GET", query: query, secure: true }, params));
            },
            /**
             * @description return invoice if it's available to user
             *
             * @tags invoice
             * @name GetInvoiceById
             * @summary Get Invoice by id
             * @request GET:/invoice/{id}
             * @secure
             */
            getInvoiceById: function (id, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/invoice/".concat(id), method: "GET", secure: true }, params));
            },
        };
        _this.admin = {
            /**
             * No description
             *
             * @tags admin-merchant-controller
             * @name UpdateMerchant
             * @summary admin update merchant
             * @request PUT:/admin/merchant/{merchantId}/
             * @secure
             */
            updateMerchant: function (merchantId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/merchant/".concat(merchantId, "/"), method: "PUT", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags admin-merchant-controller
             * @name DeleteMerchant
             * @summary admin delete merchant
             * @request DELETE:/admin/merchant/{merchantId}/
             * @secure
             */
            deleteMerchant: function (merchantId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/merchant/".concat(merchantId, "/"), method: "DELETE", secure: true }, params));
            },
            /**
             * @description return list of actual partners in acquiring service
             *
             * @tags admin-partner-controller
             * @name ListMerchant
             * @summary admin get partners controller
             * @request GET:/admin/partner
             * @secure
             */
            listMerchant: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/partner", method: "GET", secure: true }, params));
            },
            /**
             * @description return list of actual partners in acquiring service
             *
             * @tags admin-partner-controller
             * @name CreatePartner
             * @summary admin create partner controller
             * @request POST:/admin/partner
             * @secure
             */
            createPartner: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/partner", method: "POST", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags admin-merchant-controller
             * @name ListMerchant1
             * @summary admin get merchant
             * @request GET:/admin/merchant
             * @secure
             */
            listMerchant1: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/merchant", method: "GET", secure: true }, params));
            },
            /**
             * No description
             *
             * @tags admin-merchant-controller
             * @name CreateMerchant
             * @summary admin create merchant
             * @request POST:/admin/merchant
             * @secure
             */
            createMerchant: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/merchant", method: "POST", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * @description Get pageable invoices.
             *
             * @tags admin invoices
             * @name AdminGetInvoices
             * @summary admin get invoices
             * @request GET:/admin/invoice
             * @secure
             */
            adminGetInvoices: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/admin/invoice", method: "GET", query: query, secure: true }, params));
            },
        };
        _this.stripe = {
            /**
             * No description
             *
             * @tags stripe-interaction-controller
             * @name WebhookPost
             * @request POST:/stripe/webhook
             */
            webhookPost: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/stripe/webhook", method: "POST", body: data, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags stripe-interaction-controller
             * @name CreatePaymentIntent
             * @request POST:/stripe/create-payment-intent
             * @secure
             */
            createPaymentIntent: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/stripe/create-payment-intent", method: "POST", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags stripe-interaction-controller
             * @name GetStripeClientConfig
             * @request GET:/stripe/config
             * @secure
             */
            getStripeClientConfig: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/stripe/config", method: "GET", secure: true }, params));
            },
        };
        _this.payment = {
            /**
             * No description
             *
             * @tags PaymentController
             * @name CheckoutSuccessRedirect
             * @summary Checkout successful redirect
             * @request POST:/payment/checkout/redirect/success
             */
            checkoutSuccessRedirect: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/payment/checkout/redirect/success", method: "POST", query: query }, params));
            },
            /**
             * No description
             *
             * @tags PaymentController
             * @name CheckoutFailedRedirect
             * @summary Checkout failed redirect
             * @request POST:/payment/checkout/redirect/failed
             */
            checkoutFailedRedirect: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/payment/checkout/redirect/failed", method: "POST", query: query }, params));
            },
        };
        _this.itez = {
            /**
             * No description
             *
             * @tags itez-controller
             * @name CreateItezInit
             * @request POST:/itez/createItezInit
             */
            createItezInit: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/itez/createItezInit", method: "POST", body: data, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags itez-controller
             * @name Callback
             * @request POST:/itez/callback
             */
            callback: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/itez/callback", method: "POST", body: data, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags itez-controller
             * @name CreateWidget
             * @request GET:/itez/
             */
            createWidget: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/itez/", method: "GET", query: query }, params));
            },
        };
        _this.inwizo = {
            /**
             * No description
             *
             * @tags inwizo
             * @name InitPayment
             * @summary Init payment request
             * @request POST:/inwizo
             * @secure
             */
            initPayment: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/inwizo", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags inwizo
             * @name GetInwizoPaymentDescription
             * @summary Get inwizo payment summary
             * @request GET:/inwizo/payment
             * @secure
             */
            getInwizoPaymentDescription: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/inwizo/payment", method: "GET", query: query, secure: true, format: "json" }, params));
            },
        };
        return _this;
    }
    return AcquiringApi;
}(HttpClient));
export { AcquiringApi };
