import { observer } from "mobx-react-lite";
import * as React from "react";
import { Box } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { PopupOption } from "@/shared/components/Popup/Option";
import { useStoreContext } from "@/shared/hooks/useStore";
import { UserCurrency, UserCurrencyStore } from "@/shared/stores/UserCurrencyStore";
export var CurrencyPopup = observer(function (_a) {
    var onClose = _a.onClose;
    var userCurrency = useStoreContext(UserCurrencyStore);
    var onClick = function (value) { return function () {
        userCurrency.setSelect(value);
        onClose();
    }; };
    return (React.createElement(Popup, { onClose: onClose, width: "450px" },
        React.createElement(PopupHead, { onClose: onClose }, "Select currency"),
        React.createElement(Box, { height: "1px", bgColor: "black-50" }),
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", padding: "20px 25px 25px" },
            React.createElement(PopupOption, { desc: "USD, $", onClick: onClick(UserCurrency.Usd), title: "United States Dollar", selected: userCurrency.selected === UserCurrency.Usd }),
            React.createElement(PopupOption, { desc: "EUR, \u20AC", onClick: onClick(UserCurrency.Eur), title: "Euro", selected: userCurrency.selected === UserCurrency.Eur }))));
});
