import { jsPDF } from "jspdf";
export var saveToPDF = function (text, filename) {
    // eslint-disable-next-line new-cap
    var doc = new jsPDF();
    doc.text(text, 10, 10);
    doc.save(filename);
};
export var dataToText = function (data, maxWidth) {
    var text = "";
    var r = maxWidth ? ".{1,".concat(maxWidth, "}") : undefined;
    var re = r ? new RegExp(r, "g") : undefined;
    data.forEach(function (item) {
        item.forEach(function (value) {
            var _a, _b;
            text += re ? (_b = (_a = value.match(re)) === null || _a === void 0 ? void 0 : _a.join("\n")) !== null && _b !== void 0 ? _b : "" : value;
            text += "\n";
        });
        text += "\n";
    });
    return text;
};
