import { observer } from "mobx-react-lite";
import * as React from "react";
import { PaymentCardFormStore } from "@/pages/PaymentPage/stores/PaymentCardFormStore";
import { Box } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
import { useStoreContext } from "@/shared/hooks/useStore";
export var CheckoutForm = observer(function () {
    var form = useStoreContext(PaymentCardFormStore);
    return (React.createElement(Box, { minHeight: "489px", position: "relative" },
        form.loading && (React.createElement(Loader, { position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px", size: 24 })),
        React.createElement("div", { id: "flow-container" })));
});
