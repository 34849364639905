import { formattedTokenAmount } from "@broxus/js-utils";
import { List, Popover } from "@mui/material";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath, Link, useParams } from "react-router-dom";
import { NameForm } from "@/pages/BankAccountsPage/components/NameForm";
import { NameFormStore } from "@/pages/BankAccountsPage/stores/NameFormStore";
import { DepositType, ExchangeType, ROUTES, TransferType } from "@/routes";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { BankIcon } from "@/shared/components/BankIcon";
import { ListItem } from "@/shared/components/List/Item";
import { Loader } from "@/shared/components/Loader";
import { SquareBtn } from "@/shared/components/SquareBtn";
import { useBack } from "@/shared/hooks/useBack";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { Transactions } from "@/widgets/Transactions";
export var BankAccountPage = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var params = useParams();
    var media = useMedia();
    var back = useBack();
    var accountList = useStoreContext(AccountListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var addressList = useStoreContext(AddressListStore);
    var NameFormProvider = useProvider(NameFormStore);
    var _k = React.useState(null), moreEl = _k[0], setMoreEl = _k[1];
    var balance = (_a = balanceList.data[params.id]) === null || _a === void 0 ? void 0 : _a.balance;
    var account = (_b = accountList.fiatById) === null || _b === void 0 ? void 0 : _b[params.id];
    var address = account ? (_c = addressList.data[account === null || account === void 0 ? void 0 : account.account]) === null || _c === void 0 ? void 0 : _c[account === null || account === void 0 ? void 0 : account.currency] : undefined;
    var nameForm = useStore(NameFormStore, accountList, account === null || account === void 0 ? void 0 : account.account);
    var closeMore = function () {
        setMoreEl(null);
    };
    React.useEffect(function () {
        balanceList.sync(params.id);
    }, [params.id, balanceList]);
    React.useEffect(function () {
        addressList.sync(account);
    }, [account, addressList]);
    return (React.createElement(NameFormProvider, { value: nameForm },
        nameForm.visible && React.createElement(NameForm, null),
        React.createElement(Popover, { open: Boolean(moreEl), anchorEl: moreEl, onClose: closeMore, anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            }, transformOrigin: {
                vertical: "top",
                horizontal: "right",
            }, slotProps: {
                paper: {
                    sx: {
                        marginTop: "20px",
                    },
                },
            } },
            React.createElement(List, { sx: { width: "100%", maxWidth: 360, minWidth: 300 }, disablePadding: true },
                React.createElement(ListItem, { label: "Edit account name", onClick: function () {
                        closeMore();
                        runInAction(function () {
                            nameForm.visible = true;
                        });
                    } }))),
        React.createElement(Box, { gap: "40px", display: "flex", flexDirection: "column" },
            media.desktop
                ? (React.createElement(Button, { variant: "White", onClick: back, alignSelf: "flex-start", icon: "Back" }, "Back"))
                : (React.createElement(Box, { display: "flex", alignItems: "center", gap: "20px" },
                    React.createElement(BaseIcon, { icon: "Back", onClick: back, bgColor: "general-white", containerSize: 45, borderRadius: "12px" }),
                    React.createElement(Box, { display: "flex", flexDirection: "column" },
                        (account === null || account === void 0 ? void 0 : account.shortName) && (React.createElement(Typography, { type: "text16" }, account.shortName)),
                        React.createElement(Typography, { type: "text14", color: "black-400" }, ((_d = address === null || address === void 0 ? void 0 : address.first) === null || _d === void 0 ? void 0 : _d.isEmpty)
                            ? "Account unavailable"
                            : (_f = (_e = address === null || address === void 0 ? void 0 : address.first) === null || _e === void 0 ? void 0 : _e.accountNumber) !== null && _f !== void 0 ? _f : "\u200b")))),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "23px" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "5px" }, balance
                    ? (React.createElement(Typography, { type: "text36Bold" },
                        formattedTokenAmount(balance),
                        " ", account === null || account === void 0 ? void 0 :
                        account.currency))
                    : React.createElement(Loader, { containerSize: 47, size: 24 })),
                media.desktop
                    ? (React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between" },
                        React.createElement(Box, { display: "flex", gap: "15px", alignItems: "center" },
                            React.createElement(BankIcon, { symbol: "usd", size: 42 }),
                            React.createElement(Box, { display: "flex", flexDirection: "column" },
                                (account === null || account === void 0 ? void 0 : account.shortName) && (React.createElement(Typography, { type: "text16" }, account.shortName)),
                                React.createElement(Typography, { type: "text14", color: "black-400" }, ((_g = address === null || address === void 0 ? void 0 : address.first) === null || _g === void 0 ? void 0 : _g.isEmpty)
                                    ? "Account unavailable"
                                    : (_j = (_h = address === null || address === void 0 ? void 0 : address.first) === null || _h === void 0 ? void 0 : _h.accountNumber) !== null && _j !== void 0 ? _j : "\u200b"))),
                        React.createElement(Box, { display: "flex", gap: "10px" },
                            React.createElement(Button, { icon: "Deposit", size: "s", LinkComponent: Link, to: generatePath(ROUTES.deposit, {
                                    type: DepositType.Fiat,
                                    id: params.id,
                                }) }, "Add funds"),
                            React.createElement(Button, { variant: "Gray", icon: "Transfer", size: "s", LinkComponent: Link, to: generateQueryPath(ROUTES.transfer, {
                                    type: TransferType.Account,
                                }, {
                                    from: params.id,
                                }) }, "Transfer"),
                            React.createElement(Button, { variant: "Gray", icon: "Exchange", size: "s", LinkComponent: Link, to: generateQueryPath(ROUTES.exchange, {
                                    type: ExchangeType.Fiat,
                                }, {
                                    from: params.id,
                                }) }, "Exchange"),
                            React.createElement(Button, { variant: "Gray", icon: "More", size: "s", onClick: function (e) {
                                    setMoreEl(e.currentTarget);
                                } }, "More"))))
                    : (React.createElement(Box, { gap: "25px", display: "flex", alignItems: "center" },
                        React.createElement(SquareBtn, { icon: "Deposit", type: "blue", to: generatePath(ROUTES.deposit, {
                                type: DepositType.Fiat,
                                id: params.id,
                            }) }, "Add funds"),
                        React.createElement(SquareBtn, { icon: "Transfer", to: generateQueryPath(ROUTES.transfer, {
                                type: TransferType.Account,
                            }, {
                                from: params.id,
                            }) }, "Transfer"),
                        React.createElement(SquareBtn, { icon: "Exchange", to: generateQueryPath(ROUTES.exchange, {
                                type: ExchangeType.Fiat,
                            }, {
                                from: params.id,
                            }) }, "Exchange"),
                        React.createElement(SquareBtn, { icon: "More", onClick: function (e) {
                                setMoreEl(e.currentTarget);
                            } }, "More")))),
            React.createElement(Box, { height: "1px", bgColor: "black-200", width: "100%" }),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "20px", mb: "20px" },
                React.createElement(Typography, { type: "text24", fontWeight: 500 }, "Transactions"),
                React.createElement(Transactions, { accountIds: (account === null || account === void 0 ? void 0 : account.account) ? [account.account] : [] })))));
});
