import { styled } from "@mui/material/styles";
import { Box } from "@/shared/components";
export var Card = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        transition: "all 0.3s ease",
        "&:hover": {
            backgroundColor: theme.colors["black-50"],
        },
    });
});
export var WrapperStatus = styled(Box)(function (_a) {
    var theme = _a.theme;
    return ({
        position: "absolute",
        right: "60px",
        top: 0,
        textTransform: "lowercase",
        background: theme.colors["black-1000"],
        color: theme.colors["general-white"],
        borderRadius: "8px",
        padding: "6px 12px",
    });
});
