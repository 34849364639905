import React from "react";
export var useToggler = function (initialValue) {
    if (initialValue === void 0) { initialValue = false; }
    var _a = React.useState(initialValue), enabled = _a[0], setEnabled = _a[1];
    var toggle = React.useCallback(function (value) {
        setEnabled(value);
    }, []);
    var on = React.useCallback(function () {
        setEnabled(true);
    }, []);
    var off = React.useCallback(function () {
        setEnabled(false);
    }, []);
    return React.useMemo(function () { return ({
        enabled: enabled,
        toggle: toggle,
        on: on,
        off: off,
    }); }, [toggle, on, off, enabled]);
};
