var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import * as React from "react";
export var PopupItem = function (_a) {
    var children = _a.children, selected = _a.selected, onClick = _a.onClick;
    return (React.createElement(Wrapper, { selected: selected, onClick: onClick }, children));
};
var Wrapper = styled("button")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 0 25px;\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    min-height: 80px;\n    text-align: left;\n    width: 100%;\n    background: ", ";\n    transition: 0.2s ease;\n\n    &:last-child {\n        border-radius: 0 0 22px 22px;\n    }\n\n    &:hover {\n        background: ", ";\n    }\n"], ["\n    padding: 0 25px;\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    min-height: 80px;\n    text-align: left;\n    width: 100%;\n    background: ", ";\n    transition: 0.2s ease;\n\n    &:last-child {\n        border-radius: 0 0 22px 22px;\n    }\n\n    &:hover {\n        background: ", ";\n    }\n"])), function (props) { return props.selected && props.theme.colors["deep-blue-500-transparent-2"]; }, function (props) { return props.theme.colors["deep-blue-500-transparent-2"]; });
var templateObject_1;
