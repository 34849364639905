import { styled } from "@mui/material/styles";
import { Box } from "../Box";
var height = {
    s: "26px",
    m: "50px",
    l: "50px",
};
export var StyledBox = styled(Box, {
    shouldForwardProp: function (prop) { return !prop.startsWith("$"); },
})(function (_a) {
    var $height = _a.$height;
    return ({
        height: height[$height],
    });
});
