import { observer } from "mobx-react-lite";
import React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupHead } from "@/shared/components/Popup/Head";
import { useStoreContext } from "@/shared/hooks/useStore";
import welcomeMainImg from "@/shared/img/money.png";
import { AuthStore } from "@/shared/stores/AuthStore";
export var SignOutModal = observer(function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    var auth = useStoreContext(AuthStore);
    var navigate = useNavigate();
    return isOpen
        ? (React.createElement(Popup, { width: "450px", onClose: onClose, bgColor: "black-1200" },
            React.createElement(PopupHead, { invert: true, onClose: onClose }),
            React.createElement(PopupBody, null,
                React.createElement(Box, { textAlign: "center" },
                    React.createElement("img", { src: welcomeMainImg, alt: "money", width: 277, height: 277 }),
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", mt: "10px" },
                        React.createElement(Typography, { textAlign: "center", color: "general-white", type: "text28" }, "Are you leaving?"),
                        React.createElement(Typography, { textAlign: "center", color: "general-white", type: "text14" }, "All settings will be saved")),
                    React.createElement(Box, { display: "flex", flexDirection: "column", width: "100%", gap: "20px", mt: "40px" },
                        React.createElement(Button, { onClick: function () {
                                auth.logout();
                                onClose();
                                navigate(generatePath(ROUTES.signin));
                            } }, "Log out"),
                        React.createElement(Typography, { zIndex: 0, role: "button", onClick: onClose, color: "general-white", textAlign: "center" }, "Cancel"))))))
        : null;
});
