var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { toast } from "react-toastify";
import { Toast } from "@/shared/components/Alert";
export var useErrorToasts = function (errors) {
    React.useEffect(function () {
        errors.forEach(function (error) {
            toast(function (props) { return (React.createElement(Toast, __assign({}, props, { type: "error", text: error.description }))); });
        });
    }, [errors]);
};
