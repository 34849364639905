var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled } from "@mui/material/styles";
export var StyledContainer = styled("div", {
    shouldForwardProp: function (prop) { return !prop.startsWith("$"); },
})(function (_a) {
    var $errorPosittion = _a.$errorPosittion, $width = _a.$width;
    return ({
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: $width || "unset",
        ".Alert": __assign({ position: $errorPosittion }, ($errorPosittion === "absolute" && {
            bottom: "-8px",
            transform: "translateY(100%)",
            boxSizing: "border-box",
            width: "100%",
            zIndex: 2,
        })),
    });
});
