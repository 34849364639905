var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { borders as MUBorders, compose, display, flexbox, grid, positions, sizing, spacing, typography, } from "@mui/system";
import React, { memo } from "react";
import { StyledBox } from "./styled";
export var ImplBox = React.forwardRef(function (_a, ref) {
    var children = _a.children, _b = _a.variant, variant = _b === void 0 ? "unstyled" : _b, bgColor = _a.bgColor, borderColor = _a.borderColor, borderWidth = _a.borderWidth, borderStyle = _a.borderStyle, backdropFilter = _a.backdropFilter, borders = _a.borders, borderRadius = _a.borderRadius, props = __rest(_a, ["children", "variant", "bgColor", "borderColor", "borderWidth", "borderStyle", "backdropFilter", "borders", "borderRadius"]);
    return (React.createElement(StyledBox, __assign({ "$bgColor": bgColor, "$borders": borders, "$borderColor": borderColor, "$borderWidth": borderWidth, "$borderStyle": borderStyle, "$backdropFilter": backdropFilter, "$variant": variant }, props, { ref: ref }), children));
});
export var Box = memo(styled(ImplBox, {
    shouldForwardProp: function (prop) {
        return !compose(spacing, sizing, flexbox, grid, display, positions, typography)
            .filterProps.includes(prop);
    },
})(compose(spacing, sizing, flexbox, grid, display, MUBorders, positions, typography)));
