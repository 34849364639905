var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { RecipientItem } from "../RecipientItem";
import { ROUTES, TransferType } from "@/routes";
import { Box, Button, Typography } from "@/shared/components";
import { Loader } from "@/shared/components/Loader";
import { Popup } from "@/shared/components/Popup";
import { PopupCloseBtn } from "@/shared/components/Popup/CloseBtn";
import { useMedia } from "@/shared/hooks/useMedia";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { RecipientListStore } from "@/shared/stores/RecipientListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { sliceAddress } from "@/shared/utils/slice-address";
export var RecipientList = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var media = useMedia();
    var currencyList = useStoreContext(CurrencyListStore);
    var recipientList = useStoreContext(RecipientListStore);
    var _m = useState(false), open = _m[0], setIsOpen = _m[1];
    var _o = useState(""), detailsId = _o[0], setDetailsId = _o[1];
    var handleShowDetails = function (id) {
        setDetailsId(id);
        setIsOpen(true);
    };
    var selectedItem = (_a = recipientList.list) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.data.id === detailsId; });
    if (!((_b = recipientList.list) === null || _b === void 0 ? void 0 : _b.length)) {
        return (React.createElement(Box, { height: "320px", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }, recipientList.loading ? React.createElement(Loader, { size: 24 }) : (React.createElement(Typography, { type: "text14", color: "black-400" }, "Soon it will be very crowdy here ;)"))));
    }
    var deleteRec = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!((_a = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.data) === null || _a === void 0 ? void 0 : _a.id))
                        return [2 /*return*/];
                    return [4 /*yield*/, recipientList.deleteParty((_b = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.data) === null || _b === void 0 ? void 0 : _b.id)];
                case 1:
                    _c.sent();
                    setIsOpen(false);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "grid", gridTemplateColumns: media.phone ? "1fr" : "1fr 1fr", gap: "20px" }, (_c = recipientList.list) === null || _c === void 0 ? void 0 : _c.map(function (item) { return (React.createElement(RecipientItem, { key: item.data.id, id: item.data.id, name: item.data.profile.name, status: "status" in item.data ? item.data.status : "", account: item.accountNumber, onClick: handleShowDetails })); })),
        open && (React.createElement(Popup, { width: "450px", onClose: function () { return setIsOpen(false); } },
            React.createElement(PopupCloseBtn, { position: "absolute", top: "20px", right: "20px", onClick: function () { return setIsOpen(false); } }),
            React.createElement(Box, { borderRadius: "22px 22px 0 0", padding: "100px 50px 30px", bgColor: "black-50", display: "flex", flexDirection: "column", gap: "34px", width: "100%", boxSizing: "border-box" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px", alignItems: "center" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "12px" },
                        React.createElement(Typography, { type: "text24", textAlign: "center" }, (_d = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.accountNumber) !== null && _d !== void 0 ? _d : ((selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.blockchainAddress)
                            ? sliceAddress(selectedItem.blockchainAddress)
                            : "\u200b")),
                        React.createElement(Typography, { textAlign: "center" }, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.data.profile.name)),
                    React.createElement(Box, { display: "flex", gap: 4 },
                        React.createElement(Button, { variant: "Bordered", onClick: deleteRec }, "Delete"),
                        React.createElement(Button, { component: Link, to: generateQueryPath(ROUTES.transfer, {
                                type: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.isCrypto)
                                    ? TransferType.Crypto
                                    : TransferType.Recipient,
                            }, {
                                to: detailsId,
                            }) }, "Transfer funds")))),
            React.createElement(Box, { width: "100%", padding: "0 30px 10px", boxSizing: "border-box", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" },
                React.createElement(Box, { width: "100%" },
                    (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.isCrypto)
                        ? (React.createElement(React.Fragment, null,
                            React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                                React.createElement(Typography, { type: "text14", color: "black-400" }, "Blockchain"),
                                React.createElement(Typography, null, selectedItem.blockchain
                                    ? (_e = currencyList.blockchain[selectedItem.blockchain]) === null || _e === void 0 ? void 0 : _e.name
                                    : "\u200b")),
                            React.createElement(Box, { bgColor: "black-50", height: "1px", m: "0 -30px" }),
                            React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                                React.createElement(Typography, { type: "text14", color: "black-400" }, "Address"),
                                React.createElement(Typography, null, (_f = selectedItem.blockchainAddress) !== null && _f !== void 0 ? _f : "\u200b")),
                            React.createElement(Box, { bgColor: "black-50", height: "1px", m: "0 -30px" }),
                            React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                                React.createElement(Typography, { type: "text14", color: "black-400" }, "Currency"),
                                React.createElement(Typography, null, (_h = (_g = selectedItem.currency) === null || _g === void 0 ? void 0 : _g.toUpperCase()) !== null && _h !== void 0 ? _h : "\u200b"))))
                        : (React.createElement(React.Fragment, null,
                            React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                                React.createElement(Typography, { type: "text14", color: "black-400" }, "Country"),
                                React.createElement(Typography, null, (_k = (_j = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.data.institutionAddress) === null || _j === void 0 ? void 0 : _j.country) !== null && _k !== void 0 ? _k : "—")),
                            React.createElement(Box, { bgColor: "black-50", height: "1px", m: "0 -30px" }),
                            React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                                React.createElement(Typography, { type: "text14", color: "black-400" }, "Bank name"),
                                React.createElement(Typography, null, (_l = selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.data.institutionName) !== null && _l !== void 0 ? _l : "—")))),
                    (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.iban) && (React.createElement(React.Fragment, null,
                        React.createElement(Box, { m: "0 -30px", bgColor: "black-50", height: "1px" }),
                        React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                            React.createElement(Typography, { type: "text14", color: "black-400" }, "IBAN"),
                            React.createElement(Typography, null, selectedItem.iban)))),
                    (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.swiftBic) && (React.createElement(React.Fragment, null,
                        React.createElement(Box, { m: "0 -30px", bgColor: "black-50", height: "1px" }),
                        React.createElement(Box, { display: "flex", flexDirection: "column", padding: "15px 0" },
                            React.createElement(Typography, { type: "text14", color: "black-400" }, "BIC"),
                            React.createElement(Typography, null, selectedItem.swiftBic))))))))));
});
