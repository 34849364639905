import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { Form1 } from "@/pages/KYBPage/components/Form1";
import { Form2 } from "@/pages/KYBPage/components/Form2";
import { Form3 } from "@/pages/KYBPage/components/Form3";
import { Form4 } from "@/pages/KYBPage/components/Form4";
import { Form5 } from "@/pages/KYBPage/components/Form5";
import { Form6 } from "@/pages/KYBPage/components/Form6";
export var KYBFormIdPage = observer(function () {
    var params = useParams();
    return params.id === "1"
        ? React.createElement(Form1, null)
        : params.id === "2"
            ? React.createElement(Form2, null)
            : params.id === "3"
                ? React.createElement(Form3, null)
                : params.id === "4"
                    ? React.createElement(Form4, null)
                    : params.id === "5"
                        ? React.createElement(Form5, null)
                        : params.id === "6"
                            ? React.createElement(Form6, null)
                            : null;
});
