// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NDVH6JAUzZalTpA{padding:80px 80px 50px;box-sizing:border-box;height:100%;display:flex;flex-direction:column;gap:40px}@media(max-width: 1023px){.NDVH6JAUzZalTpA{padding:40px 20px}}.Bq5wY33LzE4ngmE{display:flex;align-items:center;gap:40px}@media(max-width: 1023px){.Bq5wY33LzE4ngmE{flex-direction:column;gap:10px}}.Yrtm2568qmca6aR{flex-grow:1;display:flex;align-items:center;justify-content:center;flex-direction:column;gap:15px}.mCxCIec_yXKNvlq{text-align:center}.lRZXiOdyKFYZFbS{display:flex;flex-direction:column;gap:10px}`, "",{"version":3,"sources":["webpack://./src/pages/AuthPage/components/Welcome/index.module.scss"],"names":[],"mappings":"AAAA,iBACI,sBAAA,CACA,qBAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,0BARJ,iBASQ,iBAAA,CAAA,CAIR,iBACI,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,0BALJ,iBAMQ,qBAAA,CACA,QAAA,CAAA,CAIR,iBACI,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,QAAA,CAGJ,iBACI,iBAAA,CAGJ,iBACI,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".root {\n    padding: 80px 80px 50px;\n    box-sizing: border-box;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 40px;\n\n    @media (max-width: 1023px) {\n        padding: 40px 20px;\n    }\n}\n\n.head {\n    display: flex;\n    align-items: center;\n    gap: 40px;\n\n    @media (max-width: 1023px) {\n        flex-direction: column;\n        gap: 10px;\n    }\n}\n\n.main {\n    flex-grow: 1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    gap: 15px;\n}\n\n.footer {\n    text-align: center;\n}\n\n.actions {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `NDVH6JAUzZalTpA`,
	"head": `Bq5wY33LzE4ngmE`,
	"main": `Yrtm2568qmca6aR`,
	"footer": `mCxCIec_yXKNvlq`,
	"actions": `lRZXiOdyKFYZFbS`
};
export default ___CSS_LOADER_EXPORT___;
