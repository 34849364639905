var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import * as React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
export var PaymentTypeItem = function (_a) {
    var disable = _a.disable, icon = _a.icon, title = _a.title, active = _a.active, desc = _a.desc, onClick = _a.onClick;
    return (React.createElement(Wrapper, { active: active, onClick: onClick, disabled: disable },
        React.createElement(BaseIcon, { size: 32, icon: icon }),
        React.createElement(Box, { flex: "1", display: "flex", flexDirection: "column" },
            React.createElement(Typography, { type: "text16", fontWeight: 500 }, title),
            React.createElement(Typography, { type: "text14", fontWeight: 500, color: "black-500" }, desc)),
        React.createElement(Box, null,
            React.createElement(Radio, { active: active },
                React.createElement(BaseIcon, { icon: "Radio", size: 8, color: "general-white" })))));
};
// dprint-ignore
var Radio = styled('div')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    box-sizing: border-box;\n    box-shadow: ", ";\n    width: 20px;\n    height: 20px;\n    border-radius: 20px;\n    transition: 0.2s ease;\n    background: ", ";\n"], ["\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    box-sizing: border-box;\n    box-shadow: ", ";\n    width: 20px;\n    height: 20px;\n    border-radius: 20px;\n    transition: 0.2s ease;\n    background: ", ";\n"
    // dprint-ignore
])), function (props) { return props.active
    ? "inset 0 0 0 2px ".concat(props.theme.colors["deep-blue-500"])
    : "inset 0 0 0 2px ".concat(props.theme.colors["black-100"]); }, function (props) { return props.active
    ? props.theme.colors["deep-blue-500"]
    : props.theme.colors["general-white"]; });
// dprint-ignore
var Wrapper = styled('button')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    text-align: left;\n    padding: 20px;\n    box-shadow: ", ";\n    display: flex;\n    border-radius: 12px;\n    align-items: center;\n    gap: 15px;\n    cursor: pointer;\n    transition: 0.2s ease;\n    opacity: ", "\n"], ["\n    text-align: left;\n    padding: 20px;\n    box-shadow: ", ";\n    display: flex;\n    border-radius: 12px;\n    align-items: center;\n    gap: 15px;\n    cursor: pointer;\n    transition: 0.2s ease;\n    opacity: ", "\n"])), function (props) { return props.active
    ? "inset 0 0 0 2px ".concat(props.theme.colors["deep-blue-500"])
    : "inset 0 0 0 1px ".concat(props.theme.colors["black-100"]); }, function (props) { return props.disabled ? "0.5" : "1"; });
var templateObject_1, templateObject_2;
