import { formattedTokenAmount } from "@broxus/js-utils";
import * as React from "react";
import { generatePath } from "react-router-dom";
import { ROUTES } from "@/routes";
import { Box, Typography } from "@/shared/components";
import { AccountItem } from "@/shared/components/AccountItem";
import { BankIcon } from "@/shared/components/BankIcon";
import { Loader } from "@/shared/components/Loader";
export var BankItem = function (_a) {
    var amount = _a.amount, id = _a.id, accNumber = _a.accNumber, desc = _a.desc, symbol = _a.symbol, isEmpty = _a.isEmpty;
    var amountFormatted = React.useMemo(function () { return (amount ? formattedTokenAmount(amount) : undefined); }, [amount]);
    return (React.createElement(AccountItem, { to: generatePath(ROUTES.bankAccountsItem, {
            id: id,
        }) },
        React.createElement(BankIcon, { symbol: symbol, size: 45 }),
        React.createElement(Box, { flexGrow: "1", display: "flex", flexDirection: "column" },
            React.createElement(Box, null, amountFormatted
                ? (React.createElement(React.Fragment, null,
                    React.createElement(Typography, { type: "text20" },
                        formattedTokenAmount(amount),
                        " "),
                    React.createElement(Typography, { type: "text20", color: "black-400" }, symbol.toUpperCase())))
                : React.createElement(Loader, { size: 16, containerSize: 26 })),
            desc && (React.createElement(Typography, { type: "text14", color: "black-400" }, desc)),
            React.createElement(Typography, { type: "text14", color: "black-400" }, isEmpty ? "No account" : accNumber !== null && accNumber !== void 0 ? accNumber : "\u200b"))));
};
