var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { compose, spacing } from "@mui/system";
import React, { memo } from "react";
import { FormAlert } from "../FormAlert";
import { Autocomplete } from "../Select";
import { ListOptionDefault } from "../Select/Option";
import { Typography } from "../Typography";
import { StyledContainer } from "./styled";
export var ImplFormControlMultiSelect = function (_a) {
    var _b;
    var error = _a.error, _c = _a.errorPosition, errorPosition = _c === void 0 ? "relative" : _c, _d = _a.variantError, variantError = _d === void 0 ? "text" : _d, value = _a.value, props = __rest(_a, ["error", "errorPosition", "variantError", "value"]);
    var selected = ((_b = props.options) === null || _b === void 0 ? void 0 : _b.filter(function (option) { return value === null || value === void 0 ? void 0 : value.find(function (v) { return v === option.key; }); })) || [];
    return (React.createElement(StyledContainer, { "$errorPosittion": errorPosition },
        React.createElement(Autocomplete
        // TODO: Fix this ref
        , __assign({ 
            // TODO: Fix this ref
            classes: { inputRoot: selected.length ? "MuiAutocomplete-hasValue" : "" }, ref: undefined, renderOption: function (p, option, state) { return (React.createElement(ListOptionDefault, __assign({ text: option.label }, state, p, { key: option.key }))); } }, props, { value: selected, FormControlProps: { error: error, errorPosition: errorPosition, variantError: variantError } })),
        error
            && typeof error === "string"
            && variantError === "alert" && React.createElement(FormAlert, { text: error }),
        error
            && typeof error === "string"
            && variantError === "text" && (React.createElement(Typography, { type: "text14", color: "general-red" }, error))));
};
export var FormControlMultiSelect = memo(styled(ImplFormControlMultiSelect)(compose(spacing)));
