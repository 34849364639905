var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SuccessPopup } from "./Modal";
import { VerificationStatus } from "@/api/registration/types";
import { CheckoutForm } from "@/pages/PaymentPage/components/PaymentFormCard/CheckoutForm";
import { PaymentFormCardSubmit } from "@/pages/PaymentPage/components/PaymentFormCard/Submit";
import { PaymentPromo } from "@/pages/PaymentPage/components/Promo";
import { PaymentCardFormStore } from "@/pages/PaymentPage/stores/PaymentCardFormStore";
import { PaymentPromoStore } from "@/pages/PaymentPage/stores/PaymentPromoStore";
import { VerificationStore } from "@/pages/PaymentPage/stores/VerificationStore";
import { ModalType, PaymentParam, PaymentTarget, ROUTES } from "@/routes";
import { Button } from "@/shared/components";
import { useProvider, useStore, useStoreContext } from "@/shared/hooks/useStore";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
import { generateQueryPath } from "@/shared/utils/path";
import { FailedPopup } from "@/widgets/FailedPopup";
import { UnderReviewPopup } from "@/widgets/UnderReviewPopup";
export var PaymentFormCard = observer(function () {
    var _a, _b, _c, _d;
    var navigate = useNavigate();
    var search = useSearchParams()[0];
    var target = search.get(PaymentParam.Target);
    var plan = search.get(PaymentParam.Plan);
    var verification = useStoreContext(VerificationStore);
    var subscription = useStoreContext(SubscriptionStore);
    var promo = useStore(PaymentPromoStore, subscription);
    var form = useStore(PaymentCardFormStore, subscription);
    var FormProvider = useProvider(PaymentCardFormStore);
    var PromoProvider = useProvider(PaymentPromoStore);
    var isKyb = PaymentTarget.Kyb === target;
    React.useEffect(function () {
        subscription.createPayment();
    }, [subscription]);
    return (React.createElement(PromoProvider, { value: promo },
        React.createElement(FormProvider, { value: form },
            ((_a = verification.verification) === null || _a === void 0 ? void 0 : _a.status)
                === VerificationStatus.PENDING && (React.createElement(UnderReviewPopup, { redirectLink: generateQueryPath(ROUTES.root, undefined, isKyb ? { type: ModalType.AfterKyb } : undefined) })),
            ((_b = form.errors) === null || _b === void 0 ? void 0 : _b.length) > 0
                && ((_c = verification.verification) === null || _c === void 0 ? void 0 : _c.status)
                    === VerificationStatus.INIT
                && (React.createElement(FailedPopup, { onClose: action(function () {
                        form.success = false;
                        form.errors = [];
                    }) })),
            form.success
                && ((_d = verification.verification) === null || _d === void 0 ? void 0 : _d.status)
                    === VerificationStatus.INIT
                && (React.createElement(SuccessPopup, { onClose: action(function () {
                        form.success = false;
                        navigate("/");
                    }) })),
            React.createElement(Wrapper, null,
                React.createElement(Button, { variant: "Gray", icon: "Back", width: "auto", paddingRight: "25px", marginBottom: "60px", disabled: form.success, onClick: function () {
                        var _a;
                        return navigate(generateQueryPath(ROUTES.payment, {}, (_a = {},
                            _a[PaymentParam.Target] = target,
                            _a[PaymentParam.Plan] = plan,
                            _a)), {
                            replace: true,
                        });
                    } }, "Back"),
                React.createElement(CheckoutForm, null)),
            React.createElement(Sidebar, null,
                React.createElement(SidebarInner, null,
                    React.createElement(PaymentPromo, null),
                    React.createElement(PaymentFormCardSubmit, null))))));
});
var Wrapper = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    max-width: 560px;\n\n    ", " {\n        max-width: unset;\n    }\n"], ["\n    max-width: 560px;\n\n    ", " {\n        max-width: unset;\n    }\n"])), function (props) { return props.theme.breakpoints.down("md"); });
var Sidebar = styled("div")(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: max(\n        calc(((100% - 1240px) / 2) + 560px + min(160px, ((100% - 1240px) / 2))),\n        600px\n    );\n    right: 0;\n    z-index: -1;\n    padding: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: ", ";\n\n    ", " {\n        position: static;\n        padding: 0;\n        margin: 40px 0 0;\n        background: none;\n    }\n"], ["\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    left: max(\n        calc(((100% - 1240px) / 2) + 560px + min(160px, ((100% - 1240px) / 2))),\n        600px\n    );\n    right: 0;\n    z-index: -1;\n    padding: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: ", ";\n\n    ", " {\n        position: static;\n        padding: 0;\n        margin: 40px 0 0;\n        background: none;\n    }\n"])), function (props) { return props.theme.colors["black-1200"]; }, function (props) { return props.theme.breakpoints.down("md"); });
var SidebarInner = styled("div")(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    max-width: 420px;\n    width: 100%;\n\n    ", " {\n        max-width: unset;\n    }\n"], ["\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    max-width: 420px;\n    width: 100%;\n\n    ", " {\n        max-width: unset;\n    }\n"])), function (props) { return props.theme.breakpoints.down("md"); });
var templateObject_1, templateObject_2, templateObject_3;
