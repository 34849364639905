// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.H5sdNmzxMPiwD3U{display:flex;background:#eeeff2;min-height:100vh;box-sizing:border-box}._ChpWQA0ZY2nBPF{flex-grow:1}.u0NNdDOqvBSX3Gc{max-width:500px;width:100%;background:#fff}@keyframes iEjqBg8KYW7y5qj{from{transform:translateX(50px);opacity:0}to{transform:none;opacity:1}}.z7H6evk7Sg6_l_s{position:relative;background:#fff;min-height:100vh;animation:iEjqBg8KYW7y5qj .25s;flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/pages/AuthPage/index.module.scss"],"names":[],"mappings":"AAAA,iBACI,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,qBAAA,CAIJ,iBACI,WAAA,CAGJ,iBACI,eAAA,CACA,UAAA,CACA,eAAA,CAGJ,2BACI,KACI,0BAAA,CACA,SAAA,CAEJ,GACI,cAAA,CACA,SAAA,CAAA,CAIR,iBACI,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,8BAAA,CACA,WAAA","sourcesContent":[".root {\n    display: flex;\n    background: #EEEFF2;\n    min-height: 100vh;\n    box-sizing: border-box;\n\n}\n\n.main {\n    flex-grow: 1;\n}\n\n.side {\n    max-width: 500px;\n    width: 100%;\n    background: #fff;\n}\n\n@keyframes slide {\n    from {\n        transform: translateX(50px);\n        opacity: 0;\n    }\n    to {\n        transform: none;\n        opacity: 1;\n    }\n}\n\n.popup {\n    position: relative;\n    background: #fff;\n    min-height: 100vh;\n    animation: slide 0.25s;\n    flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `H5sdNmzxMPiwD3U`,
	"main": `_ChpWQA0ZY2nBPF`,
	"side": `u0NNdDOqvBSX3Gc`,
	"popup": `z7H6evk7Sg6_l_s`,
	"slide": `iEjqBg8KYW7y5qj`
};
export default ___CSS_LOADER_EXPORT___;
