var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { CreateWalletPopup } from "@/features/create-wallet";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { FormControlInput } from "@/shared/components/FormControlInput";
import { Loader } from "@/shared/components/Loader";
import { Popup } from "@/shared/components/Popup";
import { PopupHead } from "@/shared/components/Popup/Head";
import { PopupItem } from "@/shared/components/Popup/Item";
import { TokenItem } from "@/shared/components/TokenPopup/TokenItem";
import { Typography } from "@/shared/components/Typography";
import { useStoreContext } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddressListStore } from "@/shared/stores/AddressListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
export var TokenPopup = observer(function (_a) {
    var selectedId = _a.selectedId, onSelect = _a.onSelect, onClose = _a.onClose;
    var popup = useToggler();
    var accounts = useStoreContext(AccountListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var addressList = useStoreContext(AddressListStore);
    var loaded = accounts.crypto.every(function (item) {
        var _a, _b;
        return (((_a = balanceList.data[item.account]) === null || _a === void 0 ? void 0 : _a.balance)
            && ((_b = addressList.data[item.account]) === null || _b === void 0 ? void 0 : _b[item.currency]));
    });
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accounts.crypto.map(function (item) { return item.account; }));
    }, [accounts.crypto, balanceList]);
    React.useEffect(function () {
        addressList.sync.apply(addressList, accounts.crypto);
    }, [accounts.crypto, addressList]);
    return popup.enabled
        ? (React.createElement(CreateWalletPopup, { onClose: popup.off, onSuccess: popup.off }))
        : (React.createElement(Popup, { onClose: onClose },
            React.createElement(PopupHead, { onClose: onClose }, "Select crypto"),
            React.createElement(Box, { height: "1px", bgColor: "black-50" }),
            React.createElement(Box, { position: "relative" },
                !loaded && (React.createElement(Loader, { size: 24, position: "absolute", top: "50%", left: "50%", margin: "-12px 0 0 -12px", zIndex: "100" })),
                React.createElement(Wrapper, { loaded: loaded },
                    React.createElement(Box, { padding: "20px 25px" },
                        React.createElement(FormControlInput
                        // TODO: Search impl
                        , { 
                            // TODO: Search impl
                            placeholder: "Search", InputProps: {
                                startAdornment: (React.createElement(BaseIcon, { icon: "Search", color: "black-400" })),
                            } })),
                    React.createElement(Box, { overflow: "hidden", borderRadius: "0 0 22px 22px" },
                        React.createElement(List, { loaded: loaded },
                            React.createElement(PopupItem, { onClick: popup.on },
                                React.createElement(BaseIcon, { icon: "Plus", size: 14, containerSize: 36, borderRadius: "36px", bgColor: "light-blue", color: "deep-blue-500" }),
                                React.createElement(Typography, { type: "text14", color: "deep-blue-500" }, "Create new wallet")),
                            accounts.crypto.map(function (item) {
                                var _a, _b, _c, _d;
                                var address = (_a = addressList.data[item.account]) === null || _a === void 0 ? void 0 : _a[item.currency];
                                return (React.createElement(TokenItem, { address: (_b = address === null || address === void 0 ? void 0 : address.first) === null || _b === void 0 ? void 0 : _b.address, key: item.account, id: item.account, selected: item.account === selectedId, symbol: item.currency, amount: (_d = (_c = balanceList.data) === null || _c === void 0 ? void 0 : _c[item.account]) === null || _d === void 0 ? void 0 : _d.balance, currency: item.currency, onSelect: function () {
                                        onSelect(item.account);
                                    } }));
                            })))))));
});
var Wrapper = styled("div")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: relative;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n    pointer-events: ", ";\n"], ["\n    position: relative;\n    opacity: ", ";\n    transition: opacity 0.2s ease;\n    pointer-events: ", ";\n"])), function (props) { return props.loaded ? 1 : 0; }, function (props) { return props.loaded ? "unset" : "none"; });
var List = styled("div")(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    overflow: auto;\n    max-height: 505px;\n    scrollbar-width: none;\n\n    &::-webkit-scrollbar {\n        display: none;\n    }\n"], ["\n    overflow: auto;\n    max-height: 505px;\n    scrollbar-width: none;\n\n    &::-webkit-scrollbar {\n        display: none;\n    }\n"])));
var templateObject_1, templateObject_2;
