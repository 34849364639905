var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { makeAutoObservable, reaction } from "mobx";
export var EventType;
(function (EventType) {
    EventType[EventType["AccountCreated"] = 0] = "AccountCreated";
})(EventType || (EventType = {}));
var EventsStore = /** @class */ (function () {
    function EventsStore() {
        this.list = [];
        makeAutoObservable(this, {}, { autoBind: true });
    }
    EventsStore.prototype.reaction = function (eventType, callback) {
        var _this = this;
        return reaction(function () { return _this.last; }, function (e) {
            if ((e === null || e === void 0 ? void 0 : e.type) === eventType) {
                callback(e);
            }
        });
    };
    EventsStore.prototype.push = function (event) {
        this.list = __spreadArray(__spreadArray([], this.list, true), [event], false);
    };
    Object.defineProperty(EventsStore.prototype, "last", {
        get: function () {
            return this.list.at(this.list.length - 1);
        },
        enumerable: false,
        configurable: true
    });
    return EventsStore;
}());
export { EventsStore };
