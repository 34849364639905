import { List, ListItemButton, Popover } from "@mui/material";
import * as React from "react";
import { defaultCountries, FlagImage, parseCountry, usePhoneInput } from "react-international-phone";
import { Box } from "@/shared/components/Box";
import { FormControlInput } from "@/shared/components/FormControlInput";
import { Typography } from "@/shared/components/Typography";
import { useToggler } from "@/shared/hooks/useToggler";
export var PhoneInput = function (_a) {
    var value = _a.value, _b = _a.label, label = _b === void 0 ? "Phone number" : _b, error = _a.error, disabled = _a.disabled, onChange = _a.onChange;
    var divRef = React.useRef(null);
    var toggler = useToggler();
    var _c = usePhoneInput({
        value: value,
        defaultCountry: "us",
        countries: defaultCountries,
        onChange: function (data) {
            onChange(data.phone);
        },
    }), inputValue = _c.inputValue, handlePhoneValueChange = _c.handlePhoneValueChange, inputRef = _c.inputRef, country = _c.country, setCountry = _c.setCountry;
    return (React.createElement("div", { ref: divRef },
        React.createElement(FormControlInput, { ref: inputRef, label: label, value: inputValue, onChange: handlePhoneValueChange, variantError: "alert", error: error, disabled: disabled, InputProps: {
                startAdornment: (React.createElement(React.Fragment, null,
                    React.createElement("button", { type: "button", onClick: toggler.on },
                        React.createElement(FlagImage, { iso2: country.iso2, style: {
                                display: "flex",
                                width: "24px",
                                height: "24px",
                            } })),
                    React.createElement(Popover, { open: toggler.enabled, anchorEl: divRef.current, onClose: toggler.off, anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        }, transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        }, slotProps: {
                            paper: {
                                sx: {
                                    marginTop: "20px",
                                },
                            },
                        } },
                        React.createElement(List, { disablePadding: true, sx: { width: "100%", maxWidth: 360, maxHeight: 300 } }, defaultCountries.map(function (item) {
                            var country = parseCountry(item);
                            return (React.createElement(ListItemButton, { key: country.iso2, disableGutters: true, sx: { padding: "15px 20px" }, onClick: function () {
                                    setCountry(country.iso2);
                                    toggler.off();
                                } },
                                React.createElement(Box, { display: "flex", gap: "16px" },
                                    React.createElement(FlagImage, { iso2: country.iso2, style: {
                                            display: "flex",
                                            width: "24px",
                                            height: "24px",
                                        } }),
                                    React.createElement(Typography, { type: "text16", color: "black-1200", className: "ListItemText" },
                                        country.name,
                                        " +",
                                        country.dialCode))));
                        }))))),
            } })));
};
